import React from "react";
import {Link} from "react-router-dom";
import "../pageCss/myprofile.css";
import {
    getCountries,
    getCities,
    updateUserProfile,
    getUserProfile,
    addGallery,
    getFriends,
    getGallery,
    baseUrl,
    getAvatarList,
    blockUser,
    getBlockedList,
    unblockUser,
    removeGallery,
    updatePassword,
    removeAccount,
    unreadChatMessage,
    submitSafeGuardDocs,
    getProfileActions,
    unfriend,
    getSchedules,
    acceptSchedule, cancelSchedule, notifySettings, updateNotifySettings
} from "../services/ApiService";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {interests, userInfo} from "../services/CommonService";
import AddScripts from "../AddScript";
import {LoginContext} from "../contexts/LoginContext";
import moment from "moment";


class MyProfile extends React.Component {

    static contextType = LoginContext;

    state = {
        _isMounted: false,
        user: {
            city: '',
            cityName: '',
            country: '',
            countryName: '',
            email: '',
            gender: '',
            img: '',
            phone: '',
            isGuarded: 0
        },
        userProfile: userInfo,
        avatar: [],
        gallery: [],
        blankGallery: Array.apply(null, Array(12)),
        countryName: '',
        friendRequests: 0,
        friendList: [],
        fListPagination: {limit: 15, page: 1},
        blockedFriendList: [],
        bListPagination: {limit: 15, page: 1},
        unreadChat: 0,
        cityName: '',
        image: {},
        blockedUser: null,
        selectedImage: {
            name: '',
            source: ''
        },
        guardData: {email: '', phone: '', photoId: null, imageOne: null, imageTwo: null, imageThree: null},
        isPasswordOk: false,
        noMoreAvatar: false,
        pageTitle: 'My Account',
        pageMessage: 'Please complete your account 100% as it will make your profile visibility higher and increase your chances of finding a meaningful connection or relationship.',
        unfriendAcc: {},
        schedules: {sent: [], received: [], accepted: []},
        notifySettings: {views: 1, likes: 1, story_shares: 1, appointments: 1, story_email: 1,
            profile_email: 1, friend_request: 1, friend_accept: 1, chat_message: 1}
    }


    interestList = interests;
    countryList = [];
    cityList = [];
    uploadImage = null;
    hobbies = [];
    removeImageId = null;

    socket = null;
    componentDidMount() {
        document.body.classList.add('my-profile');
        const bk = this.state;
        bk._isMounted = true;
        bk.user = JSON.parse(localStorage.getItem('user'));
        this.setState(bk);

        this.socket = this.context.socket;

        this.restCall();

    }

    componentWillUnmount() {
        document.body.classList.remove('my-profile');
        document.body.style.overflow = 'auto';
        this.setState = (state, callback) => {
            return;
        };
    }

    restCall = async () => {
        if (this.state._isMounted) {
            await this.getUserProfile();
            await this.notificationSettings();
            await this.getFriends();
            await this.getGalleryImage();
            await this.getAvatarList();
            await this.getBlockedFriends();
            await this.unreadMessageCount();
            await this.pageActions();
            await this.schedules();
        }
    }

    unreadMessageCount = async () => {
        const res = await unreadChatMessage();
        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.unreadChat = res.count;
                this.setState(bk);
            }
        }
    }

    avatarPage = 1;
    getAvatarList = async () => {
        const res = await getAvatarList(this.avatarPage);
        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.avatar = bk.avatar.concat(res.list);

                if (res.list.length < 15) {
                    bk.noMoreAvatar = true;
                }

                this.setState(bk);
            }
        }
    }

    getCountries = async () => {
        const res = await getCountries();
        if (res) {
            if (res.status) {
                const bk = this.state;
                this.countryList = res.list;
                setTimeout( async () => {
                    const selectedCountry = res.list.find((item) => {
                        return item.id === parseInt(this.state.userProfile.country);
                    });

                    if (selectedCountry) {
                        bk.countryName = selectedCountry.name;
                        this.setState(bk);
                    }
                }, 300);
                await this.getCities(this.state.userProfile.country);
            }
        }
    }

    getCities = async (countryId) => {
        const res = await getCities(countryId);
        if (res) {
            if (res.status) {
                this.cityList = res.list;
            }
        }
    }

    getUserProfile = async (e) => {
        const result = await getUserProfile();
        if (result) {
            if (result.status) {
                const hobbyList = result.info.hobby;
                const bk = this.state;
                bk.userProfile = result.info;

                if (hobbyList) {
                    const optionArr = hobbyList.split(',');
                    this.hobbies = optionArr;
                }

                this.setState(bk);

                await this.getCountries();
            }
        }

    }

    allowSwearWords = async (e) => {
        e.persist();
        const bk = this.state;
        if (e.target.checked) {
            bk.userProfile.allowSwearWords = 1;
        } else {
            bk.userProfile.allowSwearWords = 0;
        }
        this.setState(bk);

        await this.updateUserProfile();

    }

    updateValue = async (e) => {
        e.persist();

        if (e.target.name === 'bio' && this.state.userProfile.bio.split(' ').length > 200) {
            toast.warning('Bio cannot be over 200 words');
            return false;
        } else if (e.target.name === 'why_here' && this.state.userProfile.why_here.split(' ').length > 50) {
            toast.warning('Info cannot be over 50 words');
            return false;
        } else if (e.target.name === 'hobby' && this.state.userProfile.hobby.split(',').length >= 20) {
            toast.warning('You can select only 20 from interests');
            e.target.checked = false;
            return false;
        }

        const bk = this.state;

        if (e.target.files) {
            bk.userProfile[e.target.name] = e.target.files[0];
            this.uploadImage = e.target.files[0];

            const reader = new FileReader();
            reader.onloadend = () => {
                bk.selectedImage.source = reader.result;
                bk.selectedImage.name = e.target.files[0].name;
                this.setState(bk);

            }
            reader.readAsDataURL(e.target.files[0]);

        } else if (e.target.type === 'checkbox') {
            if (e.target.checked) {
                this.hobbies.push(e.target.value);
                bk.userProfile.hobby = this.hobbies.join(',');
            } else {
                this.hobbies = this.hobbies.filter((item) => {
                    return item !== e.target.value;
                });
                bk.userProfile.hobby = this.hobbies.join(',');
            }
        } else {
            bk.userProfile[e.target.name] = e.target.value;
        }

        if (e.target.name === 'country') {
            await this.getCities(e.target.value);
        }

        this.setState(bk);

    }

    addGallery = async (e) => {
        e.preventDefault();
        const data = {
            image: this.uploadImage,
        }

        if (this.state._isMounted) {
            const result = await addGallery(data);
            if (result) {
                if (result.status) {
                    document.querySelector('#update_gallery .modal_close').click();
                    document.querySelector('#image').value = null;
                    this.uploadImage = null;
                    const bk = this.state;
                    bk.selectedImage.source = '';
                    bk.selectedImage.name = '';
                    this.setState(bk);

                    await this.getGalleryImage();
                }
            }
        }
    }

    getGalleryImage = async () => {
        const res = await getGallery();
        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.gallery = res.list;

                bk.blankGallery = Array.apply(null, Array(12 - bk.gallery.length));

                this.setState(bk);

                if(document.getElementById('customScript')) {
                    document.getElementById('customScript').innerHTML = '';
                    const addScr = new AddScripts();
                    addScr.createElement('customScript', '/extraJs/ExtraMyProfile.js');
                }

            }
        }
    }

    updateUserProfile = async (e) => {
        if (e) {
            e.preventDefault();
        }

        const data = this.state.userProfile;

        const result = await updateUserProfile(data);

        if (result) {
            if (result.status) {
                toast.success(result.message);
                document.querySelector('#update_bio .modal_close').click();
                document.querySelector('#update_profile .modal_close').click();
                document.querySelector('#update_interests .modal_close').click();

                const bk = this.state;
                bk.user.city = data.city;
                bk.user.cityName = data.cityName;
                bk.user.country = data.country;
                bk.user.countryName = data.countryName;
                bk.user.email = data.email;
                bk.user.gender = data.gender;
                bk.user.img = data.img;
                bk.user.phone = data.phone;

                this.setState(bk);

                localStorage.setItem('user', JSON.stringify(this.state.user));

            } else {
                toast.error(result.error);
            }
        }

    }

    logOut = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('access_token');

        window.location.href = '/';
    }

    getFriends = async () => {
        const res = await getFriends({userId: this.state.user.id});
        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.friendList = res.list;
                this.setState(bk);
                this.socket.emit('check-online', {userId: this.state.user.id, list: res.list});
            }
        }
    }

    getBlockedFriends = async () => {
        const res = await getBlockedList();
        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.blockedFriendList = res.list;
                this.setState(bk);
            }
        }
    }

    updateProfilePicture = async (profileImage) => {
        const bk = this.state;
        bk.userProfile.img = profileImage;
        bk.user.img = profileImage;
        localStorage.setItem('user', JSON.stringify(bk.user));
        this.setState(bk);
        await this.updateUserProfile();
        document.querySelector('#update_avatar_modal .modal_close').click();
    }

    blockUser = (contact) => {
        const bk = this.state;
        bk.blockedUser = contact;
        this.setState(bk);
    }

    unblockUser = (contact) => {
        const bk = this.state;
        bk.blockedUser = contact;
        this.setState(bk);
    }

    blockReason = '';
    updateBlockReason = (e) => {
        e.persist();

        if (e.target.type === 'radio') {
            if (e.target.value === 'Other') {
                this.blockReason = '';
                return false;
            }
        }
        this.blockReason = e.target.value;
    }

    confirmBlocking = async () => {
        const res = await blockUser({blockedUserId: this.state.blockedUser.id, reason: this.blockReason});
        if (res) {
            if (res.status) {
                toast.success(res.message);
                await this.getFriends();
                await this.getBlockedFriends();
                document.querySelector('#block_confirm .modal_close').click();
            } else {
                toast.error(res.error);
            }
        }
        this.blockReason = '';
    }

    confirmUnblocking = async () => {
        const res = await unblockUser({blockedUserId: this.state.blockedUser.id});
        if (res) {
            if (res.status) {
                toast.success(res.message);
                await this.getFriends();
                await this.getBlockedFriends();
                document.querySelector('#unblock_confirm .modal_close').click();
            } else {
                toast.error(res.error);
            }
        }
    }

    confirmImageRemove = async () => {
        const res = await removeGallery({imageId: this.removeImageId});
        if (res) {
            if (res.status) {
                toast.success(res.message);
                await this.getGalleryImage();
                document.querySelector('#remove-image .modal_close').click();
            } else {
                toast.error(res.error);
            }
        }
    }

    changePageTitle = (tab) => {
        const bk = this.state;
        if (document.documentElement.clientWidth <= 991) {
            document.body.style.overflowY = 'hidden';
        }
        if (tab === 'general') {
            bk.pageTitle = 'My Account';
            bk.pageMessage = 'Please complete your account 100% as it will make your profile visibility higher and increase your chances of finding a meaningful connection or relationship.';
        } else if (tab === 'connection') {
            bk.pageTitle = 'My Connections';
            bk.pageMessage = `You are connected with ${this.state.friendList.length} users.`;
        } else if (tab === 'gallery') {
            bk.pageTitle = 'Gallery';
            bk.pageMessage = 'Keeping an up-to-date photo gallery will make your profile interesting to other users.';
        } else if (tab === 'setting') {
            bk.pageTitle = 'Account Settings';
            bk.pageMessage = 'Update your password and use strong one to protect your account';
        } else if (tab === 'appointment') {
            bk.pageTitle = 'Appointments';
            bk.pageMessage = 'Accept, cancel or keep track of your appointments';
        }
        this.setState(bk);
    }

    closeDetails = () => {
        (document.querySelector('.profile-tab')).style.display = 'none';
        document.body.style.overflowY = 'auto';
    }

    oldPassword = '';
    newPassword = '';
    confirmPass = '';
    updatePassword = async () => {
        if (this.confirmPass !== this.newPassword) {
            const bk = this.state;
            bk.isPasswordOk = false;
            this.setState(bk);
            return false;
        }
        const res = await updatePassword({oldPassword: this.oldPassword, newPassword: this.newPassword});

        if (res) {
            if (res.status) {
                toast.success(res.message);
                document.querySelector('#password-update-modal .modal_close').click();
                this.oldPassword = this.newPassword = this.confirmPass = '';
            } else {
                toast.error(res.error);
            }
        }

    }


    checkNewPassword = (e) => {
        e.persist();
        this.confirmPass = e.target.value;
        const bk = this.state;

        if (this.confirmPass !== this.newPassword) {
            bk.isPasswordOk = false;
        } else {
            bk.isPasswordOk = true;
        }
        this.setState(bk);

    }

    password = '';
    deleteAccount = async () => {
        if (this.password === '') {
            toast.warning('Please use your current password');
            return false;
        }

        const res = await removeAccount({password: this.password});
        if (res) {
            if (res.status) {
                toast.success(res.message);
                await this.logOut()
            } else {
                toast.error(res.error);
            }
        }

        document.querySelector('#delete-account-modal .modal_close').click();
    }

    updateGuardValue = (e) => {
        e.persist();
        const bk = this.state;
        if (e.target.type === 'file') {
            bk.guardData[e.target.name] = e.target.files[0];
        } else {
            bk.guardData[e.target.name] = e.target.value;
        }
        this.setState(bk);
    }

    submitGuardValue = async () => {
        const res = await submitSafeGuardDocs(this.state.guardData);
        if (res) {
            if (res.status) {
                toast.success(res.message);
            } else {
                toast.error(res.error);
            }
        }
        document.querySelector('#guard-modal .modal_close').click();
        const bk = this.state;
        bk.guardData = {email: '', phone: '', photoId: null, imageOne: null, imageTwo: null, imageThree: null};
        this.setState(bk);
    }

    pageActions = async () => {
        const res = await getProfileActions();
        if (res) {
            if (res.status && res.views.length) {
                const bk = this.state;
                bk.views = res.views;
                this.setState(bk);
            }
            if (res.status && res.likes.length) {
                const bk = this.state;
                bk.likes = res.likes;
                this.setState(bk);
            }
        }
    }

    unfriendAccount = async () => {
        if (!Object.keys(this.state.unfriendAcc).length) {
            return false;
        }
        const res = await unfriend({friendId: this.state.unfriendAcc.id});
        if (res) {
            if (res.status) {
                await this.getFriends();
                await this.getBlockedFriends();
                toast.success('User unfriended');
            } else {
                toast.error(res.error);
            }
        }
    }

    schedules = async () => {
        const res = await getSchedules();
        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.schedules.sent = res.schedules.sent;
                bk.schedules.received = res.schedules.received;
                bk.schedules.accepted = res.schedules.accepted;
                this.setState(bk);
            }
        }
    }

    selectedDate = null;
    selectedIndex = null;
    selectDate = (e, index) => {
        if (this.selectedIndex !== index) {
            this.selectedDate = null;
        }
        e.persist();
        this.selectedIndex = index;
        this.selectedDate = e.target.value;
    }
    acceptAppointment = async (appId) => {
        if (this.selectedIndex !== appId) {
            this.selectedDate = null;
        }
        if (!this.selectedDate) {
            toast.error('Select a date');
        }
        const res = await acceptSchedule({appId: appId, date: this.selectedDate});
        if (res) {
            if (res.status) {
                toast.success(res.message);
                this.selectedDate = null;
                await this.schedules();
            } else {
                toast.success(res.message);
            }
        }
    }

    cancelAppointment = async (appId) => {
        const res = await cancelSchedule({appId: appId});
        if (res) {
            if (res.status) {
                toast.success(res.message);
                await this.schedules();
            } else {
                toast.success(res.message);
            }
        }
    }

    notificationSettings = async () => {
        const res = await notifySettings();
        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.notifySettings = res.notifySettings;
                this.setState(bk);
            }
        }
    }

    updateNotificationSettings = async (e, action) => {
        e.persist();
        let setting = {};
        if (e.target.checked) {
            setting[action] = 'checked';
        } else {
            setting[action] = 'unchecked';
        }
        const res = await updateNotifySettings(setting);
        if (res) {
            if (res.status) {
                toast.success(res.message);
                await this.notificationSettings();
            } else {
                toast.error(res.error);
            }
        }
    }

    render() {
        return (
            <>

                <div className="page_hero"></div>


                <section id="my_account">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="page_title">
                                    <h3 className="purple_color">{this.state.pageTitle} <i
                                        className="fa fa-question-circle-o d-inline-block d-md-none d-lg-none"
                                        id="info_helper" data-toggle="modal" data-target="#info-modal"></i></h3>
                                    <p className="page-message help_notification d-none d-md-block d-lg-block">{this.state.pageMessage}</p>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="my_profile_waper">

                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="my_ac_left">
                                                <div className="avatar">
                                                    {
                                                        this.state.user.img &&

                                                            <img src={`${baseUrl}/avatar/${this.state.user.img}`} alt=""/>

                                                    }

                                                    {
                                                        !this.state.user.img && this.state.user.gender.toLowerCase() === 'male' &&
                                                        <img src={`${baseUrl}/avatar/avatar - 09-01.png`} alt=""/>
                                                    }

                                                    {
                                                        !this.state.user.img && this.state.user.gender.toLowerCase() === 'female' &&
                                                        <img src={`${baseUrl}/avatar/avatar - 14-01.png`} alt=""/>
                                                    }

                                                    {
                                                        !this.state.user.img && this.state.user.gender.toLowerCase() !== 'male' && this.state.user.gender.toLowerCase() !== 'female' &&
                                                        <img src={`${baseUrl}/avatar/avatar-2877.png`} alt=""/>
                                                    }

                                                    <a href="#" data-toggle="modal" data-target="#update_avatar_modal"
                                                       id="update_avatar" title="Update avatar"><i
                                                        className="fa fa-pencil" aria-hidden="true"></i></a>
                                                </div>
                                                <div className="user_name">
                                                    <h5 className="text-center purple_color mb-0">
                                                        <span>{this.state.user.name}</span>
                                                        {
                                                            parseInt(this.state.user.isGuarded) === 1
                                                                ?
                                                                    <i id='sgs' className="fa fa-shield guarded" aria-hidden="true" title='Safe Guarded'
                                                                       data-toggle={"modal"} data-target={"#guard-info"}></i>
                                                                :
                                                                    <i id='sgs' className="fa fa-shield unguarded" aria-hidden="true" title='UnGuarded'
                                                                           data-toggle={"modal"} data-target={"#guard-info"}></i>
                                                        }

                                                    </h5>
                                                    <p className="text-center mb-0">
                                                        <small className={'pin'}><b>Pin: </b>{this.state.user.uid}</small>
                                                        <i className="fa fa-question-circle-o"
                                                        data-toggle={"modal"} data-target={"#pin-modal"}></i>
                                                    </p>
                                                    <div className={'profile-action'}>
                                                        <small data-toggle={'modal'} data-target={'#profile_views'}>Views(<b>{this.state.userProfile.views}</b>)
                                                            <i className="fa fa-eye" aria-hidden="true"></i>
                                                        </small>
                                                        <small data-toggle={'modal'} data-target={'#profile_likes'}>Likes(<b>{this.state.userProfile.likes}</b>)
                                                            <i className="fa fa-thumbs-up" aria-hidden="true"></i>
                                                        </small>
                                                    </div>
                                                </div>

                                                <div className="nav flex-column nav-pills left_profile_navigation"
                                                     id="v-pills-tab" role="tablist" aria-orientation="vertical">

                                                    <Link to={"/chat"} className="btn btn-light btn_outline btn_purple_mid_light">
                                                        {
                                                            this.state.unreadChat > 0 &&
                                                            <span className={'rn'}>{this.state.unreadChat}</span>
                                                        }
                                                        Chat Now
                                                    </Link>

                                                    <Link to={"/find-friends"} className="btn btn-light btn_outline btn_purple_light">Find Friends</Link>

                                                    <a className="btn btn-light btn_outline menu-btn" id="v-pills-my-connection-tab"
                                                       data-toggle="pill" href="#v-pills-friends" role="tab"
                                                       aria-controls="v-pills-friends" aria-selected="false"
                                                       onClick={() => {this.changePageTitle('connection')}}>My Connections</a>

                                                    <button className="btn btn-light btn_outline fr-btn" data-toggle="modal" data-target="#fr">
                                                        {
                                                            this.context.friendRequests > 0 &&
                                                            <span className={'rn'}>{this.context.friendRequests}</span>
                                                        }

                                                        Friend Requests
                                                    </button>

                                                    <a className="btn btn-light btn_outline menu-btn" id="v-pills-appointment-tab"
                                                       data-toggle="pill" href="#v-pills-appointment" role="tab"
                                                       aria-controls="v-pills-appointment" aria-selected="false"
                                                       onClick={() => {this.changePageTitle('appointment')}}>Appointments</a>

                                                    <a className="btn btn-light btn_outline menu-btn" id="v-pills-gallery-tab"
                                                       data-toggle="pill" href="#v-pills-gallery" role="tab"
                                                       aria-controls="v-pills-gallery"
                                                       aria-selected="false" onClick={() => {this.changePageTitle('gallery')}}>My Gallery</a>

                                                    <a className="btn btn-light btn_outline menu-btn" id="v-pills-home-tab"
                                                       data-toggle="pill" href="#v-pills-home" role="tab"
                                                       aria-controls="v-pills-home" aria-selected="true" onClick={() => {this.changePageTitle('general')}}>Profile Info</a>

                                                    <a className="btn btn-light btn_outline menu-btn" id="v-pills-account-tab"
                                                       data-toggle="pill" href="#v-pills-account" role="tab"
                                                       aria-controls="v-pills-account"
                                                       aria-selected="false" onClick={() => {this.changePageTitle('setting')}}>Account Security</a>

                                                    {
                                                        !(parseInt(this.state.user.isGuarded) === 1) &&
                                                        <button className="btn btn-light btn_outline btn_purple_mid_light"
                                                                data-toggle={"modal"} data-target={"#guard-modal"}>Active Guard
                                                        </button>
                                                    }

                                                    <button className="btn btn-light btn_outline btn_purple"
                                                            onClick={this.logOut}>Log Out
                                                    </button>

                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-9 profile-tab">

                                            <div onClick={this.closeDetails} title={'Close Tab'}
                                                className="title_and_close d-flex align-items-center justify-content-between d-md-none d-lg-none">
                                                <i className="fa fa-long-arrow-left" id="close_pop_up"></i>
                                                <h6>{this.state.pageTitle}</h6>
                                            </div>

                                            <div className="profile_right">
                                                <div className="tab-content" id="v-pills-tabContent">
                                                    <div className="tab-pane fade show active position-relative"
                                                         id="v-pills-home" role="tabpanel"
                                                         aria-labelledby="v-pills-home-tab">

                                                        {
                                                            this.state.userProfile.name === '' &&
                                                            <div className="spinner-border spinner-color" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>
                                                        }

                                                        <div className="update_profile">
                                                            <a href="#" data-toggle="modal" data-target="#update_bio" title={'Edit Profile'}>
                                                                <i className="fa fa-pencil" aria-hidden="true"></i>
                                                            </a>
                                                            <Link to={`/profile/${this.state.userProfile.name}`} title={'View Profile'}>
                                                                <i className="fa fa-address-book-o" aria-hidden="true"></i>
                                                            </Link>
                                                        </div>
                                                        <h6 className="purple_color">Bio: </h6>
                                                        {
                                                            this.state.userProfile.bio
                                                            ?
                                                            <p>{this.state.userProfile.bio}</p>
                                                            :
                                                            <>
                                                                <p>___ ___ ___ ___ ____ ___ ____ _____ ___ ____ ___ ____ ____ ____ ____ _____ ____ ___ _____ _____ _____ ____ ___ ___ ___ ___ ___ ____ ___ ____ _____ ___ ____ ___ ____ ____ ____ ____ _____ ____ ___ _____ _____ _____ ____ ___</p>
                                                            </>
                                                        }

                                                        <h6 className="purple_color">Why I'm here: </h6>
                                                        {
                                                            this.state.userProfile.why_here
                                                                ?
                                                                <p>{this.state.userProfile.why_here}</p>
                                                                :
                                                                <>
                                                                    <p>___ ___ ___ ___ ____ ___ ____ _____ ___ ____ ___ ____ ____ ____ ____ _____ ____ ___ _____ _____ _____ ____ ___ ___ ___ ___ ___ ____ ___ ____ _____ ___ ____ ___ ____ ____ ____ ____ _____ ____ ___ _____ _____ _____ ____ ___</p>
                                                                </>
                                                        }


                                                        <div className="profile_setting">
                                                            <div className="info_container">
                                                                <div className="update_profile">
                                                                    <a href="#" data-toggle="modal"
                                                                       data-target="#update_profile" title={'Edit Basic Info'}>
                                                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                                                    </a>
                                                                </div>
                                                                <h6 className="purple_color">Contact Information:</h6>

                                                                <p><span>Country: </span>{this.state.countryName}</p>
                                                                <p><span>Email: </span> {this.state.userProfile.email}
                                                                </p>
                                                                <small>(Your email address or any other contact details are private, and no other user can see those. But adding an email address will enable Reset Password option for your account..)</small>
                                                            </div>
                                                            <div className="info_container">
                                                                <h6 className="purple_color">Basic Information:</h6>

                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <p>
                                                                            <span>Gender:</span> {this.state.userProfile.gender}
                                                                        </p>
                                                                        <p>
                                                                            <span>Age:</span> {this.state.userProfile.age}
                                                                        </p>
                                                                        <p>
                                                                            <span>Profession:</span> {this.state.userProfile.profession}
                                                                        </p>
                                                                        <p>
                                                                            <span>Height:</span> {this.state.userProfile.height}
                                                                        </p>
                                                                        <p>
                                                                            <span>Ethnicity:</span> {this.state.userProfile.ethnicity}
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <p>
                                                                            <span>Body Type:</span> {this.state.userProfile.bodyType}
                                                                        </p>
                                                                        <p>
                                                                            <span>Skin color:</span> {this.state.userProfile.skin_color}
                                                                        </p>
                                                                        <p>
                                                                            <span>Eye Color:</span> {this.state.userProfile.eye_color}
                                                                        </p>
                                                                        <p>
                                                                            <span>Hair Length:</span> {this.state.userProfile.hair_length}
                                                                        </p>
                                                                        <p>
                                                                            <span>Hair Type:</span> {this.state.userProfile.hair_type}
                                                                        </p>
                                                                        <p>
                                                                            <span>Hair Color:</span> {this.state.userProfile.hair_type}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="info_container">
                                                                <div className="update_profile">
                                                                    <a href="#" data-toggle="modal" data-target="#update_interests" title={'Edit Interests'}>
                                                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                                                    </a>
                                                                </div>
                                                                <h6 className="purple_color">Interests:</h6>

                                                                <div className="interest mt-2">

                                                                    {
                                                                        this.hobbies.map((hobby, index) => {
                                                                                return (

                                                                                    <span
                                                                                        className={(hobby == '' ? ' ' : 'interest_btn')}
                                                                                        key={index}>{hobby}</span>
                                                                                )
                                                                            }
                                                                        )}

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>


                                                    <div className="tab-pane fade position-relative"
                                                         id="v-pills-friends" role="tabpanel"
                                                         aria-labelledby="v-pills-friends-tab">
                                                        <div className={'my_conntection'}>
                                                            <div className={'connection-list'}>
                                                                <ul className="nav nav-tabs" id="pills-tab"
                                                                    role="presentation">
                                                                    <li className="nav-item">
                                                                        <a className="nav-link active" id="pills-friend-tab"
                                                                           data-toggle="pill" href="#pills-friend" role="tab"
                                                                           aria-controls="pills-friend"
                                                                           aria-selected="true">Friend List</a>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <a className="nav-link" id="pills-block-tab"
                                                                           data-toggle="pill" href="#pills-block"
                                                                           role="tab"
                                                                           aria-controls="pills-block"
                                                                           aria-selected="false">Blocked List</a>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div className="tab-content" id="pills-tabContent">
                                                                <div className="row tab-pane fade show active" role="tabpanel" aria-labelledby="pills-home-tab" id={'pills-friend'}>
                                                                    {
                                                                        this.state.friendList.length == 0

                                                                            ?

                                                                            <div className={'empty-list'}>
                                                                                <h3>You do not have any connections yet</h3>
                                                                                <Link to={"/find-friends"} className="btn_outline">LET'S FIND ONE</Link>
                                                                            </div>

                                                                            :

                                                                            this.state.friendList.map((contact, index) => {
                                                                                return (

                                                                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6" key={index}>
                                                                                        <div className="user_info_sum d-flex align-items-center">
                                                                                            <div className="profile_img">
                                                                                                <Link to={`/profile/${contact.name}`}>
                                                                                                    {
                                                                                                        contact.img &&

                                                                                                        <img src={`${baseUrl}/avatar/${contact.img}`} alt=""/>

                                                                                                    }

                                                                                                    {
                                                                                                        !contact.img && contact.gender.toLowerCase() === 'male' &&
                                                                                                        <img src={`${baseUrl}/avatar/avatar - 09-01.png`} alt=""/>
                                                                                                    }

                                                                                                    {
                                                                                                        !contact.img && contact.gender.toLowerCase() === 'female' &&
                                                                                                        <img src={`${baseUrl}/avatar/avatar - 14-01.png`} alt=""/>
                                                                                                    }

                                                                                                    {
                                                                                                        !contact.img && contact.gender.toLowerCase() !== 'male' && contact.gender.toLowerCase() !== 'female' &&
                                                                                                        <img src={`${baseUrl}/avatar/avatar-2877.png`} alt=""/>
                                                                                                    }
                                                                                                </Link>
                                                                                            </div>
                                                                                            <div className="details">
                                                                                                <p className="purple_color font-weight-bold text-uppercase">{contact.name}</p>
                                                                                                <p className="small_font">{contact.city}, {contact.country}</p>
                                                                                                <p className="small_font"><span
                                                                                                    className="purple_color">Age: </span>{contact.age} | <span
                                                                                                    className="pink_color">Post Code: </span> {contact.postcode}
                                                                                                </p>
                                                                                                <div className={'action-link'}>
                                                                                                    <Link to={`/chat`}
                                                                                                          className="pink_color">Chat</Link>
                                                                                                    <Link to={`/profile/${contact.name}`}
                                                                                                          className="purple_color">View</Link>
                                                                                                    <Link to={`#`} data-toggle={'modal'} data-target={'#block_confirm'}
                                                                                                          onClick={(e) => {
                                                                                                              e.preventDefault();
                                                                                                              this.blockUser(contact);
                                                                                                          }}
                                                                                                          className="red_color">Block</Link>
                                                                                                    <a href={'#'} className="text-danger" data-toggle={'modal'} data-target={'#unfriend-modal'}
                                                                                                       onClick={ (e) => {
                                                                                                        e.preventDefault();
                                                                                                        const bk = this.state;
                                                                                                        bk.unfriendAcc = contact;
                                                                                                        this.setState(bk);
                                                                                                    }}>Unfriend</a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>



                                                                                    </div>


                                                                                );
                                                                            })
                                                                    }
                                                                </div>
                                                                <div className="row tab-pane fade" role="tabpanel" aria-labelledby="pills-profile-tab" id={'pills-block'}>
                                                                    {
                                                                        this.state.blockedFriendList.length == 0

                                                                            ?

                                                                            <div className={'empty-list'}>
                                                                                <h3>Currently do not have any blocked users</h3>
                                                                            </div>

                                                                            :

                                                                            this.state.blockedFriendList.map((contact, index) => {
                                                                                return (

                                                                                    <div
                                                                                        className="col-12 col-md-12 col-lg-6 col-xl-6"
                                                                                        key={index}>
                                                                                        <div
                                                                                            className="user_info_sum d-flex align-items-center">
                                                                                            <div className="profile_img">
                                                                                                <a href="">
                                                                                                    {
                                                                                                        contact.img &&

                                                                                                        <img src={`${baseUrl}/avatar/${contact.img}`} alt=""/>

                                                                                                    }

                                                                                                    {
                                                                                                        !contact.img && contact.gender.toLowerCase() === 'male' &&
                                                                                                        <img src={`${baseUrl}/avatar/avatar - 09-01.png`} alt=""/>
                                                                                                    }

                                                                                                    {
                                                                                                        !contact.img && contact.gender.toLowerCase() === 'female' &&
                                                                                                        <img src={`${baseUrl}/avatar/avatar - 14-01.png`} alt=""/>
                                                                                                    }

                                                                                                    {
                                                                                                        !contact.img && contact.gender.toLowerCase() !== 'male' && contact.gender.toLowerCase() !== 'female' &&
                                                                                                        <img src={`${baseUrl}/avatar/avatar-2877.png`} alt=""/>
                                                                                                    }
                                                                                                </a>
                                                                                            </div>
                                                                                            <div className="details">
                                                                                                <p className="purple_color font-weight-bold text-uppercase">{contact.name}</p>
                                                                                                <p className="small_font">{contact.city}, {contact.country}</p>
                                                                                                <p className="small_font"><span
                                                                                                    className="purple_color">Age: </span>{contact.age} | <span
                                                                                                    className="pink_color">Post Code: </span> {contact.postcode}
                                                                                                </p>
                                                                                                <div className={'action-link'}>
                                                                                                    <Link to={`/profile/${contact.name}`}
                                                                                                          className="purple_color">View</Link>
                                                                                                    <Link to={`#`} data-toggle={'modal'} data-target={'#unblock_confirm'}
                                                                                                          onClick={(e) => {
                                                                                                              e.preventDefault();
                                                                                                              this.unblockUser(contact);
                                                                                                          }}
                                                                                                          className="red_color">Unblock</Link>
                                                                                                    <a href={'#'} className="text-danger" data-toggle={'modal'} data-target={'#unfriend-modal'}
                                                                                                       onClick={ (e) => {
                                                                                                           e.preventDefault();
                                                                                                           const bk = this.state;
                                                                                                           bk.unfriendAcc = contact;
                                                                                                           this.setState(bk);
                                                                                                       }}>Unfriend</a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>


                                                                                );
                                                                            })
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>


                                                    <div className="tab-pane fade position-relative"
                                                         id="v-pills-appointment" role="tabpanel"
                                                         aria-labelledby="v-pills-appointment-tab">
                                                        <div className={'my_conntection'}>
                                                            <div className={'connection-list'}>
                                                                <ul className="nav nav-tabs" id="pills-tab"
                                                                    role="presentation">
                                                                    <li className="nav-item">
                                                                        <a className="nav-link active" id="pills-sent-tab"
                                                                           data-toggle="pill" href="#pills-sent" role="tab"
                                                                           aria-controls="pills-sent"
                                                                           aria-selected="true">Sent</a>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <a className="nav-link" id="pills-received-tab"
                                                                           data-toggle="pill" href="#pills-received"
                                                                           role="tab"
                                                                           aria-controls="pills-received"
                                                                           aria-selected="false">Received</a>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <a className="nav-link" id="pills-accepted-tab"
                                                                           data-toggle="pill" href="#pills-accepted"
                                                                           role="tab"
                                                                           aria-controls="pills-accepted"
                                                                           aria-selected="false">Accepted</a>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div className="tab-content" id="pills-tabContent">
                                                                <div className="row tab-pane fade show active" role="tabpanel" aria-labelledby="pills-sent-tab" id={'pills-sent'}>
                                                                    {
                                                                        this.state.schedules.sent.length == 0

                                                                            ?

                                                                            <div className={'empty-list'}>
                                                                                <h3>You did not requested ant appointment yet</h3>
                                                                            </div>

                                                                            :

                                                                            this.state.schedules.sent.map((contact, index) => {
                                                                                return (

                                                                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6" key={index}>
                                                                                        <div className="user_info_sum d-flex align-items-center">
                                                                                            <div className="profile_img">
                                                                                                <Link to={`/profile/${contact.name}`}>
                                                                                                    {
                                                                                                        contact.img &&

                                                                                                        <img src={`${baseUrl}/avatar/${contact.img}`} alt=""/>

                                                                                                    }

                                                                                                    {
                                                                                                        !contact.img && contact.gender.toLowerCase() === 'male' &&
                                                                                                        <img src={`${baseUrl}/avatar/avatar - 09-01.png`} alt=""/>
                                                                                                    }

                                                                                                    {
                                                                                                        !contact.img && contact.gender.toLowerCase() === 'female' &&
                                                                                                        <img src={`${baseUrl}/avatar/avatar - 14-01.png`} alt=""/>
                                                                                                    }

                                                                                                    {
                                                                                                        !contact.img && contact.gender.toLowerCase() !== 'male' && contact.gender.toLowerCase() !== 'female' &&
                                                                                                        <img src={`${baseUrl}/avatar/avatar-2877.png`} alt=""/>
                                                                                                    }
                                                                                                </Link>
                                                                                            </div>
                                                                                            <div className="details">
                                                                                                <p className="purple_color font-weight-bold text-uppercase">{contact.name}</p>
                                                                                                <p className="small_font">{contact.city}, {contact.country}</p>
                                                                                                <p className="small_font">Approximate Times:</p>
                                                                                                <ul className={'schedule-dates'}>

                                                                                                    {
                                                                                                        contact.appDates.map((date, i) => {
                                                                                                            return (
                                                                                                                <li key={i}>{moment(date, 'Y-MM-DD kk:mm:ss').format("lll")}</li>
                                                                                                            )
                                                                                                        })
                                                                                                    }

                                                                                                </ul>
                                                                                                <div className={'action-link'}>
                                                                                                    <Link to={`/profile/${contact.name}`}
                                                                                                          className="purple_color">View</Link>
                                                                                                    <Link to={`#`}
                                                                                                          onClick={async (e) => {
                                                                                                              e.preventDefault();
                                                                                                              await this.cancelAppointment(contact.id);
                                                                                                          }}
                                                                                                          className="red_color">Cancel</Link>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>



                                                                                    </div>


                                                                                );
                                                                            })
                                                                    }
                                                                </div>
                                                                <div className="row tab-pane fade" role="tabpanel" aria-labelledby="pills-received-tab" id={'pills-received'}>
                                                                    {
                                                                        this.state.schedules.received.length == 0

                                                                            ?

                                                                            <div className={'empty-list'}>
                                                                                <h3>Currently do not have any appointment request</h3>
                                                                            </div>

                                                                            :

                                                                            this.state.schedules.received.map((contact, index) => {
                                                                                return (

                                                                                    <div
                                                                                        className="col-12 col-md-12 col-lg-6 col-xl-6"
                                                                                        key={index}>
                                                                                        <div
                                                                                            className="user_info_sum d-flex align-items-center">
                                                                                            <div className="profile_img">
                                                                                                <a href="">
                                                                                                    {
                                                                                                        contact.img &&

                                                                                                        <img src={`${baseUrl}/avatar/${contact.img}`} alt=""/>

                                                                                                    }

                                                                                                    {
                                                                                                        !contact.img && contact.gender.toLowerCase() === 'male' &&
                                                                                                        <img src={`${baseUrl}/avatar/avatar - 09-01.png`} alt=""/>
                                                                                                    }

                                                                                                    {
                                                                                                        !contact.img && contact.gender.toLowerCase() === 'female' &&
                                                                                                        <img src={`${baseUrl}/avatar/avatar - 14-01.png`} alt=""/>
                                                                                                    }

                                                                                                    {
                                                                                                        !contact.img && contact.gender.toLowerCase() !== 'male' && contact.gender.toLowerCase() !== 'female' &&
                                                                                                        <img src={`${baseUrl}/avatar/avatar-2877.png`} alt=""/>
                                                                                                    }
                                                                                                </a>
                                                                                            </div>
                                                                                            <div className="details">
                                                                                                <p className="purple_color font-weight-bold text-uppercase">{contact.name}</p>
                                                                                                <p className="small_font">{contact.city}, {contact.country}</p>
                                                                                                <p className="small_font">Approximate Times:</p>
                                                                                                <ul className={'schedule-dates'}>

                                                                                                    {
                                                                                                        contact.appDates.map((date, i) => {
                                                                                                            return (
                                                                                                                <div
                                                                                                                    className="form-check" key={i}>
                                                                                                                    <input
                                                                                                                        className="form-check-input"
                                                                                                                        type="radio"
                                                                                                                        name="app_date"
                                                                                                                        id={`date${i}`}
                                                                                                                        value={date}
                                                                                                                        onChange={(e) => {
                                                                                                                            this.selectDate(e, contact.id);
                                                                                                                        }} />
                                                                                                                        <label
                                                                                                                            className="form-check-label"
                                                                                                                            htmlFor={`date${i}`}>
                                                                                                                            {moment(date, 'Y-MM-DD kk:mm:ss').format("lll")}
                                                                                                                        </label>
                                                                                                                </div>
                                                                                                            )
                                                                                                        })
                                                                                                    }

                                                                                                </ul>
                                                                                                <div className={'action-link'}>
                                                                                                    <Link to={`/profile/${contact.name}`}
                                                                                                          className="purple_color">View</Link>
                                                                                                    <Link to={`#`}
                                                                                                          onClick={async (e) => {
                                                                                                              e.preventDefault();
                                                                                                              await this.acceptAppointment(contact.id);
                                                                                                          }}
                                                                                                          className="pink_color">Accept</Link>
                                                                                                    <Link to={`#`}
                                                                                                          onClick={async (e) => {
                                                                                                              e.preventDefault();
                                                                                                              await this.cancelAppointment(contact.id);
                                                                                                          }}
                                                                                                          className="red_color">Cancel</Link>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>


                                                                                );
                                                                            })
                                                                    }
                                                                </div>
                                                                <div className="row tab-pane fade" role="tabpanel" aria-labelledby="pills-accepted-tab" id={'pills-accepted'}>
                                                                    {
                                                                        this.state.schedules.accepted.length == 0

                                                                            ?

                                                                            <div className={'empty-list'}>
                                                                                <h3>Currently do not have any accepted appointment</h3>
                                                                            </div>

                                                                            :

                                                                            this.state.schedules.accepted.map((contact, index) => {
                                                                                return (

                                                                                    <div
                                                                                        className="col-12 col-md-12 col-lg-6 col-xl-6"
                                                                                        key={index}>
                                                                                        <div
                                                                                            className="user_info_sum d-flex align-items-center">
                                                                                            <div className="profile_img">
                                                                                                <a href="">
                                                                                                    {
                                                                                                        contact.img &&

                                                                                                        <img src={`${baseUrl}/avatar/${contact.img}`} alt=""/>

                                                                                                    }

                                                                                                    {
                                                                                                        !contact.img && contact.gender.toLowerCase() === 'male' &&
                                                                                                        <img src={`${baseUrl}/avatar/avatar - 09-01.png`} alt=""/>
                                                                                                    }

                                                                                                    {
                                                                                                        !contact.img && contact.gender.toLowerCase() === 'female' &&
                                                                                                        <img src={`${baseUrl}/avatar/avatar - 14-01.png`} alt=""/>
                                                                                                    }

                                                                                                    {
                                                                                                        !contact.img && contact.gender.toLowerCase() !== 'male' && contact.gender.toLowerCase() !== 'female' &&
                                                                                                        <img src={`${baseUrl}/avatar/avatar-2877.png`} alt=""/>
                                                                                                    }
                                                                                                </a>
                                                                                            </div>
                                                                                            <div className="details">
                                                                                                <p className="purple_color font-weight-bold text-uppercase">{contact.name}</p>
                                                                                                <p className="small_font">{contact.city}, {contact.country}</p>
                                                                                                <p className="small_font">Appointment Time:</p>
                                                                                                <ul className={'schedule-dates'}>
                                                                                                    <li>{moment(contact.date).format("lll")}</li>
                                                                                                </ul>
                                                                                                <div className={'action-link'}>
                                                                                                    <Link to={`/profile/${contact.name}`}
                                                                                                          className="purple_color">View</Link>
                                                                                                    <Link to={`#`}
                                                                                                          onClick={async (e) => {
                                                                                                              e.preventDefault();
                                                                                                              await this.cancelAppointment(contact.id);
                                                                                                          }}
                                                                                                          className="red_color">Cancel</Link>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>


                                                                                );
                                                                            })
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>


                                                    <div className="tab-pane fade" id="v-pills-gallery" role="tabpanel"
                                                         aria-labelledby="v-pills-gallery-tab">
                                                        <div className="gallery_waper">
                                                            <div className="row">

                                                                {

                                                                    this.state.gallery.map((item, i) => {
                                                                        return (

                                                                            <div className="gal_image" key={i}>
                                                                                <button className={'remove-img'}  data-toggle={'modal'} data-target={'#remove-image'}
                                                                                        onClick={ () => {this.removeImageId = item.id}}>
                                                                                    <span aria-hidden="true">&times;</span>
                                                                                </button>
                                                                                <a href={`${baseUrl}/image_gallery/${item.name}`}
                                                                                   className="with-caption image-link">
                                                                                    <img src={`${baseUrl}/image_gallery/${item.name}`}/>
                                                                                </a>
                                                                            </div>


                                                                        );
                                                                    })
                                                                }

                                                                {
                                                                    this.state.blankGallery.map((it, i) => {
                                                                        return (
                                                                            <div className="gal_image" key={i}>
                                                                                <div className='empty-gallery'>
                                                                                    <img src="images/add.png" alt=""/>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })
                                                                }

                                                            </div>

                                                            <div className="row" style={{display: "none"}}>
                                                                <div className="col-12 text-center">
                                                                    <a href="#" className="add_gallery"
                                                                       data-toggle="modal"
                                                                       data-target="#update_gallery">Add New</a>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>


                                                    <div className="tab-pane fade" id="v-pills-account" role="tabpanel"
                                                         aria-labelledby="v-pills-account-tab">
                                                        <div className="account">
                                                            <div className="row">

                                                                <div className={'settings-btn'}>
                                                                    <div className={'general-notifications'}>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" name={'allowSwearWords'}
                                                                                   type="checkbox" checked={ parseInt(this.state.userProfile.allowSwearWords) === 1 || false}
                                                                                   id="allow-swear-word" onChange={this.allowSwearWords} />
                                                                                <label className="form-check-label"
                                                                                       htmlFor="allow-swear-word">
                                                                                    Allow Swear Word
                                                                                </label>
                                                                        </div>
                                                                    </div>

                                                                    <div className={'notify-settings'}>
                                                                        <p>Notification Display Settings:</p>
                                                                        <div className={'ck-box-list'}>

                                                                            <div className={'ck-box'}>
                                                                                <div className="toggle-switch">
                                                                                    <div>
                                                                                        <input type="checkbox" className={'input-toggle'}
                                                                                               id={'allow-views'} checked={ parseInt(this.state.notifySettings.views) === 1 || false}
                                                                                               onChange={ async (e) => {
                                                                                                    await this.updateNotificationSettings(e, 'views');
                                                                                                }} />
                                                                                        <label htmlFor="allow-views">
                                                                                            <div>
                                                                                                <p>ON</p>
                                                                                                <div></div>
                                                                                                <p>OFF</p>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <p>Profile View</p>
                                                                            </div>

                                                                            <div className={'ck-box'}>
                                                                                <div className="toggle-switch">
                                                                                    <div>
                                                                                        <input type="checkbox" className={'input-toggle'}
                                                                                               id={'allow-likes'} checked={ parseInt(this.state.notifySettings.likes) === 1 || false}
                                                                                               onChange={ async (e) => {
                                                                                                    await this.updateNotificationSettings(e, 'likes');
                                                                                                }} />
                                                                                        <label htmlFor="allow-likes">
                                                                                            <div>
                                                                                                <p>ON</p>
                                                                                                <div></div>
                                                                                                <p>OFF</p>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <p>Profile Like</p>
                                                                            </div>

                                                                            <div className={'ck-box'}>
                                                                                <div className="toggle-switch">
                                                                                    <div>
                                                                                        <input type="checkbox" className={'input-toggle'}
                                                                                               id={'allow-stories'} checked={ parseInt(this.state.notifySettings.story_shares) === 1 || false}
                                                                                               onChange={ async (e) => {
                                                                                                    await this.updateNotificationSettings(e, 'story_shares');
                                                                                                }} />
                                                                                        <label htmlFor="allow-stories">
                                                                                            <div>
                                                                                                <p>ON</p>
                                                                                                <div></div>
                                                                                                <p>OFF</p>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <p>Story Share</p>
                                                                            </div>

                                                                            <div className={'ck-box'}>
                                                                                <div className="toggle-switch">
                                                                                    <div>
                                                                                        <input type="checkbox" className={'input-toggle'}
                                                                                               id={'allow-appointments'} checked={ parseInt(this.state.notifySettings.appointments) === 1 || false}
                                                                                               onChange={ async (e) => {
                                                                                                    await this.updateNotificationSettings(e, 'appointments');
                                                                                                }} />
                                                                                        <label htmlFor="allow-appointments">
                                                                                            <div>
                                                                                                <p>ON</p>
                                                                                                <div></div>
                                                                                                <p>OFF</p>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <p>Appointments</p>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                    <div className={'notify-settings'}>
                                                                        <p>Notification Email Settings:</p>
                                                                        <div className={'ck-box-list'}>

                                                                            <div className={'ck-box'}>
                                                                                <div className="toggle-switch">
                                                                                    <div>
                                                                                        <input type="checkbox" className={'input-toggle'}
                                                                                               id={'profile-email'} checked={ parseInt(this.state.notifySettings.profile_email) === 1 || false}
                                                                                               onChange={ async (e) => {
                                                                                                    await this.updateNotificationSettings(e, 'profile_email');
                                                                                                }} />
                                                                                        <label htmlFor="profile-email">
                                                                                            <div>
                                                                                                <p>ON</p>
                                                                                                <div></div>
                                                                                                <p>OFF</p>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <p>Profile Action</p>
                                                                            </div>

                                                                            <div className={'ck-box'}>
                                                                                <div className="toggle-switch">
                                                                                    <div>
                                                                                        <input type="checkbox" className={'input-toggle'}
                                                                                               id={'story-email'} checked={ parseInt(this.state.notifySettings.story_email) === 1 || false}
                                                                                               onChange={ async (e) => {
                                                                                                    await this.updateNotificationSettings(e, 'story_email');
                                                                                                }} />
                                                                                        <label htmlFor="story-email">
                                                                                            <div>
                                                                                                <p>ON</p>
                                                                                                <div></div>
                                                                                                <p>OFF</p>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <p>Story Action</p>
                                                                            </div>

                                                                            <div className={'ck-box'}>
                                                                                <div className="toggle-switch">
                                                                                    <div>
                                                                                        <input type="checkbox" className={'input-toggle'}
                                                                                               id={'friend-request'} checked={ parseInt(this.state.notifySettings.friend_request) === 1 || false}
                                                                                               onChange={ async (e) => {
                                                                                                    await this.updateNotificationSettings(e, 'friend_request');
                                                                                                }} />
                                                                                        <label htmlFor="friend-request">
                                                                                            <div>
                                                                                                <p>ON</p>
                                                                                                <div></div>
                                                                                                <p>OFF</p>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <p>Friend Request</p>
                                                                            </div>

                                                                            <div className={'ck-box'}>
                                                                                <div className="toggle-switch">
                                                                                    <div>
                                                                                        <input type="checkbox" className={'input-toggle'}
                                                                                               id={'friend-accept'} checked={ parseInt(this.state.notifySettings.friend_accept) === 1 || false}
                                                                                               onChange={ async (e) => {
                                                                                                    await this.updateNotificationSettings(e, 'friend_accept');
                                                                                                }} />
                                                                                        <label htmlFor="friend-accept">
                                                                                            <div>
                                                                                                <p>ON</p>
                                                                                                <div></div>
                                                                                                <p>OFF</p>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <p>Accept Request</p>
                                                                            </div>

                                                                            <div className={'ck-box'}>
                                                                                <div className="toggle-switch">
                                                                                    <div>
                                                                                        <input type="checkbox" className={'input-toggle'}
                                                                                               id={'chat-message'} checked={ parseInt(this.state.notifySettings.chat_message) === 1 || false}
                                                                                               onChange={ async (e) => {
                                                                                                    await this.updateNotificationSettings(e, 'chat_message');
                                                                                                }} />
                                                                                        <label htmlFor="chat-message">
                                                                                            <div>
                                                                                                <p>ON</p>
                                                                                                <div></div>
                                                                                                <p>OFF</p>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <p>Chat Message</p>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                    <button className={'btn btn-light btn_outline'}
                                                                    data-toggle={'modal'} data-target={'#password-update-modal'}>
                                                                        Update Password

                                                                        <i className="fa fa-pencil-square-o"
                                                                           aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className={'btn btn-light btn_outline'}
                                                                    data-toggle={'modal'} data-target={'#delete-account-modal'}>
                                                                        Delete Account

                                                                        <i className="fa fa-trash-o"
                                                                           aria-hidden="true"></i>
                                                                    </button>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="overlay_body"></div>

                </section>


                <div className="modal fade re_modal" id="update_profile" tabIndex="-1"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body">
                                <div className="modal_waper_main_profile bg-white">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <form>
                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="phone">Phone</label>
                                                            <input type="text" className="form-control" id="phone"
                                                                   name={'phone'} onChange={this.updateValue}
                                                                   value={this.state.userProfile.phone}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="email">Email</label>
                                                            <input type="email" className="form-control" id="email"
                                                                   name={'email'} onChange={this.updateValue}
                                                                   value={this.state.userProfile.email}/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6 col-xl-6 col-lg-6">
                                                        <label htmlFor="age">Country</label>
                                                        <div className="form-group">
                                                            <select className="form-control"
                                                                    value={this.state.userProfile.country}
                                                                    name={'country'} onChange={this.updateValue}>
                                                                <option value={0}>Select Country</option>
                                                                {
                                                                    this.countryList.map((country, index) => {
                                                                        return (

                                                                            <option value={country.id}
                                                                                    key={index}>{country.name}</option>

                                                                        );
                                                                    })
                                                                }
                                                            </select>
                                                        </div>

                                                    </div>
                                                    <div className="col-md-6 col-xl-6 col-lg-6">
                                                        <label htmlFor="age">City</label>
                                                        <div className="form-group">
                                                            <select className="form-control"
                                                                    value={this.state.userProfile.city} name={'city'}
                                                                    onChange={this.updateValue}>
                                                                <option value={0}>Select City</option>
                                                                {
                                                                    this.cityList.map((city, index) => {
                                                                        return (

                                                                            <option value={city.id}
                                                                                    key={index}>{city.name}</option>

                                                                        );
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="age">Age</label>
                                                            <input type="text" className="form-control" id="age"
                                                                   name={'age'} onChange={this.updateValue}
                                                                   value={this.state.userProfile.age}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="postcode">Postal Code</label>
                                                            <input type="text" className="form-control" id="postcode"
                                                                   name={'postcode'} onChange={this.updateValue}
                                                                   value={this.state.userProfile.postcode}/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="gender">Gender</label>
                                                            <select className="form-control" id={'gender'}
                                                                    name={'gender'}
                                                                    value={this.state.userProfile.gender}
                                                                    onChange={this.updateValue}>
                                                                <option value={''}>Select Gender</option>
                                                                <option value={'Male'}>Male</option>
                                                                <option value={'Female'}>Female</option>
                                                                <option value={'Agender'}>Agender</option>
                                                                <option value={'Androgynous'}>Androgynous</option>
                                                                <option value={'Bi Gender'}>Bi gender</option>
                                                                <option value={'Gender Fluid'}>Gender Fluid</option>
                                                                <option value={'Gender Monconforming'}>Gender Nonconforming</option>
                                                                <option value={'Gender Questioning'}>Gender Questioning</option>
                                                                <option value={'Gender Variant'}>Gender Variant</option>
                                                                <option value={'Gender Queer'}>Genderqueer</option>
                                                                <option value={'Intersex'}>Intersex</option>
                                                                <option value={'Neutrois'}>Neutrois</option>
                                                                <option value={'Non Binary'}>Non-binary</option>
                                                                <option value={'Pan Gender'}>Pangender</option>
                                                                <option value={'Queer'}>Queer</option>
                                                                <option value={'Trans Feminine'}>Transfeminine</option>
                                                                <option value={'Transgender'}>Transgender</option>
                                                                <option value={'Transgender Female To Male'}>Transgender Female to Male</option>
                                                                <option value={'Transgender Male To Female'}>Transgender Male to Female</option>
                                                                <option value={'Transgender Person'}>Transgender Person</option>
                                                                <option value={'Trans Masculine'}>Transmasculine</option>
                                                                <option value={'Transsexual Female'}>Transsexual Female</option>
                                                                <option value={'Transsexual Male'}>Transsexual Male</option>
                                                                <option value={'Transsexual Person'}>Transsexual Person</option>
                                                                <option value={'Two Spirit'}>Two-Spirit</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="profession">Profession</label>
                                                            <input type="text" className="form-control" id="profession"
                                                                   name={'profession'} onChange={this.updateValue}
                                                                   value={this.state.userProfile.profession}/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="Height">Height</label>
                                                            <select className="form-control" id="Height"
                                                                    value={this.state.userProfile.height}
                                                                    name={'height'} onChange={this.updateValue}>
                                                                <option value=' '>Select Height</option>
                                                                <option value={'132'}>Less than 132 cm / 4ft 4in</option>
                                                                <option value={'134'}>134 cm / 4ft 5in</option>
                                                                <option value={'137'}>137 cm / 4ft 6in</option>
                                                                <option value={'139'}>139 cm / 4ft 7in</option>
                                                                <option value={'142'}>142 cm / 4ft 8in</option>
                                                                <option value={'144'}>144 cm / 4ft 9in</option>
                                                                <option value={'147'}>147 cm / 4ft 10in</option>
                                                                <option value={'149'}>149 cm / 4ft 11in</option>
                                                                <option value={'152'}>152 cm / 5ft 0in</option>
                                                                <option value={'154'}>154 cm / 5ft 1in</option>
                                                                <option value={'157'}>157 cm / 5ft 2in</option>
                                                                <option value={'160'}>160 cm / 5ft 3in</option>
                                                                <option value={'162'}>162 cm / 5ft 4in</option>
                                                                <option value={'165'}>165 cm / 5ft 5in</option>
                                                                <option value={'167'}>167 cm / 5ft 6in</option>
                                                                <option value={'170'}>170 cm / 5ft 7in</option>
                                                                <option value={'172'}>172 cm / 5ft 8in</option>
                                                                <option value={'175'}>175 cm / 5ft 9in</option>
                                                                <option value={'177'}>177 cm / 5ft 10in</option>
                                                                <option value={'180'}>180 cm / 5ft 11in</option>
                                                                <option value={'182'}>182 cm / 6ft 0in</option>
                                                                <option value={'185'}>185 cm / 6ft 1in</option>
                                                                <option value={'187'}>187 cm / 6ft 2in</option>
                                                                <option value={'190'}>190 cm / 6ft 3in</option>
                                                                <option value={'193'}>193 cm / 6ft 4in</option>
                                                                <option value={'195'}>195 cm / 6ft 5in</option>
                                                                <option value={'198'}>198 cm / 6ft 6in</option>
                                                                <option value={'200'}>200 cm / 6ft 7in</option>
                                                                <option value={'203'}>203 cm / 6ft 8in</option>
                                                                <option value={'205'}>205 cm / 6ft 9in</option>
                                                                <option value={'208'}>208 cm / 6ft 10in</option>
                                                                <option value={'210'}>over 208 cm / 6ft 10in</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="bodyType">Body Type</label>
                                                            <select className="form-control" id="bodyType"
                                                                    value={this.state.userProfile.bodyType}
                                                                    name={'bodyType'} onChange={this.updateValue}>
                                                                <option value=' '>Select Body Type</option>
                                                                <option value={'Rather Not Say'}>Rather not say</option>
                                                                <option value={'Thin'}>Thin</option>
                                                                <option value={'Skinny'}>Skinny</option>
                                                                <option value={'slender'}>Slender</option>
                                                                <option value={'Fit'}>Fit</option>
                                                                <option value={'Average'}>Average</option>
                                                                <option value={'Curvy'}>Curvy</option>
                                                                <option value={'A Few Extra Pounds'}>A few extra pounds</option>
                                                                <option value={'Athletic'}>Athletic</option>
                                                                <option value={'Toned'}>Toned</option>
                                                                <option value={'Full Figured'}>Full figured</option>
                                                                <option value={'Heavy set'}>Heavy set</option>
                                                                <option value={'Over weight'}>Over weight</option>
                                                                <option value={'Big And Beautiful'}>Big and beautiful</option>
                                                                <option value={'Big And Tall'}>Big and tall</option>

                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="Ethnicity">Ethnicity</label>
                                                            <select className="form-control" id="Ethnicity"
                                                                    value={this.state.userProfile.ethnicity}
                                                                    name={'ethnicity'} onChange={this.updateValue}>
                                                                <option value=''>Select Ethnicity</option>
                                                                <option value={'Asian'}>Asian</option>
                                                                <option value={'Aboriginal Torres Strait Islander'}>Aboriginal / Torres Strait Islander</option>
                                                                <option value={'Black African Descent'}>Black / African descent</option>
                                                                <option value={'East Indian'}>East Indian</option>
                                                                <option value={'Latino Hispanic'}>Latino / Hispanic</option>
                                                                <option value={'Middle Eastern'}>Middle Eastern</option>
                                                                <option value={'Native American'}>Native American</option>
                                                                <option value={'Pacific Islander'}>Pacific Islander</option>
                                                                <option value={'White Caucasian'}>White / Caucasian</option>
                                                                <option value={'Other'}>Other</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="Skin_Color">Skin color</label>
                                                            <select className="form-control" id="Skin_Color"
                                                                    value={this.state.userProfile.skin_color}
                                                                    name={'skin_color'} onChange={this.updateValue}>
                                                                <option value=''>Select Skin color</option>
                                                                <option value={'White'}>White</option>
                                                                <option value={'Black'}>Black</option>
                                                                <option value={'Brown'}>Brown</option>
                                                                <option value={'Olive'}>Olive</option>
                                                                <option value={'Tanned'}>Tanned</option>
                                                                <option value={'Other'}>Other</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="Eye_Color">Eye Color</label>
                                                            <select className="form-control" id="Eye_Color"
                                                                    value={this.state.userProfile.eye_color}
                                                                    name={'eye_color'} onChange={this.updateValue}>
                                                                <option value=''>Select Eye Color</option>
                                                                <option value={'Black'}>Black</option>
                                                                <option value={'Blue'}>Blue</option>
                                                                <option value={'Brown'}>Brown</option>
                                                                <option value={'Green'}>Green</option>
                                                                <option value={'Grey'}>Grey</option>
                                                                <option value={'Hazel'}>Hazel</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="Hair_Length">Hair Length</label>
                                                            <select className="form-control" id="Hair_Length"
                                                                    value={this.state.userProfile.hair_length}
                                                                    name={'hair_length'} onChange={this.updateValue}>
                                                                <option value=''> Select Hair Length</option>
                                                                <option value={'None'}>None</option>
                                                                <option value={'Short'}>Short</option>
                                                                <option value={'Medium'}>Medium</option>
                                                                <option value={'Long'}>Long</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="Hair_Type">Hair Type</label>
                                                            <select className="form-control" id="Hair_Type"
                                                                    value={this.state.userProfile.hair_type}
                                                                    name={'hair_type'} onChange={this.updateValue}>
                                                                <option value=''> Select Hair Type</option>
                                                                <option value={'Straight'}>Straight</option>
                                                                <option value={'Wavy'}>Wavy</option>
                                                                <option value={'Curly'}>Curly</option>
                                                                <option value={'Afro'}>Afro</option>
                                                                <option value={'Bald'}>Bald</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="Hair_Color">Hair Color</label>
                                                            <select className="form-control" id="Hair_Color"
                                                                    value={this.state.userProfile.hair_color}
                                                                    name={'hair_color'} onChange={this.updateValue}>
                                                                <option value=''> Select Hair Color</option>
                                                                <option value={'Auburn'}>Auburn</option>
                                                                <option value={'Blonde'}>Blonde</option>
                                                                <option value={'Black'}>Black</option>
                                                                <option value={'Brown'}>Brown</option>
                                                                <option value={'Grey'}>Grey</option>
                                                                <option value={'Red'}>Red</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <button type="button" className="btn btn_purple_mid_light mt-4"
                                                        onClick={this.updateUserProfile}>Update details
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade re_modal" id="update_interests" tabIndex="-1"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body">
                                <div className="modal_waper_main_profile bg-white">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <form>

                                                <div className="form-row">

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="">What are your interests?</label>
                                                            <p className="text-warning small_font"><small>Max choice limit 20</small></p>
                                                        </div>
                                                    </div>
                                                    {

                                                        this.interestList.map((hobby, i) => {
                                                            let checked = '';
                                                            const isSet = this.hobbies.find((item) => {
                                                                return item === hobby;
                                                            })

                                                            if (isSet) {
                                                                checked = 'checked';
                                                            }

                                                            return (
                                                                <div className="col-md-3" key={i}>
                                                                    <label className="checkbox-inline cat_sel"> <input
                                                                        type="checkbox" name={'hobby'} checked={checked}
                                                                        onChange={this.updateValue}
                                                                        value={hobby}/> {hobby} </label>
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                </div>

                                                <button type="button" className="btn btn_purple_mid_light mt-4"
                                                        onClick={this.updateUserProfile}>Update details
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade re_modal" id="update_bio" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body">
                                <div className="modal_waper_main_profile bg-white">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <form>

                                                <div className="form-group">
                                                    <label htmlFor="bio">Bio</label>
                                                    <textarea className="form-control" id="bio" rows="3" name={'bio'}
                                                              onChange={this.updateValue}
                                                              value={this.state.userProfile.bio}/>
                                                    <p className="text-warning small_font"><small>Max limit 200 words</small></p>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="why_here">Why I'm here:</label>
                                                    <textarea className="form-control" id="why_here" rows="3"
                                                              name={'why_here'} onChange={this.updateValue}
                                                              value={this.state.userProfile.why_here}/>
                                                    <p className="text-warning small_font"><small>Max limit 50 words</small></p>
                                                </div>
                                                <button type="submit" className="btn btn-light btn_purple now_submit"
                                                        onClick={this.updateUserProfile}>Update details
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade re_modal" id="update_gallery" tabIndex="-1"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body">
                                <div className="modal_waper_main_profile bg-white">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h6 className="purple_color mb-4">Upload Gallery Image</h6>
                                            <form>

                                                {
                                                    this.state.selectedImage.source !== '' &&
                                                    <>
                                                        <img className={'selected-img'}
                                                             src={this.state.selectedImage.source} width={100}/>
                                                    </>
                                                }
                                                <div className="custom-file">

                                                    <input type="file" name={'image'} className="custom-file-input"
                                                           id="image" onChange={this.updateValue}/>
                                                    {
                                                        this.state.selectedImage.name !== '' ?
                                                            <label className="custom-file-label"
                                                                   htmlFor="customFile">{this.state.selectedImage.name}</label>

                                                            :
                                                            <label className="custom-file-label" htmlFor="customFile">Choose
                                                                file</label>
                                                    }

                                                </div>

                                                <button type="submit" className="btn btn-success now_submit mt-4"
                                                        onClick={this.addGallery}>Upload Now
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade re_modal" id="update_avatar_modal" tabIndex="-1"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body">
                                <div className="modal_waper_main_profile bg-white">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h6 className="purple_color mb-4">Select Avatar From This List</h6>

                                            <div className="sd_avater">
                                                <div className="avatar_container">

                                                    {
                                                        this.state.avatar.map((item, i) => {
                                                            return (
                                                                <div className="single_avatar" key={i}>
                                                                    <img src={`${baseUrl}/avatar/${item.name}`} alt={item.name} onClick={() => {this.updateProfilePicture(item.name)}}/>
                                                                </div>
                                                            );
                                                        })
                                                    }


                                                </div>

                                                <div className="text-center mt-4">
                                                    {
                                                        !this.state.noMoreAvatar &&
                                                        <a href="#" className="btn btn_purple_mid_light" onClick={async () => {
                                                            this.avatarPage++;
                                                            await this.getAvatarList();
                                                        }}>Load More</a>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade modal_tnc" id="block_confirm" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body p-0">

                                <div className="tnc_content">

                                    <h4 className="text-white text-left">Are you sure you want to block {this.state.blockedUser ? this.state.blockedUser.name  : ''}?</h4>
                                    <div className="top_carret">
                                        <i className="fa fa-caret-down"></i>
                                    </div>
                                </div>

                                <div className="modal_waper_main bg-white">
                                    <div className="modal_header p-5 text-left">
                                        <p><b>Warning -</b> if you block 10 users in a day, your account will be blocked until that you contact NowPurple admins.!</p>
                                        <div className={'reason-block'}>
                                            <label htmlFor="block-reason" className={'mt-3 mb-3 font-weight-bold text-secondary'}>Reason Of Blocking</label>
                                            <label><input type={'radio'} name={'reason'} onChange={this.updateBlockReason} value={'Bothering me by using vulgar words'} /> Bothering me by using vulgar words</label>
                                            <label><input type={'radio'} name={'reason'} onChange={this.updateBlockReason} value={'Violent behavior'} /> Violent behavior</label>
                                            <label><input type={'radio'} name={'reason'} onChange={this.updateBlockReason} value={'Underage person'} /> Underage person</label>
                                            <label><input type={'radio'} name={'reason'} onChange={this.updateBlockReason} value={'Sharing violent or inappropriate images'} /> Sharing violent or inappropriate images</label>
                                            <label><input type={'radio'} name={'reason'} onChange={this.updateBlockReason} value={'Other'} /> Other</label>
                                            <textarea name="block-reason" id="block-reason" cols="30" rows="5" onChange={this.updateBlockReason}></textarea>
                                        </div>

                                        <div className="continue  text-center mt-5">
                                            <button className="btn_now_purple btn_danger"
                                               id={'block-btn'} onClick={async () => { await this.confirmBlocking()}}>Block User</button>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade modal_tnc" id="unblock_confirm" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body p-0">

                                <div className="tnc_content">

                                    <h4 className="text-white text-left">Are you sure you want to unblock {this.state.blockedUser ? this.state.blockedUser.name  : ''}? When you unblock this user, they will have all the access that they had before you blocked them.</h4>
                                    <div className="top_carret">
                                        <i className="fa fa-caret-down"></i>
                                    </div>
                                </div>

                                <div className="modal_waper_main bg-white">
                                    <div className="modal_header p-5 text-left">

                                        <div className="continue  text-center mt-5">
                                            <button className="btn_now_purple btn_purple"
                                               id={'block-btn'} onClick={async () => { await this.confirmUnblocking()}}>Unblock User</button>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade modal_tnc" id="remove-image" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body p-0">

                                <div className="tnc_content">

                                    <h4 className="text-white text-left">Are you sure you want to remove this image?</h4>
                                    <div className="top_carret">
                                        <i className="fa fa-caret-down"></i>
                                    </div>
                                </div>

                                <div className="modal_waper_main bg-white">
                                    <div className="modal_header p-5 text-left">

                                        <div className="continue  text-center mt-5">
                                            <button className="btn_now_purple btn_purple"
                                               id={'remove-image-btn'} onClick={this.confirmImageRemove}>Remove</button>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade modal_tnc" id="pin-modal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body p-0">

                                <div className="modal_waper_main bg-white">
                                    <div className="modal_header p-5 text-justify">

                                        <p className="text-danger">Please write down this pin number in a safe space. If you forget your password, you can call our support team and they will be able to reset your password - but only if you know your pin number.</p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade modal_tnc" id="password-update-modal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body p-0">

                                <div className="modal_waper_main bg-white">
                                    <div className="modal_header p-5 text-justify">
                                        <p className="text-info">Create password using 8 or more characters. To make it secure use uppercase, lowercase, number and special characters</p>
                                    </div>

                                    <div className="modal_body p-5">
                                        <div className="form-group">
                                            <label htmlFor="Old-pass">Old Password</label>
                                            <input type="password" className="form-control" id="old-pass"
                                                   placeholder="Password" onChange={(e) => {
                                                       e.persist();
                                                       this.oldPassword = e.target.value;
                                            }} />
                                        </div><div className="form-group">
                                            <label htmlFor="new-pass">New Password</label>
                                            <input type="password" className="form-control" id="new-pass"
                                                   placeholder="Password" onChange={(e) => {
                                                       e.persist();
                                                       this.newPassword = e.target.value;
                                            }} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="new-pass">Confirm Password</label>
                                            <input type="password" className="form-control" id="confirm-pass"
                                                   placeholder="Password" onChange={this.checkNewPassword} />
                                            {
                                                !this.state.isPasswordOk &&
                                                    <small className="text-danger">Password does not matched</small>
                                            }
                                        </div>
                                        {
                                            !this.state.isPasswordOk ?
                                                <button className="btn btn-secondary mb-2 disabled">Update</button>
                                                :
                                                <button className="btn btn-secondary mb-2" onClick={this.updatePassword}>Update</button>
                                        }

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade modal_tnc" id="delete-account-modal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body p-0">

                                <div className="modal_waper_main bg-white">
                                    <div className="modal_header p-5 text-justify">
                                        <h4 >Are you sure, you want to delete your account?</h4>
                                    </div>

                                    <div className="modal_body p-5">
                                        <div className="form-group">
                                            <label htmlFor="your-pass">Password</label>
                                            <input type="password" className="form-control" id="your-pass"
                                                   placeholder="Password" onChange={(e) => {
                                                       e.persist();
                                                       this.password = e.target.value;
                                            }} />
                                        </div>

                                        <button className="btn btn-danger mb-2" onClick={this.deleteAccount}>Delete Account</button>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade modal_tnc" id="unfriend-modal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body p-0">

                                <div className="modal_waper_main bg-white">
                                    <div className="modal_header p-5 text-justify">
                                        <h4 >Are you sure, you want to unfriend {this.state.unfriendAcc.name}?</h4>
                                    </div>

                                    <div className="modal_body pt-1 pl-5 pb-2">

                                        <button className="btn btn-danger mb-2" onClick={ async () => {
                                            await this.unfriendAccount();
                                            document.querySelector('#unfriend-modal .modal_close').click();
                                        }}>Unfriend</button>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade re_modal" id="info-modal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body">
                                <div className="modal_waper_main_profile bg-white">
                                    <p>{this.state.pageMessage}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade re_modal" id="guard-modal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body">
                                <div className="modal_waper_main_profile bg-white">
                                    <h4>Please provide all the information to active the <b>Safe Guard</b></h4>

                                    <label className={'cst-label'} htmlFor="guard-email">Email</label>
                                    <input type="email" name={'email'} className="form-control"
                                           id="guard-email" placeholder="Email" onChange={this.updateGuardValue} />

                                    <label className={'cst-label'} htmlFor="guard-phone">Phone</label>
                                    <input type="text" name={'phone'} className="form-control"
                                           id="guard-phone" placeholder="Phone" onChange={this.updateGuardValue} />

                                    <label className='cst-label'>Photo Id</label>
                                    <input type="file" name={'photoId'} className="form-control"
                                           id="photoId" onChange={this.updateGuardValue} accept="image/png, image/jpeg, image/jpg" />

                                    <label className='cst-label'>Image One</label>
                                    <input type="file" name={'imageOne'} className="form-control"
                                           id="imageOne" onChange={this.updateGuardValue} accept="image/png, image/jpeg, image/jpg" />

                                    <label className='cst-label'>Image Two</label>
                                    <input type="file" name={'imageTwo'} className="form-control"
                                           id="imageTwo" onChange={this.updateGuardValue} accept="image/png, image/jpeg, image/jpg" />

                                    <label className='cst-label'>Image Three</label>
                                    <input type="file" name={'imageThree'} className="form-control"
                                           id="imageThree" onChange={this.updateGuardValue} accept="image/png, image/jpeg, image/jpg" />

                                    <button className="btn btn-secondary" onClick={this.submitGuardValue}>Submit</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade re_modal" id="guard-info" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body">
                                <div className="modal_waper_main_profile bg-white">

                                    <p>Activate your Safeguard function before meeting any NowPurple friends physically.</p>
                                    <p>Meeting any NowPurple user physically without first activating our Safeguard option is extremely dangerous for both users and we strongly recommend not to do this under any circumstances.</p>
                                    <p>When you activate the Safeguard function, all information you or other users provide us will remain 100% private and will not be shared with anyone.</p>

                                    <p><b>Why do I need to activate Safeguard function?</b></p>
                                    <p>When you have activated Safeguard function, other NowPurple users will feel more secure interacting with you. It shows that you care about your security and that of the other users.</p>
                                    <p><b>If I activate Safeguard can anyone see my personal information?</b></p>
                                    <p>No-one will have access to your documents including any government agency. Only if any adverse events arise relating to questions of human life, safety or other criminal activity and provided we receive a court order, information of a particular user may be disclosed to government agencies.</p>
                                    <p><b>Can I still use the NowPurple platform without Safeguard?</b></p>
                                    <p>Yes, you can use almost 100% functions without activating Safeguard. There is just one thing that we don’t recommend and that is to meet any user physically as it will be dangerous for you as well as for the other user.</p>

                                    <div>
                                        {
                                            !(parseInt(this.state.user.isGuarded) === 1) &&
                                            <a href="#" className="btn btn_purple_mid_light btn_outline mr-3" data-toggle={'modal'} data-target={'#guard-modal'}
                                               onClick={() => {
                                                   document.querySelector('#guard-info .modal_close').click();
                                               }}>Active Guard</a>
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade profile_views_modal" id="profile_views" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body">
                                <div className="modal_waper_main_profile bg-white">

                                    {
                                        this.state.views &&
                                        <div className={'sidebar-notification'}>
                                            <h4 className="text-center">Latest views of your profile</h4>
                                            {
                                                this.state.views.map((u, i) => {
                                                    return (
                                                        <div className={'notification-item'} key={i}>
                                                            <div className="profile_img">
                                                                <Link to={`/profile/${u.name}`} onClick={() => {
                                                                    document.querySelector('#profile_views .modal_close').click();
                                                                }} >
                                                                {
                                                                    u.img &&

                                                                    <img src={`${baseUrl}/avatar/${u.img}`} alt=""/>

                                                                }

                                                                {
                                                                    !u.img && u.gender.toLowerCase() === 'male' &&
                                                                    <img src={`${baseUrl}/avatar/avatar - 09-01.png`} alt=""/>
                                                                }

                                                                {
                                                                    !u.img && u.gender.toLowerCase() === 'female' &&
                                                                    <img src={`${baseUrl}/avatar/avatar - 14-01.png`} alt=""/>
                                                                }

                                                                {
                                                                    !u.img && u.gender.toLowerCase() !== 'male' && u.gender.toLowerCase() !== 'female' &&
                                                                    <img src={`${baseUrl}/avatar/avatar-2877.png`} alt=""/>
                                                                }
                                                                </Link>
                                                            </div>
                                                            <div className="details">
                                                                <Link to={`/profile/${u.name}`}  onClick={() => {
                                                                    document.querySelector('#profile_views .modal_close').click();
                                                                }} >{u.name}</Link>
                                                                <p className="small_font">{u.city}, {u.country}</p>
                                                                <p className="small_font"><b>{u.time}</b></p>

                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    }

                                    {
                                        !this.state.views &&
                                            <h4 className={'no-view'}>Your profile has no views yet</h4>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade profile_likes_modal" id="profile_likes" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body">
                                <div className="modal_waper_main_profile bg-white">

                                    {
                                        this.state.likes &&
                                        <div className={'sidebar-notification'}>
                                            <h4 className="text-center">Latest likes from the users</h4>
                                            {
                                                this.state.likes.map((u, i) => {
                                                    return (
                                                        <div className={'notification-item'} key={i}>
                                                            <div className="profile_img">
                                                                <Link to={`/profile/${u.name}`}  onClick={() => {
                                                                    document.querySelector('#profile_likes .modal_close').click();
                                                                }}  >
                                                                {
                                                                    u.img &&

                                                                    <img src={`${baseUrl}/avatar/${u.img}`} alt=""/>

                                                                }

                                                                {
                                                                    !u.img && u.gender.toLowerCase() === 'male' &&
                                                                    <img src={`${baseUrl}/avatar/avatar - 09-01.png`} alt=""/>
                                                                }

                                                                {
                                                                    !u.img && u.gender.toLowerCase() === 'female' &&
                                                                    <img src={`${baseUrl}/avatar/avatar - 14-01.png`} alt=""/>
                                                                }

                                                                {
                                                                    !u.img && u.gender.toLowerCase() !== 'male' && u.gender.toLowerCase() !== 'female' &&
                                                                    <img src={`${baseUrl}/avatar/avatar-2877.png`} alt=""/>
                                                                }
                                                                </Link>
                                                            </div>
                                                            <div className="details">
                                                                <Link to={`/profile/${u.name}`}  onClick={() => {
                                                                    document.querySelector('#profile_likes .modal_close').click();
                                                                }} >{u.name}</Link>
                                                                <p className="small_font">{u.city}, {u.country}</p>
                                                                <p className="small_font"><b>{u.time}</b></p>

                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    }

                                    {
                                        !this.state.likes &&
                                            <h4 className={'no-view'}>Your profile has no likes yet</h4>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <section id={'customScript'}></section>

            </>
        );
    }
}

export default MyProfile;
