import React from "react";
import {resetPassword, sendResetEmail} from "../services/ApiService";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

class ForgetPassword extends React.Component {

    state = {
        password: '',
        pin: '',
    }

    password = '';
    pin = '';
    resetPass = async () => {
        const res = await resetPassword({pin: this.pin, password: this.password});

        if (res) {
            if (res.status) {
                toast.success(res.message);
                setTimeout(() => {
                    window.location.href = '/';
                }, 5000);
            } else {
                toast.error(res.error);
            }
        }
    }

    userName = '';
    sendLink = async () => {
        const res = await sendResetEmail({userName: this.userName});

        if (res) {
            if (res.status) {
                toast.success(res.message);
            } else {
                toast.error(res.error);
            }
        }
    }

    render() {
        return (
            <>
                <div className="page_hero"></div>

                <section className={'forget-pass'}>
                    <div className='container'>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="page_title text-center">
                                    <h3 className="purple_color">Reset Password</h3>
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className='reset-choice'>
                                <button className={'btn btn-light btn_outline'}
                                        data-toggle={'modal'} data-target={'#reset-modal'}>Use Secret Pin</button>
                                <button className={'btn btn-light btn_outline'}
                                        data-toggle={'modal'} data-target={'#send-email-modal'}>Use Email</button>
                            </div>
                        </div>
                    </div>
                </section>


                <div className="modal fade" id="reset-modal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body p-0">

                                <div className="modal_waper_main bg-white">
                                    <div className="modal_header pl-5 pr-5 pt-5 text-left">
                                        <h4>Please use you secret pin and the new password</h4>
                                    </div>

                                    <div className="modal_body  p-5">
                                        <div className="form-group">
                                            <label htmlFor="pin">Secret Pin</label>
                                            <input type="text" className="form-control" id="pin"
                                                   placeholder="Secret Pin" onChange={(e) => {
                                                e.persist();
                                                this.pin = e.target.value;
                                            }} />
                                            <label htmlFor="pass">New Password</label>
                                            <input type="password" className="form-control" id="pass"
                                                   placeholder="Password" onChange={(e) => {
                                                e.persist();
                                                this.password = e.target.value;
                                            }} />
                                        </div>

                                        <button className="btn btn-secondary mb-2" onClick={this.resetPass}>Reset</button>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="send-email-modal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body p-0">

                                <div className="modal_waper_main bg-white">
                                    <div className="modal_body  p-5">
                                        <div className="form-group">
                                            <label htmlFor="u-name">User Name</label>
                                            <input type="text" className="form-control" id="u-name"
                                                   placeholder="User Name" onChange={(e) => {
                                                e.persist();
                                                this.userName = e.target.value;
                                            }} />
                                        </div>

                                        <button className="btn btn-secondary mb-2"
                                                data-dismiss="modal" aria-label="Close"
                                                onClick={this.sendLink}>Send Reset Link</button>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }

}

export default ForgetPassword;
