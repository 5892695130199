import React from "react";
import AddScripts from "../AddScript";
import {Link} from "react-router-dom";
import {LoginContext} from "../contexts/LoginContext";
import {baseUrl, checkUserName, getCaptcha, getCities, getCountries, registerUser} from "../services/ApiService";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

class Home extends React.Component {
    static contextType = LoginContext;

    state = {
        captcha: {},
        options: [],
        name: '',
        age: '',
        gender: '',
        password: '',
        country: '',
        city: '',
        postcode: '',
        email: '',
        tc: false,
        pp: false,
        countryList: [],
        cityList: [],
        checkCaptcha:'wrong',
        isValid: 0,
    }

    componentDidMount() {
        document.title = 'NowPurple | Totally Free Online Dating Site UK';
        document.getElementsByTagName('meta')["description"].content = 'NowPurple is a totally free online dating site UK. Create a free (and anonymous) account today and find your loved one. Free dating website UK';
        document.getElementsByTagName('meta')["keywords"].content = 'free uk dating site, free dating uk, totally free online dating sites uk, online dating free uk, free dating websites uk';
        document.body.classList.add('home');

        const addScripts = new AddScripts();
        addScripts.createElement('customScript', '/extraJs/ExtraHome.js');

        this.getCountries();

    }
    componentWillUnmount() {
        document.body.classList.remove('home');

        this.setState = (state,callback)=>{
            return;
        };
    }

    visitorId = '';
    browserFP = () => {
        (async () => {
            // We recommend to call `load` at application startup.
            const fp = await FingerprintJS.load();

            // The FingerprintJS agent is ready.
            // Get a visitor identifier when you'd like to.
            const result = await fp.get();

            // This is the visitor identifier:
            this.visitorId = result.visitorId;

        })();
    }

    getCountries = async () => {
        const res = await getCountries();
        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.countryList = res.list;
                this.setState(bk);
            }
        }
    }

    getCities = async (countryId) => {
        const res = await getCities(countryId);
        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.cityList = res.list;
                this.setState(bk);
            }
        }
    }

    _typeName = null;
    updateValue = async (e) => {
        e.persist();

        if (e.target.name === 'name') {
            this._userName = e.target.value;
            if (this._typeName) {
                clearTimeout(this._typeName);
            }
            this._typeName = setTimeout(async () => {
                await this.checkValidName();
            }, 500);

        } else {

            const bk = this.state;
            bk[e.target.name] = e.target.value;

            if (e.target.name === 'country') {
                await this.getCities(e.target.value);
            }

            if (e.target.name === 'tc' || e.target.name === 'pp') {
                if (e.target.checked) {
                    bk[e.target.name] = true;
                } else {
                    bk[e.target.name] = false;
                }
            }
            this.setState(bk);

        }

    }

    registerUser = async ( e ) => {
        e.preventDefault();

        const data = {
            name: this.state.name,
            password: this.state.password,
            gender: this.state.gender,
            age: this.state.age,
            email: this.state.email,
            postcode: this.state.postcode,
            country: this.state.country,
            city: this.state.city,
            tc: this.state.tc,
            pp: this.state.pp,
            visitorId: this.visitorId,
        }

        if (!this.state.tc || !this.state.pp) {
            toast.error('Please check terms and privacy policy');
            return false;
        }

        const result = await registerUser(data);
        const {isLoggedIn, setLoggedInState} = this.context;

        if (result.status) {
            setLoggedInState(true);
            window.location.href = '/profile';
        } else {
            toast.error(result.error, {autoClose: 3000});
        }
    }

    _userName = '';
    checkValidName = async () => {
        if (this._userName !== '') {
            const res = await checkUserName({userName: this._userName});
            if (res) {
                if (res.status) {
                    setTimeout(() => {
                        const bk = this.state;
                        bk.name = this._userName;
                        bk.isValid = res.isValid;
                        this.setState(bk);
                    }, 100);
                }
            }
        }
    }

    getCaptcha = async () => {
        this.browserFP();
        const captcha = await getCaptcha();
        if (captcha) {
            localStorage.setItem('captcharesult', JSON.stringify(captcha));

            const bk = this.state;
            bk.checkCaptcha = 'false';
            bk._isMounted = true;
            const opname = captcha.description;
            const optionArr = opname.split(',');
            bk.options = [];
            this.setState(bk);
            bk.options = optionArr;
            bk.captcha = captcha;

            this.setState(bk);
        }


    }


    checkValue = (e) => {
        e.persist();
        const result = localStorage.getItem('captcharesult');
        const capresult = JSON.parse(result)

        let msg = 'Wrong Answer';
        let msg1 = 'Correct Answer';
        const answer = capresult.answer;
        if (answer !== e.target.value) {
            const bk = this.state;
            bk.checkCaptcha = 'wrong';
            this.setState(bk);
        }
        else {
            const bk = this.state;
            bk.checkCaptcha = 'right';
            this.setState(bk);
        }
    }


    render() {

        const {isLoggedIn} = this.context;
        return (
            <>
                <section id="hero_area">
                    <div className="container">
                        <div className="col-md-12">
                            <div className="container">
                                <div className="hero_wraper">
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                                            <div className="hero_text">
                                                <h1 className="text-white">Free Dating Site UK</h1>
                                                <h4 className="text-white font-weight-normal">100% of Features Included. No Hidden Fees</h4>

                                                <p className="text-white">The most secure dating
                                                    website. We do not ask for your email, phone number or any
                                                    other personal details to create an account.
                                                    <a href={'https://play.google.com/store/apps/details?id=com.nowpurple.webapp'} target="_blank" className={'inline-download-app'}>
                                                        <i className="fa fa-android" aria-hidden="true"></i>
                                                        Install App
                                                    </a>
                                                </p>
                                                <div className="hero_buttons">
                                                    <Link to="/how-to" className="btn_now_purple btn_pink mr-2">How it works <i
                                                        className="fa fa-play"></i></Link>
                                                    {
                                                        !isLoggedIn &&
                                                        <a href="#" id={'register-btn'} className="btn_now_purple btn_purple"
                                                           data-toggle="modal" data-target="#capOnly"
                                                            onClick={ this.getCaptcha }>Register Now</a>
                                                    }

                                                    {
                                                        isLoggedIn &&
                                                        <Link to={"/find-friends"} className="btn_now_purple btn_purple" >Find Friend</Link>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-6 col-xl-6 text-right d-none d-md-block">
                                            <div className="right_img_hero mt-5 mt-md-0 mt-lg-0 mt-xl-0">
                                                <img src="/images/couple_umbrela.png" alt="Top Header Image Couple Holding Hands-Totally Free Online Dating Site UK" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*<div className="empty_space"></div>*/}

                <section id="scroll_next">
                    <a href="#why_nowpurple" className="scrollArea">
                        <div className="icon-scroll"></div>
                    </a>
                </section>

                <section id="why_nowpurple" className="left_right_info">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="left_right_img">
                                    <img src="/images/couple_image.png" alt="Second Image Couple Holding Heart Balloons-Totally Free Online Dating Site UK" />
                                </div>
                            </div>
                            {/*end col */}
                            <div className="col-md-6">
                                <div className="right_text_area" id="why-us">
                                    <h5 className="pink_color font-weight-normal"><i className="fa fa-eye"></i> Why
                                        NowPurple</h5>
                                    <h2 className="purple_color">Why choose NowPurple and is it safe to use?</h2>
                                    <p className="mb-3"><span>1</span>We do not ask your email, phone number or any
                                        personal details to create an account or to use the NowPurple dating website.
                                        Also, we do not use your google location API data and we don’t share any of your
                                        data with any 3rd party companies. From the user behaviour perspective our AI
                                        system will keep your account safe from inappropriate user activity.</p>
                                    <p><span>2</span>NowPurple is a 100% free dating app which means we truly offer all
                                        functions for free forever.</p>
                                    <p><span>3</span>NowPurple has developed a Safeguard system which helps to protect our users if they choose to meet physically. Read more about Safeguard in our FAQ area.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="whynotapp" className="left_right_info">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="right_text_area">
                                    <h5 className="pink_color font-weight-normal"><i className="fa fa-mobile"></i> Why Not App?</h5>
                                    <h2 className="purple_color">Why didn't we create a mobile app?</h2>
                                    <p>
                                        We care about your privacy and installing a mobile app can sometimes cause a privacy issue. For example, you may not want anyone to see that you are using a dating app on your mobile device. For this reason, we used the latest technology to build a website (not app) which is user-friendly on all kinds of devices and which will give you a hassle free user experience. This does not mean that we are against apps per se. Quite the opposite. We have currently created an Android app for those users who would prefer to use an app rather than a website. Download it in Google Play Store. iPhone app is in development and is coming soon.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="left_right_img mt-5 m-md-0 m-lg-0">
                                    <img src="/images/lvoe_img.png" alt="Third Image Couple Holding a Heart-Free Dating Website UK" />
                                </div>
                            </div>
                            {/*end col*/}
                        </div>
                    </div>
                </section>

                <section id="why_free" className="left_right_info">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 order-1 order-md-0 order-lg-0">
                                <div className="left_right_img mt-5 m-md-0 m-lg-0">
                                    <img src="/images/angel.png" alt="Fourth Image Couple Flying and Holding Hands-Free Dating Website UK" />
                                </div>
                            </div>
                            {/*end col*/}
                            <div className="col-md-6 order-0 order-md-1 order-lg-1">
                                <div className="right_text_area">
                                    <h5 className="pink_color font-weight-normal"><i className="fa fa-random"></i> Free
                                        isn't always bad</h5>
                                    <h2 className="purple_color">Why is NowPurple free?</h2>
                                    <p>On our website, you can see a button called “Help to Shop”. This is where our advertisers are displaying their products.
                                        If you find that you need these products, when you purchase it, we will get a small commission which helps us to keep our website going.
                                        Therefore, if you think NowPurple is a helpful website for you, you can help us time after time by
                                        looking for your necessary products on the “Help to Shop” page.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="mainfaq">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="faq_title text-center">
                                    <h3 className="purple_color mb-5">FAQ <br /> About Shop to help</h3>
                                </div>
                            </div>
                            {/*<end col*/}
                            <div className="col-md-12">
                                <div className="accordion" id="faq">
                                    <div className="card">
                                        <div className="card-header" id="faqhead1">
                                            <a href="#" className="btn btn-header-link" data-toggle="collapse"
                                               data-target="#faq1" aria-expanded="true" aria-controls="faq1">I am concerned about my privacy - is it safe to shop from the "HELP TO SHOP" page?</a>
                                        </div>

                                        <div id="faq1" className="collapse show" aria-labelledby="faqhead1"
                                             data-parent="#faq">
                                            <div className="card-body">
                                                <p className="mb-3">Yes, it is 100% safe to use. If you click on any product in this marketplace you will be redirected to a third-party website to complete the product purchase. As you will be buying from a third party, NowPurple will never show up in your bank statements. This also means that you will never provide us your debit or credit card number or other financial information.</p>
                                                <p>You will however provide such information to that particular third-party website. We take great care to select business partners for our marketplace who have high standards of eCommerce and all of them use highly secure online payment processing platforms where all of your financial information is encrypted and secure.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="faqhead2">
                                            <a href="#" className="btn btn-header-link collapsed" data-toggle="collapse"
                                               data-target="#faq2" aria-expanded="true" aria-controls="faq2">What is the
                                                delivery confirmation and product quality control?</a>
                                        </div>

                                        <div id="faq2" className="collapse" aria-labelledby="faqhead2"
                                             data-parent="#faq">
                                            <div className="card-body">
                                                <p className="mb-3">We take great care to select business partners for our marketplace who have high standards of eCommerce, high-quality products and a great track record of on-time delivery. However, we strongly advise to pay via PayPal as this will ensure you get a 100% money back guarantee.</p>
                                                <p>If any advertiser lets you down regarding product quality or shipping, you can also always write a review about that seller on our platform. Our advertisers are displayed according to ratings by users, which will help us to identify poor performing ones and prevent negative experience for other users.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Register */}
                <section>

                    {/*Modal Cap*/}
                    <div className="modal fade re_modal" id="capOnly" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </div>
                                <div className="modal-body p-0">
                                    <div className="modal_waper_main bg-white">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-xl-6 col-lg-6 d-none d-md-block d-lg-block bg_right_modal align-items-center">
                                                <div className="reg_left d-flex align-items-center h-100">
                                                    <div>
                                                        <h4 className="purple_color text-center">Let us help you to <br /> secure the path</h4>
                                                        <div className="image_for_left">
                                                            <img src="/images/cap_img.png" alt="cap_img" />
                                                        </div>
                                                        <div className="tree_img">
                                                            <img src="/images/tree.png" alt="Tree" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-6 col-lg-6">
                                                <div className="reg-right">
                                                    <h4 className="text-center purple_color">Register Your Account <br /> Step 01</h4>
                                                    <div className="cap_main">
                                                        {
                                                            this.state.captcha.img &&
                                                            <img src={`${baseUrl}/assets/uploads/captcha/${this.state.captcha.img}`} alt="cap images" />
                                                        }

                                                        <div className="cap_inputs">
                                                            <h6>{this.state.captcha.title}?</h6>

                                                            {
                                                                this.state.options.map((capData, index) => {
                                                                    return (
                                                                        <div className="form-check" key={index}>
                                                                            <input className="form-check-input" type="radio" name="check-cap" id={index}
                                                                                   value={capData} onChange={this.checkValue} />
                                                                            <label className="form-check-label" htmlFor={index}>{capData}</label>
                                                                        </div>
                                                                    )
                                                                })
                                                            }


                                                        </div>
                                                    </div>
                                                    <div id={(this.state.checkCaptcha === 'right') ? '' : "btn-display-none"} className="continue text-center">
                                                        <a href="#" id={'btn-go-reg'} className="btn_now_purple btn_purple"
                                                           data-toggle="modal" data-target="#regModal">Continue</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Modal Reg*/}
                    <div className="modal fade re_modal" id="regModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </div>
                                <div className="modal-body p-0">
                                    <div className="modal_waper_main bg-white">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-xl-6 col-lg-6 d-none d-md-block d-lg-block bg_right_modal align-items-center">
                                                <div className="reg_left d-flex align-items-center h-100">
                                                    <div>
                                                        <h4 className="purple_color text-center">We're almost Done! <br /> Let's Do this.</h4>
                                                        <div className="image_for_left">
                                                            <img src="/images/reg.png" alt="cap_img" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-6 col-lg-6">
                                                <div className="reg-right">
                                                    <h4 className="text-center purple_color">Register Your Account</h4>
                                                    <div className="reg_form">
                                                        <form>
                                                            <div className="form-group">
                                                                <input type="text" name={'name'} className="form-control" placeholder="Username"
                                                                       onChange={this.updateValue} />
                                                                {
                                                                    this.state.isValid === 0 && this.state.name !== '' &&
                                                                    <small className={"text-danger small_font"}>This username is not available</small>
                                                                }
                                                            </div>
                                                            <div className="form-group">
                                                                <input type="password" name={'password'} className="form-control" placeholder="Password"
                                                                       value={this.state.password} onChange={this.updateValue} />
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6 col-xl-6 col-lg-6">
                                                                    <div className="form-group">
                                                                        <input type="text" name={'age'} className="form-control" placeholder="Age"
                                                                               value={this.state.age} onChange={this.updateValue} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 col-xl-6 col-lg-6">
                                                                    <div className="form-group">
                                                                        <select className="form-control" name={'gender'} onChange={this.updateValue}>
                                                                            <option >Select Gender</option>
                                                                            <option value={'Male'}>Male</option>
                                                                            <option value={'Female'}>Female</option>
                                                                            <option value={'Agender'}>Agender</option>
                                                                            <option value={'Androgynous'}>Androgynous</option>
                                                                            <option value={'Bi Gender'}>Bi gender</option>
                                                                            <option value={'Gender Fluid'}>Gender Fluid</option>
                                                                            <option value={'Gender Monconforming'}>Gender Nonconforming</option>
                                                                            <option value={'Gender Questioning'}>Gender Questioning</option>
                                                                            <option value={'Gender Variant'}>Gender Variant</option>
                                                                            <option value={'Gender Queer'}>Genderqueer</option>
                                                                            <option value={'Intersex'}>Intersex</option>
                                                                            <option value={'Neutrois'}>Neutrois</option>
                                                                            <option value={'Non Binary'}>Non-binary</option>
                                                                            <option value={'Pan Gender'}>Pangender</option>
                                                                            <option value={'Queer'}>Queer</option>
                                                                            <option value={'Trans Feminine'}>Transfeminine</option>
                                                                            <option value={'Transgender'}>Transgender</option>
                                                                            <option value={'Transgender Female To Male'}>Transgender Female to Male</option>
                                                                            <option value={'Transgender Male To Female'}>Transgender Male to Female</option>
                                                                            <option value={'Transgender Person'}>Transgender Person</option>
                                                                            <option value={'Trans Masculine'}>Transmasculine</option>
                                                                            <option value={'Transsexual Female'}>Transsexual Female</option>
                                                                            <option value={'Transsexual Male'}>Transsexual Male</option>
                                                                            <option value={'Transsexual Person'}>Transsexual Person</option>
                                                                            <option value={'Two Spirit'}>Two-Spirit</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6 col-xl-6 col-lg-6">

                                                                    <div className="form-group">
                                                                        <select className="form-control" name={'country'} onChange={this.updateValue}>
                                                                            <option value={0} >Select Country</option>
                                                                            {
                                                                                this.state.countryList.map((country, index) => {
                                                                                    return (

                                                                                        <option value={country.id} key={index}>{country.name}</option>

                                                                                    );
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>

                                                                </div>
                                                                <div className="col-md-6 col-xl-6 col-lg-6">
                                                                    <div className="form-group">
                                                                        <select className="form-control" name={'city'} onChange={this.updateValue}>
                                                                            <option value={0} >Select City</option>
                                                                            {
                                                                                this.state.cityList.map((city, index) => {
                                                                                    return (

                                                                                        <option value={city.id} key={index}>{city.name}</option>

                                                                                    );
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <input type="text" name={'postcode'} className="form-control" placeholder="Post Code"
                                                                       value={this.state.postcode} onChange={this.updateValue} />
                                                            </div>

                                                            <div className="form-group">
                                                                <input type="text" name={'email'} className="form-control" placeholder="mail@something.com"
                                                                       value={this.state.email} onChange={this.updateValue} />
                                                            </div>

                                                            <div className="form-check">
                                                                <input type="checkbox" className="form-check-input"
                                                                       id="tc" name={'tc'} onChange={this.updateValue} />
                                                                    <label className="form-check-label"
                                                                           htmlFor="tc">Terms and condition. <a href={'/terms'}>Click Here</a> to read. </label>
                                                            </div>

                                                            <div className="form-check">
                                                                <input type="checkbox" className="form-check-input"
                                                                       id="pp" name={'pp'} onChange={this.updateValue} />
                                                                    <label className="form-check-label"
                                                                           htmlFor="pp">Privacy policy. <a href={'/privacy-policy'}>Click Here</a> to read. </label>
                                                            </div>

                                                            <p className='small_font text-muted mt-2 lh-14'>Email is optional, but if you use you will have the ability to re-set your account password whenever you need it.</p>

                                                            <div className="cap_main_reg">
                                                                <div className="lets_get_started">

                                                                    {

                                                                        this.state.isValid !== 0 && this.state.pp && this.state.tc &&
                                                                        <a href={'#'} id={'btn-go-tnc'} className="btn_now_purple btn_purple"
                                                                                data-toggle="modal" data-target="#tnc">Let's Get Started</a>

                                                                    }

                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Modal TNC*/}
                    <div className="modal fade modal_tnc" id="tnc" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </div>
                                <div className="modal-body p-0">
                                    <div className="modal_waper_main bg-white">
                                        <div className="modal_header">
                                            <i className="fa fa-thumbs-up"></i>
                                            <h4 className="purple_color">Awesome!</h4>
                                        </div>
                                    </div>
                                    <div className="tnc_content">
                                        <div className="top_carret">
                                            <i className="fa fa-caret-down"></i>
                                        </div>
                                        <h4 className="text-white text-center">House Rules</h4>
                                        <div className="tnc_listing">
                                            <ul>
                                                <li>Do not use inappropriate words.</li>
                                                <li>Keep this place safe and friendly.</li>
                                                <li>You will be BLOCKED for an hour if you ignore 7 chats within 30 minutes.</li>
                                                <li>If you get blocked by a friend for 20 times within 7 days NowPurple will block you for 7 days.</li>
                                            </ul>
                                        </div>

                                        <div className="continue text-center">
                                            <Link to={"/find-contacts"} id={'btn-reg-finish'} className="btn_now_purple btn_white" onClick={this.registerUser}>Continue</Link>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <div className={'download-app'}>
                    <p>Install App</p>
                    <a href={'https://play.google.com/store/apps/details?id=com.nowpurple.webapp'} target="_blank">
                        <i className="fa fa-android" aria-hidden="true"></i>
                    </a>
                </div>

                <section id={'customScript'}></section>

            </>
        );
    }
}

export default Home;
