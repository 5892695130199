import React from "react";
import "./Chat.css";
import io from 'socket.io-client';
import {
    acceptRejectContact,
    adsGetCategory, adsGetProducts, adsGetRandProducts,
    baseChatUrl, baseUrl, blockUser, checkSwearWords,
    getChatMessages,
    getFriends, getSharedWithMeGallery, getUserProfile, giveReview,
    removeChatMessage,
    sendChatFile,
    sendChatMessage, shareGallery, unreadChatMessage
} from "../services/ApiService";
import AddScripts from "../AddScript";
import {Link} from "react-router-dom";
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {emojis, userInfo} from "../services/CommonService";
import {LoginContext} from "../contexts/LoginContext";

class Chat extends React.Component {
    static contextType = LoginContext;

    state = {
        _isMounted: false,
        user: userInfo,
        messages: [],
        chatMessage: '',
        friendList: [],
        selectedChannel: '',
        adsCategoryList: [],
        ratingPoint: '0',
        ratingMessage: '',
        selectedGallery: [],
        emojis: emojis,
        showChatBody: false,
    }

    _friendList = [];
    selectedChatFriend = { id: 0, name: '', channel: '', online: false, status: null, sender: null };
    socket = null;

    addScripts = new AddScripts();
    componentDidMount() {

        this.addScripts.createElement('customScript', '/extraJs/ExtraChat.js');
        document.body.classList.add('chat');
        document.getElementById('main_footer').classList.add('chat');

        if(window.Tawk_API.onLoaded) {
            window.Tawk_API.hideWidget();
        } else {
            this.hideServiceChat();
        }

        const user = localStorage.getItem('user');
        const bk = this.state;
        bk._isMounted = true;
        bk.user = JSON.parse(user);
        this.setState(bk);

        this.getFriends();
        this.adsGetCategory();
        this.adsGetProducts();

        this.socket = this.context.socket;

        this.socket.emit('at-chat-room', {user_id: this.state.user.id});

        this.socket.on('private-chat', async (data) => {
            await this.getMessage();
            this.chatAreaScroll();
            this.checkNewMessage(data, true);

            if (parseInt(this.selectedChatFriend.id) === parseInt(data.from_user_id)) {
                document.getElementById('chat-received').play();
            }
        });

        this.socket.on('shared-gallery', async (data) => {
            for (let key in this._friendList) {
                if (this._friendList[key].id === data.from_user_id) {
                    this._friendList[key].galleryShared = true;
                }
            }
            const bk = this.state;
            bk.friendList = this._friendList;
            this.setState(bk);
        });

    }

    componentWillUnmount() {
        document.body.classList.remove('chat');
        document.body.style.overflow = 'auto';
        document.getElementById('main_footer').classList.remove('chat');
        this.socket.emit('out-chat-room', {user_id: this.state.user.id});
        window.Tawk_API.showWidget();

        this.setState = (state,callback)=> {
            return;
        };
    }

    getDevice = () => {
        const userAgent = navigator.userAgent;

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            const cf = document.getElementById('chat_footer');
            cf.classList.add('ios');
        }
    }

    hideServiceChat = () => {
        setTimeout(() => {
            if(window.Tawk_API.onLoaded) {
                window.Tawk_API.hideWidget();
            } else {
                this.hideServiceChat();
            }
        }, 200);
    }

    checkNewMessage = (data, status = false) => {
        for (let key in this._friendList) {
            if (this._friendList[key].id === data.from_user_id) {
                this._friendList[key].hasNewMessage = status;
            }
        }
        const bk = this.state;
        bk.friendList = this._friendList;
        this.setState(bk);

    }

    getFriends = async () => {
        const res = await getFriends({userId: this.state.user.id});
        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.friendList = res.list;
                this._friendList = bk.friendList;
                this.setState(bk);
                this.socket.emit('check-online', {userId: this.state.user.id, list: res.list});
                this.socket.on('check-online', (data) => {
                    const bk = this.state;
                    const online = [];
                    const offline = [];
                    for (const key in bk.friendList) {
                        if (bk.friendList[key].id === data.id) {
                            bk.friendList[key].online = true;
                        }
                        if (bk.friendList[key].online === true) {
                            online.push(bk.friendList[key]);
                        } else {
                            offline.push(bk.friendList[key]);
                        }
                    }

                    bk.friendList = online.concat(offline);
                    this._friendList = bk.friendList;
                    this.setState(bk);
                });
                this.socket.on('offline', (data) => {
                    const bk = this.state;
                    const online = [];
                    const offline = [];
                    for (const key in bk.friendList) {
                        if (bk.friendList[key].id === data.id) {
                            bk.friendList[key].online = false;
                        }
                        if (bk.friendList[key].online === true) {
                            online.push(bk.friendList[key]);
                        } else {
                            offline.push(bk.friendList[key]);
                        }
                    }
                    bk.friendList = online.concat(offline);
                    this.setState(bk);
                });
            }
        }
    }

    _chatMessages = [];
    getMessage = async () => {
        const res = await getChatMessages(this.selectedChatFriend.channel);
        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.messages = res.messages;
                this._chatMessages = res.messages;
                this.setState(bk);

                this.chatAreaScroll();
                await this.unreadMessageCount();

                this.socket.emit('chat-notify', {
                    to_id: this.selectedChatFriend.id,
                    from_user_id: this.state.user.id,
                    channel: this.selectedChatFriend.channel,
                    self: true
                });
            }
        }
    }

    unreadMessageCount = async () => {
        const res = await unreadChatMessage();
        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.unreadChat = res.count;
                this.setState(bk);
            }
        }
    }


    // captureMessage = (e) => {
    //     e.persist();
    //
    //     const bk = this.state;
    //     const _text = document.getElementById('text');
    //     bk.chatMessage = _text.innerText;
    //     this.setState(bk);
    //
    //     console.log(this.state.chatMessage);
    //
    //     this.socket.emit('typing', { from_user_id: this.state.user.id, to_id: this.selectedChatFriend.id, status: true });
    //
    //     if (this.stop) {
    //         clearTimeout(this.stop);
    //     }
    //
    //     this.stop = setTimeout(() => {
    //         this.socket.emit('typing', { from_user_id: this.state.user.id, to_id: this.selectedChatFriend.id, status: false });
    //     }, 2000);
    //
    // }

    addEmoji = (emo) => {
        const _text = document.getElementById('text');
        _text.innerHTML = _text.innerText + ' ' + emo;

        const bk = this.state;
        bk.chatMessage = _text.innerText;
        this.setState(bk);

    }

    clock = null;
    lastSelectedFriend = null;
    chatSession = async () => {

        if (this.clock !== null) {
            const minutes = 1000 * 60;
            const now = Date.now();
            const start = Math.round(this.clock / minutes);
            const end = Math.round(now / minutes);
            this.clock = null;
            const diff = end - start;
            if (diff >= 10) {
                this.lastSelectedFriend = this.selectedChatFriend;
                document.getElementById('open-rating').click();
            }
        }

    }


    chat = async (friend) => {

        setTimeout(() => {
            this.getDevice();
        }, 200);

        await this.chatSession();

        const bk = this.state;
        bk.chatMessage = '';
        bk.showChatBody = true;
        this.setState(bk);

        this.selectedChatFriend = friend;

        this.socket.on('typing', data => {

            if (data.status && data.from_user_id === this.selectedChatFriend.id) {
                document.getElementById('ticontainer').style.display = 'flex';
            } else {
                document.getElementById('ticontainer').style.display = 'none';
            }
        });

        this.socket.on('remove-message',async (data) => {
            await this.getMessage();
        });

        const searchBox =  document.getElementById('search_chat_msg');
        if (searchBox) {
            searchBox.style.display = 'none';
        }
        await this.getMessage();
        this.checkNewMessage({from_user_id : friend.id}, false);
    }

    stop = null;
    sendMessage = async (e) => {
        e.persist();

        if (e.key === 'Enter' && !e.shiftKey) {
            this.socket.emit('typing', { from_user_id: this.state.user.id, to_id: this.selectedChatFriend.id, status: false });
            await this.send();
        } else {

            const bk = this.state;
            const _text = document.getElementById('text');
            bk.chatMessage = _text.innerText;
            this.setState(bk);

            this.socket.emit('typing', { from_user_id: this.state.user.id, to_id: this.selectedChatFriend.id, status: true });

            if (this.stop) {
                clearTimeout(this.stop);
            }

            this.stop = setTimeout(() => {
                this.socket.emit('typing', { from_user_id: this.state.user.id, to_id: this.selectedChatFriend.id, status: false });
            }, 2000);

        }
    }

    firstSentMsg = ``;
    send = async () => {
        this.lastSelectedFriend = this.selectedChatFriend;
        this.clock = Date.now();

        if (this.state.chatMessage !== '' || (this.state.chatFile && this.state.chatFile.fileName !== '')) {

            const res = await sendChatMessage({
                message: this.state.chatMessage !== '' ? this.state.chatMessage : null,
                channel: this.selectedChatFriend.channel,
                isToUserStatus: this.selectedChatFriend.online ? 'online' : 'offline',
            });

            if (res) {
                if (res.status) {
                    document.getElementById('text').innerHTML = '';
                    if (res.userBlocked) {
                        const result = await getUserProfile();
                        if (result) {
                            if (result.status) {
                                const bk = this.state;
                                bk.user = result.info;
                                localStorage.setItem('user', JSON.stringify(bk.user));
                                this.setState(bk);
                                window.location.reload();
                            }
                        }
                    } else if (res.extra) {
                        if (res.extra === 'sent') {
                            this.firstSentMsg = `we noticed that this is your first conversation with ${this.selectedChatFriend.name}. It is our policy that if someone replies you back and you both start your first conversation, we hope you will chat with each other for at least 5 minutes. If you ignore this rule 7 times within 1 hour time, your profile will be blocked for 15 minutes.`;
                        } else if (res.extra === 'reply') {
                            this.firstSentMsg = `we noticed that this is your first conversation with ${this.selectedChatFriend.name}. It is our policy that if you reply back to someone and you both start your first conversation, we hope you will chat with each other for at least 5 minutes. If you ignore this rule 7 times within 1 hour time, your profile will be blocked for 15 minutes.`;
                        }
                        document.getElementById('first-msg-btn').click();
                    }
                } else {
                    toast.warning(res.error);
                    return false;
                }
            }

            this.socket.emit('private-chat', {
                to_id: this.selectedChatFriend.id,
                from_user_id: this.state.user.id,
                channel: this.selectedChatFriend.channel,
            });

            this.socket.emit('chat-notify', {
                to_id: this.selectedChatFriend.id,
                from_user_id: this.state.user.id,
                channel: this.selectedChatFriend.channel,
            });

            const bk = this.state;
            bk.messages.push({
                id: null,
                from_user_id: this.state.user.id,
                to_user_id: null,
                message: bk.chatMessage !== '' ? bk.chatMessage : null,
            });
            bk.chatMessage = '';

            this.setState(bk);
        }
        await this.getMessage();

    }

    removeMessage = async (id) => {
        const res = await removeChatMessage({messageId: id});
        if (res) {
            if (res.status) {
                await this.getMessage();
                this.socket.emit('remove-message', {
                    to_id: this.selectedChatFriend.id,
                });
                toast.success(res.message, {autoClose: 3000});
            } else {
                toast.error(res.error, {autoClose: 3000});
            }
        }
    }

    sendFile = async (e) => {
        e.persist();

        const file = e.target.files[0];

        const res = await sendChatFile({file: file, channel: this.selectedChatFriend.channel});

        if (res) {
            if (res.status) {

                this.socket.emit('private-chat', {
                    to_id: this.selectedChatFriend.id,
                    from_user_id: this.state.user.id,
                    channel: this.selectedChatFriend.channel,
                });

                await this.getMessage();
                this.chatAreaScroll();
            }
        }

    }

    chatAreaScroll = () => {
        if (this.selectedChatFriend.name !== '') {
            setTimeout(() => {
                const sc = document.querySelector('.chat_messages');
                sc.scrollTop = sc.scrollHeight - 50;
            }, 50);
        }
    }


    adsGetCategory = async () => {
        const category = await adsGetCategory();
        if (category) {
            const bk = this.state;
            bk._isMounted = true;
            bk.adsCategoryList = category;
            this.setState(bk);
        }
    }


    adsGetProducts = async () => {
        const adsProduct = await adsGetRandProducts(5);
        if (adsProduct) {
            if (adsProduct.status) {
                const bk = this.state;
                bk._isMounted = true;
                bk.adsRandProductList = adsProduct.products;
                this.setState(bk);
            }
        }
    }


    giveRating = (e) => {
        e.persist();
        const bk = this.state;

        if (e.target.type === 'radio') {
            bk.ratingPoint = e.target.value;
        } else if (e.target.type === 'textarea') {
            bk.ratingMessage = e.target.value;
        }
        this.setState(bk);
    }

    giveReview = async (e) => {
        e.preventDefault();
        let friendId = this.selectedChatFriend.id;
        if (this.lastSelectedFriend != null) {
            friendId = this.lastSelectedFriend.id;
        }
        if (this.state.ratingPoint === '' || this.state.ratingMessage === '' || friendId === 0) {
            toast.error('Give rating point and a short review');
            return false;
        }

        const res = await giveReview({reviewFor: friendId, reviewRate: this.state.ratingPoint, message: this.state.ratingMessage});
        if (res) {
            if (res.status) {
                toast.success(res.message);
                document.querySelector('#modal-rating .modal_close').click();
                const bk = this.state;
                bk.ratingPoint = '';
                bk.ratingMessage = '';
                this.setState(bk);
            }
        }

    }

    shareGallery = async () => {
        const res = await shareGallery({shareWith: this.selectedChatFriend.id});
        if (res) {
            if (res.status) {
                toast.success(res.message);

                this.socket.emit('shared-gallery', {
                    to_id: this.selectedChatFriend.id,
                    from_user_id: this.state.user.id,
                    channel: this.selectedChatFriend.channel,
                });

            } else {
                toast.error(res.error);
            }
        }

        document.querySelector('#modal-share-gallery .modal_close').click();
    }


    blockReason = '';
    updateBlockReason = (e) => {
        e.persist();

        if (e.target.type === 'radio') {
            if (e.target.value === 'Other') {
                this.blockReason = '';
                return false;
            }
        }
        this.blockReason = e.target.value;
    }


    confirmBlocking = async () => {
        const res = await blockUser({blockedUserId: this.selectedChatFriend.id, reason: this.blockReason});
        if (res) {
            if (res.status) {
                toast.success(res.message);
                await this.getFriends();
                document.querySelector('#block_confirm .modal_close').click();
                this.selectedChatFriend = { id: 0, name: '', channel: '', online: false };
                const bk = this.state;
                bk.messages = [];
                this.setState(bk);
            } else {
                toast.error(res.error);
            }
        }
        this.blockReason = '';
    }


    filterFriendList = (e) => {
        e.persist();
        const bk = this.state;
        const val = e.target.value;

        if (val !== '') {
            let list = [];

            list = this._friendList.filter((item) => {
                const exp = new RegExp(val, "gi");
                return item.name.match(exp);
            });
            bk.friendList = list;
            this.setState(bk);
        } else {
            bk.friendList = this._friendList;
            this.setState(bk);
        }

    }

    filterChatMessage = (e) => {
        e.persist();
        const bk = this.state;
        const val = e.target.value;

        if (val !== '') {
            let list = [];

            list = this._chatMessages.filter((item) => {
                const exp = new RegExp(val, "gi");
                return item.message.match(exp);
            });
            bk.messages = list;
            this.setState(bk);
        } else {
            bk.messages = this._chatMessages;
            this.setState(bk);
        }

    }

    _selectedUsersGallery = null;
    viewGallery = async () => {
        const res = await getSharedWithMeGallery({sharedBy: this._selectedUsersGallery.id});
        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.selectedGallery = res.list;
                this.setState(bk);

                setTimeout(() => {
                    document.getElementById('customScript').innerHTML = '';
                    this.addScripts.createElement('customScript', '/extraJs/ExtraChat.js');
                }, 50);
            }
        }
    }


    inputFocus = (e) => {
        e.persist();
        if (document.body.clientWidth <= 991) {
            this.chatAreaScroll();
        }
    }

    inputBlur = (e) => {
        e.persist();
        if (document.body.clientWidth <= 991) {
            this.chatAreaScroll();
        }
    }

    scrollWindow = () => {
        setTimeout(function(){
            window.scrollTo(0, 50);
            document.body.scrollTo(0, 50);
            document.documentElement.scrollTo(0, 50);
            document.querySelector('.chat_area_show_hide').scrollTo(0, 100);
        }, 0);
    }

    recordedData = [];
    recorder = null;
    audioStream = null;

    startRecoding = async () => {
        if (navigator.mediaDevices) {
            console.log('getUserMedia supported.');
            if (this.recorder) {
                return false;
            }
            document.getElementById('rec-start').classList.add('pressed');
            document.querySelectorAll('.equalizer div').forEach(div => {
                div.classList.add('up-down');
            });
            const constraints = {audio: true};
            try {
                this.audioStream = await navigator.mediaDevices.getUserMedia(constraints);
                this.recorder = new MediaRecorder(this.audioStream);
                this.recorder.start();
                this.recorder.ondataavailable = (e) => {
                    this.recordedData.push(e.data);
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    pauseRecording = async () => {
        if (this.recorder) {
            if (this.recorder.state === "recording") {
                document.getElementById('rec-start').classList.remove('pressed');
                document.getElementById('rec-pause').classList.add('pressed');
                document.getElementById('rec-pause').title = 'Resume';
                this.recorder.pause();
                document.querySelectorAll('.equalizer div').forEach(div => {
                    div.classList.remove('up-down');
                });
            } else if (this.recorder.state === "paused") {
                document.getElementById('rec-start').classList.add('pressed');
                document.getElementById('rec-pause').classList.remove('pressed');
                document.getElementById('rec-pause').title = 'Pause';
                this.recorder.resume();
                document.querySelectorAll('.equalizer div').forEach(div => {
                    div.classList.add('up-down');
                });
            }
        }
    }

    audioBlob = null;
    stopRecording = async () => {
        try {
            if (this.recorder) {

                document.getElementById('rec-start').classList.remove('pressed');
                document.getElementById('rec-pause').classList.remove('pressed');
                document.querySelectorAll('.equalizer div').forEach(div => {
                    div.classList.remove('up-down');
                });

                if (this.audioStream) {
                    this.audioStream.getTracks().forEach(track => { track.stop(); });
                }
                this.recorder.stop();
                this.recorder.onstop = async (e) => {
                    this.audioBlob = new Blob(this.recordedData, {type: 'audio/mpeg'});
                    this.recordedData = [];
                    const audioUrl = window.URL.createObjectURL(this.audioBlob);
                    const player = document.getElementById('play-rec-audio');
                    player.src = audioUrl;
                    this.recorder = null;
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    sendAudio = async () => {
        try {
            if (this.audioBlob) {
                const audioFile = new File([this.audioBlob], new Date().toISOString() +'.mp3', {type: "audio/mpeg", lastModified: Date.now()});

                const res = await sendChatFile({file: audioFile, channel: this.selectedChatFriend.channel});
                if (res) {
                    if (res.status) {
                        this.audioBlob = null;
                        this.socket.emit('private-chat', {
                            to_id: this.selectedChatFriend.id,
                            from_user_id: this.state.user.id,
                            channel: this.selectedChatFriend.channel,
                        });

                        await this.getMessage();
                        this.chatAreaScroll();
                    } else {
                        toast.error(res.error);
                    }
                }
            } else {
                toast.info('Record audio first');
            }
        } catch (e) {
            console.log(e);
        }
    }

    acceptRejectContact = async (status) => {
        const res = await acceptRejectContact({userOne: this.selectedChatFriend.id, userTwo: this.state.user.id, status: status});
        if (res) {
            if (res.status) {
                toast.success(res.message, {autoClose: 3000});
                this.socket.emit('update-notification', {from_id: this.state.user.id, to_id: this.selectedChatFriend.id});
                if (status) {
                    const bk = this.state;
                    for(let key in bk.friendList) {
                        if (bk.friendList[key].id === this.selectedChatFriend.id) {
                            bk.friendList[key].status = 1;
                        }
                    }
                    this.setState(bk);
                } else {
                    await this.getFriends();
                    this.selectedChatFriend = { id: 0, name: '', channel: '', online: false, status: null, sender: null };
                    const bk = this.state;
                    bk.showChatBody = false;
                    this.setState(bk);
                }
            } else {
                toast.error(res.error, {autoClose: 3000});
            }
        }
    }

    render() {
        let infoMessage = ``;
        if (this.state.user.block_duration) {
            infoMessage = `Hey, sorry, but we had to block your account for 15 min as you avoided 7 people. To avoid being blocked in the future, please remember our house rules. One of our rules is that if you accept someone’s friend request, then kindly talk with them for at least 5 min to know more about this person or let the person know about you. Therefore before accepting someone’s request, please see their profile.`;
        }else if (this.state.user.block_status) {
            infoMessage = `Your account has been blocked, as you got blocked by users 20 times or you blocked 10 users just in a day. If you think our decision was wrong, please contact us.`;
        }

        return (
            <>

                {
                    infoMessage &&
                    <div className="top_colseable_area">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div id="id_show_notice">
                                        <i className="fa fa-question-circle-o"></i>
                                    </div>
                                    <div className="alert alert-secondary now-alert" role="alert" id="closeable_notice">
                                        { infoMessage }
                                        <button type="button" className="close" id="remvoe_notice">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }


                <section id="chatbody">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-lg-3 col-xl-3">
                                <div className="chat_sidebar_waper">

                                    {/*Own profile*/}
                                    <div className="user_own d-flex align-items-center">
                                        <div className="profile_img">

                                            {
                                                this.state.user.img &&

                                                <img src={`${baseUrl}/avatar/${this.state.user.img}`} alt=""/>

                                            }

                                            {
                                                !this.state.user.img && this.state.user.gender.toLowerCase() === 'male' &&
                                                <img src={`${baseUrl}/avatar/avatar - 09-01.png`} alt=""/>
                                            }

                                            {
                                                !this.state.user.img && this.state.user.gender.toLowerCase() === 'female' &&
                                                <img src={`${baseUrl}/avatar/avatar - 14-01.png`} alt=""/>
                                            }

                                            {
                                                !this.state.user.img && this.state.user.gender.toLowerCase() !== 'male' && this.state.user.gender.toLowerCase() !== 'female' &&
                                                <img src={`${baseUrl}/avatar/avatar-2877.png`} alt=""/>
                                            }

                                        </div>
                                        <div className="details">
                                            <p className="purple_color font-weight-bold text-uppercase">{this.state.user.name}</p>
                                            <p className="small_font">{this.state.user.cityName}, {this.state.user.countryName}</p>
                                        </div>
                                    </div>

                                    {/*Search*/}
                                    <div className="search_waper">
                                        <div className="form-group">
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                            <input type="text" className="form-control" id="search_chatuser"
                                                   placeholder="Search For Friends" onChange={this.filterFriendList} />
                                        </div>
                                    </div>

                                    {/*User profile*/}

                                    {
                                        this.state.friendList.length == 0
                                            ?

                                            <div className={'empty-chat-list'}>
                                                <h3>Find Friends Here</h3>
                                                <Link to={"/find-friends"} className="btn_outline">Find Friends</Link>
                                            </div>

                                            :

                                            this.state.friendList.map((friend, index) => {
                                                const cls = friend.online ? 'top_ticker online' : 'top_ticker';
                                                const chatBtnCls = friend.status ? 'chatnow' : 'chatnow not-friend';

                                                return (

                                                    <div className="friends_profile d-flex align-items-center" key={friend.id}>

                                                        {
                                                            ( this.selectedChatFriend.id !== friend.id && friend.hasNewMessage ) &&
                                                            <div className="notify">
                                                                <span className="heartbit"></span>
                                                                <span className="point"></span>
                                                            </div>
                                                        }

                                                        <div className="profile_img">

                                                            <Link to={`/profile/${friend.name}`}>
                                                                {
                                                                    friend.img &&

                                                                    <img src={`${baseUrl}/avatar/${friend.img}`} alt=""/>

                                                                }

                                                                {
                                                                    !friend.img && friend.gender.toLowerCase() === 'male' &&
                                                                    <img src={`${baseUrl}/avatar/avatar - 09-01.png`} alt=""/>
                                                                }

                                                                {
                                                                    !friend.img && friend.gender.toLowerCase() === 'female' &&
                                                                    <img src={`${baseUrl}/avatar/avatar - 14-01.png`} alt=""/>
                                                                }

                                                                {
                                                                    !friend.img && friend.gender.toLowerCase() !== 'male' && friend.gender.toLowerCase() !== 'female' &&
                                                                    <img src={`${baseUrl}/avatar/avatar-2877.png`} alt=""/>
                                                                }
                                                            </Link>
                                                            <span className={cls}> </span>
                                                        </div>
                                                        <div className="details">
                                                            <p className="purple_color font-weight-bold text-uppercase">{friend.name}

                                                                {
                                                                    parseInt(friend.isGuarded) === 1
                                                                        ?
                                                                        <i id='sgs' className="fa fa-shield guarded" aria-hidden="true" title='Safe Guarded'
                                                                           data-toggle={'modal'} data-target={'#guard-info'}
                                                                           onClick={() => {
                                                                               document.querySelector('#guard-info p:last-of-type').style.display = 'none';
                                                                               document.querySelector('#guard-info p:first-of-type').style.display = 'block';
                                                                           }
                                                                           }></i>
                                                                        :
                                                                        <i id='sgs' className="fa fa-shield unguarded" aria-hidden="true" title='UnGuarded'
                                                                           data-toggle={'modal'} data-target={'#guard-info'}
                                                                           onClick={() => {
                                                                               document.querySelector('#guard-info p:first-of-type').style.display = 'none';
                                                                               document.querySelector('#guard-info p:last-of-type').style.display = 'block';
                                                                           }
                                                                           }></i>
                                                                }

                                                            </p>
                                                            <p className="small_font">{friend.city}, {friend.country}</p>
                                                            <div className='action'>
                                                                <a href="#" className={chatBtnCls} onClick={()=>this.chat(friend)}>Chat Now</a>
                                                                {
                                                                    friend.galleryShared &&
                                                                    <button className="btn view-gallery" title={'View Gallery'}
                                                                            data-toggle='modal' data-target="#view-gallery-modal"
                                                                            onClick={ async () => {
                                                                                this._selectedUsersGallery = friend;
                                                                                await this.viewGallery();
                                                                            }}>
                                                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                    }

                                    {/*End Single*/}

                                </div>
                            </div>
                            {/*End Col*/}
                            <div className="col-12 col-lg-7 col-xl-7 pos_initial_mobile">


                                <div className="chat_area_show_hide">

                                    {
                                        this.selectedChatFriend.name == '' && !this.state.showChatBody
                                            ?

                                            <div className={'empty-message-box'}>
                                                <p>Hey! You are in the chat area now. To start a chat pick a friend from the list.</p>
                                            </div>

                                            :

                                            <>


                                                <div className="chat_body">
                                                    {/*Chat head*/}
                                                    <div className="chat_head d-flex justify-content-between align-items-start">
                                                        <div className="left">
                                                            <p className="d-block d-xl-none">
                                                                <a id="close_chat" href="#"><i
                                                                    className="fa fa-long-arrow-left"></i></a>
                                                            </p>
                                                            <a href="#" className={'open-chat-search'} title="Search inside inbox"><i className="fa fa-search"></i></a>
                                                            <input type="text" className="form-control" id="search_chat_msg"
                                                                   placeholder="Search Chat Message" onChange={this.filterChatMessage} />
                                                            <a href="#" title="Rate your experience" id={'open-rating'}
                                                               data-toggle="modal" data-target="#modal-rating">
                                                                <i className="fa fa-star" aria-hidden="true"></i>
                                                            </a>
                                                        </div>
                                                        <div className="right">

                                                            <div className={'block-user'}>
                                                                <span className={'remove-msg'}>
                                                                    <i className="fa fa-lock" aria-hidden="true"></i>
                                                                </span>
                                                                <a className={'remove-btn'} data-toggle={'modal'} data-target={'#block_confirm'}>Block</a>
                                                            </div>

                                                            <h5 className="red_color">{this.selectedChatFriend.name}</h5>
                                                            <p className="small_font text-right">{this.selectedChatFriend.city}, {this.selectedChatFriend.country}</p>
                                                        </div>
                                                    </div>
                                                    {/*Chat Body*/}
                                                    <div className="chat_messages">

                                                        {
                                                            !this.selectedChatFriend.status && this.selectedChatFriend.sender !== 'self' &&
                                                                <div className={'accept-reject-req'}>
                                                                    <div>
                                                                        <button className="btn btn_purple_mid_light"
                                                                                onClick={async () => {
                                                                                    await this.acceptRejectContact(1);
                                                                                }}
                                                                        >Accept</button>
                                                                        <button className="btn btn-info"
                                                                                onClick={async () => {
                                                                                    await this.acceptRejectContact(0);
                                                                                }}
                                                                        >Reject</button>
                                                                        <button className="btn btn-danger" data-toggle={'modal'} data-target={'#block_confirm'}>Block</button>
                                                                    </div>
                                                                </div>
                                                        }

                                                        {
                                                            this.state.messages.map((msg, index) => {
                                                                let cls = 'message';
                                                                let isSelf = false;

                                                                if (parseInt( msg.from_user_id ) === parseInt( this.state.user.id )) {
                                                                    cls += ' self';
                                                                    isSelf = true;
                                                                } else {
                                                                    cls += ' from';
                                                                }

                                                                let isImage = false;
                                                                let isAudio = false;
                                                                if (msg.file_type) {
                                                                    if (msg.file_type.toString().includes('image')) {
                                                                        isImage = true;
                                                                    } else if(msg.file_type.toString().includes('audio')) {
                                                                        isAudio = true;
                                                                    }
                                                                }

                                                                return (
                                                                    <div className={cls} key={index}>
                                                                        <div className="msg_waper position-relative">
                                                                            {
                                                                                isSelf &&
                                                                                <>
                                                                                    <span className={'remove-msg'}>
                                                                                        <ul><li>.</li><li>.</li><li>.</li></ul>
                                                                                    </span>
                                                                                    <a className={'remove-btn'} onClick={() => {this.removeMessage(msg.id)}}>Remove</a>

                                                                                </>
                                                                            }

                                                                            {
                                                                                msg.file_type ?

                                                                                    isImage ?

                                                                                        <img src={baseUrl + '/chat_message/' + msg.file_name} width="250" alt={msg.file_name} />

                                                                                        :

                                                                                        isAudio ?

                                                                                            <audio controls>
                                                                                                <source src={baseUrl + '/chat_message/' + msg.file_name} type={msg.file_type} />
                                                                                                Your browser does not support the audio tag.
                                                                                            </audio>

                                                                                            :

                                                                                            <a href={`${baseUrl}/chat_message/${msg.file_name}`} target={'_blank'}>{msg.file_name}</a>

                                                                                    :

                                                                                    <p dangerouslySetInnerHTML={{__html: msg.message}} />
                                                                            }

                                                                            <span className={'msg-time'}>{moment(msg.created_at).format("lll")}</span>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                    </div>
                                                    {/*End chat messages*/}
                                                </div>
                                                <div className="chat_footer" id={'chat_footer'}>
                                                    <div className="d-flex w-100 align-items-center">
                                                        <div className="d-flex mr-3">
                                                            <div className="btn-group dropup">
                                                                <a href="#" className="" data-toggle="dropdown"
                                                                   aria-haspopup="true" aria-expanded="false">
                                                                    <i className="fa fa-plus-circle gray_color"
                                                                       aria-hidden="true"></i>
                                                                </a>
                                                                <div className="dropdown-menu">
                                                                    <a className="dropdown-item" href="#"
                                                                       data-toggle="modal" data-target="#modal-share-gallery">
                                                                        <i className="fa fa-picture-o"></i> Share Gallery</a>
                                                                    <a className="dropdown-item" href="#"><i
                                                                        className="fa fa-file"></i> <label htmlFor={'send-file'}>Share Files</label>
                                                                    </a>
                                                                    <a href="#" className="dropdown-item disabled" title="Coming Soon"
                                                                       onClick={() => {
                                                                           toast.info('Coming Soon');
                                                                       }}>
                                                                        <i className="fa fa-phone" aria-hidden="true"></i> <label htmlFor={'send-file'}>Voice Chat</label>
                                                                    </a>

                                                                </div>
                                                                <input type="file" id={'send-file'} style={{display: "none"}} accept="image/*,audio/mpeg,audio/wav,.doc,.docx" onChange={this.sendFile}/>

                                                            </div>

                                                            <div className="smily">
                                                                <a href="#" className="disabled" title="Select Emojis"
                                                                   onClick={() => {
                                                                       const list = document.getElementById('smiley-list');
                                                                       list.style.display = 'flex';
                                                                       list.focus();
                                                                   }}>
                                                                    <i className="fa fa-smile-o" aria-hidden="true"></i>
                                                                </a>

                                                                <ul id='smiley-list' tabIndex={-1} onBlur={() => {
                                                                    const list = document.getElementById('smiley-list');
                                                                    list.style.display = 'none';

                                                                }} onClick={() => {}} >
                                                                    {
                                                                        this.state.emojis.map((emo, i) => {
                                                                            return (
                                                                                <li key={i} onClick={() => {
                                                                                    this.addEmoji(emo);
                                                                                }} dangerouslySetInnerHTML={{__html: emo}} />
                                                                            );
                                                                        })
                                                                    }
                                                                </ul>


                                                            </div>

                                                            <div className="smily">
                                                                <a className="disabled" href="#" title={'Share Audio'}
                                                                   data-toggle={'modal'} data-target={'#modal-rec-audio'}>
                                                                    <i className="fa fa-microphone"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="form-group m-0 w-100 position-relative text-input-container">

                                                            {
                                                                this.state.chatMessage === '' &&
                                                                <span className='empty-chat'>Type a message...</span>
                                                            }

                                                            <span id='text' contentEditable={"true"}

                                                                  onFocus={this.inputFocus}
                                                                  onBlur={this.inputBlur}
                                                                  onKeyUp={this.sendMessage}
                                                            />

                                                            <i className="fa fa-paper-plane send_action" aria-hidden="true" onClick={async () => {
                                                                document.getElementById('text').innerHTML = '';
                                                                document.getElementById('text').focus();
                                                                await this.send();
                                                            }}></i>

                                                        </div>

                                                    </div>

                                                    <div className="ticontainer" id={'ticontainer'}>
                                                        <span>Typing</span>
                                                        <div className="tiblock">
                                                            <div className="tidot"></div>
                                                            <div className="tidot"></div>
                                                            <div className="tidot"></div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </>
                                    }


                                </div>


                            </div>
                            {/*end col*/}
                            <div className="col-12 col-lg-2 col-xl-2 mt-4 mt-lg-0 mt-xl-0">
                                <div className="profile_listing_right">

                                    {
                                        this.state.adsRandProductList &&
                                            <>
                                                {
                                                    this.state.adsRandProductList.map((adsRandProduct, index) => {
                                                        return (

                                                            <div className="single_product sidebar_product" key={index}>
                                                                <a  target="_balnk" href={adsRandProduct.outsite_url}>
                                                                    <img src={`${baseUrl}/assets/uploads/adsproduct/${adsRandProduct.img}`} alt="Product image" />
                                                                </a>
                                                            </div>

                                                        );
                                                    })
                                                }
                                            </>
                                    }

                                </div>
                            </div>
                        </div>
                        {/*End Row*/}
                    </div>
                    {/*End Container fluid*/}
                </section>

                <div className="modal fade modal_rec_audio" id="modal-rec-audio" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body p-2">

                                <h4>Record Audio</h4>

                                <div className={'rec-control'}>
                                    <i className="fa fa-microphone" id={'rec-start'} title={'Start Recording'} aria-hidden="true" onClick={this.startRecoding}></i>
                                    <i className="fa fa-pause" id={'rec-pause'} title={'Pause  Recording'} aria-hidden="true" onClick={this.pauseRecording}></i>
                                    <i className="fa fa-stop text-danger" id={'rec-stop'} title={'Stop  Recording'} aria-hidden="true" onClick={this.stopRecording}></i>
                                    <i className="fa fa-share" title={'Share Recording'} aria-hidden="true" onClick={ async () => {
                                        document.querySelector('#modal-rec-audio .modal_close').click();
                                        await this.sendAudio();
                                    }}></i>
                                </div>

                                {
                                    <div className="equalizer">
                                        {
                                            Array.apply(null, Array(50)).map((key, i) => {

                                                return (
                                                    <div style={{animationDelay: (Math.ceil(Math.random() * 9)  * 0.1).toFixed(1) + 's'}} key={i}></div>
                                                );
                                            })
                                        }
                                    </div>
                                }

                                <audio id={'play-rec-audio'} controls={true}></audio>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade modal_tnc" id="modal-rating" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body p-0">

                                <div className="tnc_content">

                                    <h4 className="text-white text-left">Rate your experience</h4>
                                    <div className="top_carret">
                                        <i className="fa fa-caret-down"></i>
                                    </div>
                                </div>

                                <div className="modal_waper_main bg-white">
                                    <div className="modal_header p-5 text-left">

                                        <div className="review__rating">

                                            <input type='radio' value='0' id='star-0' checked={this.state.ratingPoint === '0'} name={'ratingPoint'} onChange={this.giveRating} />

                                            <label htmlFor='star-1'>★</label>
                                            <input type='radio' value='1' id='star-1' checked={this.state.ratingPoint === '1'} name={'ratingPoint'} onChange={this.giveRating} />

                                            <label htmlFor='star-2'>★</label>
                                            <input type='radio' value='2' id='star-2' checked={this.state.ratingPoint === '2'} name={'ratingPoint'} onChange={this.giveRating} />

                                            <label htmlFor='star-3'>★</label>
                                            <input type='radio' value='3' id='star-3' checked={this.state.ratingPoint === '3'} name={'ratingPoint'} onChange={this.giveRating} />

                                            <label htmlFor='star-4'>★</label>
                                            <input type='radio' value='4' id='star-4' checked={this.state.ratingPoint === '4'} name={'ratingPoint'} onChange={this.giveRating} />

                                            <label htmlFor='star-5'>★</label>
                                            <input type='radio' value='5' id='star-5' checked={this.state.ratingPoint === '5'} name={'ratingPoint'} onChange={this.giveRating} />

                                        </div>

                                        <div className="form-group mt-4">
                                            <label htmlFor="exampleFormControlTextarea1">Say some thing:</label>
                                            <textarea className="form-control" id="exampleFormControlTextarea1" name={'ratingMessage'} onChange={this.giveRating}
                                                      rows="5"></textarea>
                                        </div>

                                        <div className="continue  text-center mt-5">
                                            <a href="#" className="btn_now_purple btn_purple" onClick={this.giveReview}>Submit</a>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade modal_tnc" id="modal-share-gallery" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body p-0">

                                <div className="tnc_content">

                                    <h4 className="text-white text-left">Do you want to share your gallery with {this.selectedChatFriend.name}? When you share a gallery with any user, this particular user will be able to see all of your gallery images.</h4>
                                    <div className="top_carret">
                                        <i className="fa fa-caret-down"></i>
                                    </div>
                                </div>

                                <div className="modal_waper_main bg-white">
                                    <div className="modal_header p-5 text-left">

                                        <div className="continue  text-center mt-5">
                                            <button className="btn_now_purple btn_purple"
                                                    id={'share-btn'} onClick={this.shareGallery} >Share Now</button>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade modal_tnc" id="block_confirm" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body p-0">

                                <div className="tnc_content">

                                    <h4 className="text-white text-left">Are you sure you want to block {this.selectedChatFriend ? this.selectedChatFriend.name  : ''}?</h4>
                                    <div className="top_carret">
                                        <i className="fa fa-caret-down"></i>
                                    </div>
                                </div>

                                <div className="modal_waper_main bg-white">
                                    <div className="modal_header p-5 text-left">
                                        <p><b className="text-danger">Warning -</b> if you block 10 users in a day, your account will be blocked until that you contact NowPurple admins.!</p>
                                        <div className={'reason-block'}>
                                            <label htmlFor="block-reason" className={'mt-3 mb-3 font-weight-bold text-secondary'}>Reason Of Blocking</label>
                                            <label><input type={'radio'} name={'reason'} onChange={this.updateBlockReason} value={'Bothering me by using vulgar words'} /> Bothering me by using vulgar words</label>
                                            <label><input type={'radio'} name={'reason'} onChange={this.updateBlockReason} value={'Violent behavior'} /> Violent behavior</label>
                                            <label><input type={'radio'} name={'reason'} onChange={this.updateBlockReason} value={'Underage person'} /> Underage person</label>
                                            <label><input type={'radio'} name={'reason'} onChange={this.updateBlockReason} value={'Sharing violent or inappropriate images'} /> Sharing violent or inappropriate images</label>
                                            <label><input type={'radio'} name={'reason'} onChange={this.updateBlockReason} value={'Other'} /> Other</label>
                                            <textarea name="block-reason" id="block-reason" cols="30" rows="5" onChange={this.updateBlockReason}></textarea>
                                        </div>

                                        <div className="continue  text-center mt-5">
                                            <button className="btn_now_purple btn_danger"
                                                    id={'block-btn'} onClick={async () => { await this.confirmBlocking()}}>Block User</button>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <input id={'first-msg-btn'} type="button" style={{display: 'none'}} data-toggle={'modal'} data-target={'#first-msg-modal'} />
                <div className="modal fade modal_tnc" id="first-msg-modal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body p-0">

                                <div className="modal_waper_main bg-white">
                                    <div className="modal_header p-5 text-justify">

                                        <p className="text-light-gray">{this.firstSentMsg}</p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade modal_tnc" id="view-gallery-modal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body p-0">

                                <div className="modal_waper_main bg-white">
                                    <div className="modal_header p-5 text-justify">
                                        <h4 className="border-bottom mb-3 pb-3">Gallery</h4>
                                        <div className="gallery_waper">
                                            <div className={'row'}>

                                                {
                                                    this.state.selectedGallery.map((item, i) => {
                                                        return (

                                                            <div className="gal_image" key={i}>
                                                                <a href={`${baseUrl}/image_gallery/${item.name}`}
                                                                   className="with-caption image-link">
                                                                    <img src={`${baseUrl}/image_gallery/${item.name}`}/>
                                                                </a>
                                                            </div>


                                                        );
                                                    })
                                                }

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade re_modal" id="guard-info" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body">
                                <div className="modal_waper_main_profile bg-white">

                                    <p>This user has Safeguard activated. Meeting this user physically is safe.</p>
                                    <p>This user has not activated Safeguard. Meeting this user physically is not safe.</p>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <audio id="chat-received">
                    <source src="audio/message-received.mp3" type="audio/mpeg" />
                </audio>

                <section id={'customScript'}></section>

            </>
        );
    }
}

export default Chat;
