import React from "react";
import {resetPassword} from "../services/ApiService";
import {toast} from "react-toastify";

class RenewPassword extends React.Component {

    state = {}

    key = '';
    componentDidMount() {
        this.key = this.props.match.params.key;
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        }
    }

    password = '';
    cfPassword = '';
    flag = false;
    checkPass = () => {
       if (this.password !== this.cfPassword || this.password === '') {
           this.flag = false;
           document.getElementById('confirm').style.borderColor = '#fc7f7f';
       } else {
           this.flag = true;
           document.getElementById('confirm').style.borderColor = 'initial';
       }
    }

    resetPass = async () => {
        if (this.flag) {
            console.log(this.password, this.cfPassword, this.key);
            const res = await resetPassword({secretKey: this.key, password: this.password});

            if (res) {
                if (res.status) {
                    toast.success(res.message);
                    setTimeout(() => {
                        window.location.href = '/';
                    }, 2000);
                } else {
                    toast.error(res.error);
                }
            }
        }
    }

    render() {

        return (
            <>
                <div className="page_hero"></div>

                <div className={'reset-pass'}>

                    <h4>Set your new password</h4>

                    <div className="form-group">
                        <label htmlFor="pass">New Password</label>
                        <input type="password" className="form-control" id="pass"
                               placeholder="New Password" onChange={(e) => {
                            e.persist();
                            this.password = e.target.value;
                            this.checkPass();
                        }} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="confirm">Confirm Password</label>
                        <input type="password" className="form-control" id="confirm"
                               placeholder="Confirm Password" onChange={(e) => {
                            e.persist();
                            this.cfPassword = e.target.value;
                            this.checkPass();
                        }} />
                    </div>

                    <button className="btn btn-secondary mb-2" onClick={this.resetPass}>Reset</button>


                </div>

            </>
        );

    }

}

export default RenewPassword;
