import React from "react";

class Terms extends React.Component {

    componentDidMount() {
        document.title = 'Terms and Conditions | NowPurple Free Dating Website UK';
        document.getElementsByTagName('meta')["description"].content = 'Terms and Conditions page of NowPurple. Read our terms and conditions here. When our users register (free) on NowPurple, they agree to be bound by these General Terms and Conditions of Use.';
        document.getElementsByTagName('meta')["keywords"].content = 'free dating site uk';

        document.body.classList.add('static');
        document.documentElement.scrollTop = 0;
    }
    componentWillUnmount() {
        document.body.classList.add('static');
    }

    render() {
        return (
            <>

                <div className="page_hero"></div>


                <section id="static">
                    <div className="container terms">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="page_title text-center">
                                    <h3 className="purple_color">Terms and Conditions</h3>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <div className="">

                                    <p>NowPurple</p>
                                    <p>General Terms and Conditions of Use</p>
                                    <p>(further here referred to also as Terms)</p>
                                    <p>Version: October 2020</p>
                                    <p>The following terms and conditions set out the relationship between NowPurple Ltd, registered at Unit GF 20, 58 Nelson Street, London E1 2DE, GreatBritain (“NowPurple", "us", "our", "we") and its customers ("you") when you register for and use the NowPurple Service (defined below).</p>
                                    <p>NowPurple is a free online dating platform with several unique features. We do not ask any email address or phone number upon registration. We also do not charge for any additional services. </p>
                                    <p>If you are under the age of 18 years old, you are not allowed to use the Service. </p>
                                    <p>You can only use the Service for your personal use – commercial use is not permitted (registering as a member in order to promote any commercial services, apart from joining “Shop to help” program on our platform).</p>
                                    <p>By registering to or using the NowPurple Service, you agree to be bound by these General Terms and Conditions of Use.</p>
                                    <p>You acknowledge that we offer our Service worldwideover the internet and, as such, you acknowledge that the information included in your profile will be visible to our members in all countries (wherever they are). You will also be able to search for a partner in any country (if there are members from that country registered on our platform), not just your own country.</p>
                                    <p>You also understand that we will use your personal information in accordance with our Privacy Policy (see Clause 4 below).</p>
                                    <p>If you do not agree to be bound by all the Terms, you will not be able to register for, or use, the Service.</p>

                                    <h3>1. Object of your Registration</h3>

                                    <ul>
                                        <li><b>1.1</b> Once you have signed up for an account (free of charge, as all our features are free), NowPurple will provide you with access to an online database over which you will be able to get to know other NowPurple registered customers (also known as members) (the “NowPurple Service” or “Service”).
                                            The NowPurple Service can only be accessed via our website (although, in the future, other operating systems may be made available). We do not charge for any services, but we do offer advertisers to advertise products on our platform(in “Shop to help” area only, which you are free to purchase or not to purchase if you so choose.
                                        </li>
                                        <li>
                                            <b>1.2</b> The online database provided by NowPurple contains "profiles" about NowPurple members, which contain information made available by those members. You can find more information about the types of personal information that are collected and how this information is made available to other members in our Privacy Policy (see Clause 4 below).It is possible for our members to search for and contact other members in any of the countries in which we operate.
                                        </li>
                                        <li>
                                            <b>1.3</b> Please be aware that while we hope that by using our Service you will successfully meet and form a meaningful connection or relationship with another NowPurple member, we cannot guarantee that your use of the Service will result in a relationship, matrimony or any other long-term or meaningful commitment. We are not and do not act as a wedding agency in relation to our Service.
                                        </li>

                                    </ul>

                                    <h3>2. Access of the Service and Conclusion of your Registration</h3>

                                    <ul>
                                        <li><b>2.1</b> To access and use the NowPurple Service you will need to register. To do this, you will NOT NEED to provide any email addresses or phone number or any similar personal information. But you will need to provide only a username and password of your choice. Once you have registered, you will be automatically allocated a "unique profile ID” (a pseudonym).</li>
                                        <li><b>2.2</b> Once you have successfully registered, you will be able to access our free-of-charge Service. We do not charge for any additional services. All functions included in our platform are completely free of charge. Your use of this Service will be subject to the terms set out in these General Terms and Conditions of Use.</li>
                                        <li><b>2.3</b> As our system does not require you to enter any email address upon registration, it is very important that you remember your login details. You can however decide to set up password recovery function inside your profile after registration, but this will require for you to set your email address there.</li>
                                    </ul>

                                    <h3>3. Free-of-Charge and Chargeable Services</h3>

                                    <ul>
                                        <li><b>3.1</b> All our memberships are free of charge and we do not charge for any additional functions (such as viewing profiles, capability of sending more messages etc).</li>
                                        <li><b>3.2</b> We may change the Service: (i) to reflect changes in relevant laws and regulatory requirements; or (ii) to implement minor technical adjustments and improvements, for example to address a security threat (however, these changes will not affect your use of the Service). In addition, although we provide a description of our Service on our website, we may change the main characteristics of our Service from time to time. However, if we do this, we will notify you by push notifications in your profile or elsewhere on our platform and you may then contact us to end your membership before the changes take effect.</li>
                                        <li><b>3.3</b> Please be aware that although we aim to make the NowPurple Service available 24 hours a day, seven days a week, we cannot guarantee that the Service will be available 100% of the time. Our Service availability may be reduced due to any downtime related to maintenance and software updates, as well as any periods during which the Service is unavailable via the Internet due to technical or other problems that are beyond NowPurple's control. In order to be able to use the NowPurple Service fully, you should use up-to-date (browser) technologies on your computer (e.g. enable Java script, cookies, pop-ups). If you use an older operating system or internet service provider, you may find that you only have limited access to the NowPurple Service.</li>
                                    </ul>

                                    <h3>4. Use, Transfer and Transmission of Data(incl. for advertising purposes)</h3>

                                    <ul>
                                        <li><b>4.1</b> You can find further information about how we use your personal information and what our obligations are in relation to data protection in our privacy policy.</li>
                                        <li><b>4.2</b> NowPurple constantly strives to protect you from harassment such as hacker attacks, spam or romance scamming. To this end, NowPurple has developed numerous technical and organisational standards. It is your responsibility to familiarise yourself with NowPurple’s security tips before using the NowPurple service and to follow these tips and the user guidelines in Clause 7 below. If NowPurple’s trust and safety team contacts you in the event of anomalies, you are obliged to cooperate with and respond to immediately if such a request is made. If we recommend actions, you should seriously consider them and not reject them without serious cause. You should also report problematic content or behaviour to NowPurple by contacting us via our Contact Page.</li>
                                        <li><b>4.3</b> In case we suspect that you are an underage person, we may need to ask you to provide personal information such as passport or ID card or other in order to verify your age. In such case, if you refuse to provide information we requested, we reserve the right to terminate your membership and close your profile. </li>
                                    </ul>

                                    <h3>5. House rules</h3>

                                    <ul>
                                        <li><b>5.1</b> As a member, you will be able to contact and exchange communications with other members (provided that they agree to communicate with you), without limitation. Please note that we may limit the number of communications that you can send if required in order to protect our members and to secure our Service as further set out in these Terms and Conditions of use.</li>
                                    </ul>

                                    <h4>5.2 User blocking rules </h4>

                                    <ul>
                                        <li>As a member, you will be able to block other members if they are behaving inappropriately. We will offer you option to block another member and to select a reason why you decided to block them. </li>
                                        <li>Please note that if you block 10 members in a day, yourprofile will be blocked until you contact NowPurple admin via Contact Page.</li>
                                        <li>You will be able to see blocked member list in your profile, and if you want, you can unblock any of those members.NowPurple reserves the right to unblock any of the blocked members if needed.</li>
                                    </ul>

                                    <h4>5.3. Flag rules:</h4>

                                    <ul>
                                        <li>NowPurple will use flags in order to indicate suspicious behaviour to other members.</li>
                                        <li>Light yellow flag – after a member gets blocked by other members 3 times within 1 day of setting up the profile.</li>
                                        <li>Deep yellow flag – after a member gets blocked by other members 10 times within 3 days of setting up the profile.</li>
                                        <li>Red flag – after the user gets blocked by other members 20 times within 3 days of setting up the profile.</li>
                                    </ul>

                                    <h4>5.4. Other blocking rules: </h4>

                                    <ul>
                                        <li>If a member finishes conversation in less than 5min with 7 users (within 3 hours), then he/she gets blocked for 15 min.</li>
                                        <li>If a user gets blocked 20 times in 1 day, NowPurple will block the user permanently.</li>
                                    </ul>

                                    <h4>5.5. Review system:</h4>

                                    <ul>
                                        <li>If members talk with each other minimum 10 min they will get a reviewspopup allowing the users to review each other with a star rating (1-5 stars). </li>
                                        <li>Reviews will appear on member profile display page and on full profile.</li>
                                    </ul>

                                    <h4>5.6. Reminder about the "Shop to help"</h4>

                                    <p>Time after time NowPurple will offer you to purchase products in our “Shop to help” area. Members are not obliged to do any shopping in our “Shop to help” area, but as this is our main source of income, by shopping with us in “Shop to help” area you will also help to continue running NowPurple platform successfully and continuously. </p>

                                    <h4>5.7. Termination/Extension</h4>

                                    <p>You can terminate your membership at any time with immediate effect. You can do this in your profile management area. Otherwise, we will continue to provide you with the Service unlesswe end your Registration in accordance with these General Terms and Conditions of Use.</p>

                                    <p><b>5.8</b> Subject to Clause 3.2, if you want to terminate your membership because we have told you about an upcoming substantial change to our Service to which you do not agree, your Registration will end immediately after we receive your termination notice in writing via our Contact Page or if you terminate your profile yourself from inside your profile admin area. </p>

                                    <h3>6. Liability of NowPurple</h3>

                                    <ul>
                                        <li><b>6.1</b> You are responsible for the accuracy of the information provided during your registration. If you need to update your profile or registration information, you can generally do this at any time through your profile. However, there is some information that can only be updated by contacting us using the details in Clause 16 (and this will be clear to you at the time).</li>
                                        <li><b>6.2</b> NowPurple is only responsible for the technical provision of the NowPurple Service (i.e. the provision of our Service, as further described in Clause 3).NowPurple will generally enable you to make contacts with other members. We cannot, however, guarantee that you will be able to successfully secure a meaningful connection or relationship as a result of using the Service.</li>
                                        <li><b>6.3</b> While we expect our members to use the Service in a kind and respectful way (and in accordance with these General Terms and Conditions of Use), NowPurple is not responsible for any misuse of personal information made available through the Service by its members, or for any other use of the Service by its members which is contrary to these General Terms and Conditions of Use, or any use that is otherwise unauthorised or unlawful. NowPurple is also not responsible for any misuse of data or information by third parties (i.e. other members or non-members) where you have provided the third party with such information yourself.</li>
                                        <li><b>6.4</b> While NowPurple works hard to provide a high-quality service, NowPurple does not guarantee that the Service will perform perfectly at all times and cannot ensure that the Service will always be uninterrupted, secure or error-free. In particular, NowPurple is not liable for disruptions that affect the accessibility of the Service where such disruptions are caused by actions or events that are outside NowPurple's control. Furthermore, although NowPurple will put appropriate technical and organisational security measures in place to protect your personal information, to the fullest extent permitted by law, NowPurple will not be liable for any unauthorized access by third parties to your personal data (e.g. due to hackers gaining unauthorised access to our database).</li>
                                        <li><b>6.5</b> Except as expressly provided in these General Terms and Conditions of Use, we exclude all representations, warranties, conditions and terms (whether express or implied by statute, common law or otherwise) to the fullest extent permitted by law. We accept no liability for any losses or damages which are not reasonably foreseeable arising out of or in connection with these General Terms and Conditions of Use or your use of our Service.</li>
                                        <li><b>6.6</b> As described below in Clause 10, these exclusions shall be governed by and construed in accordance with English law, subject to other mandatory laws, which cannot be excluded in your country of residence. If any provision of these disclaimers and exclusions shall be unlawful, void or for any reason unenforceable then that provision shall be deemed severable and shall not affect the validity and enforceability of the remaining provisions.</li>
                                        <li><b>6.7</b> Nothing in these General Terms and Conditions of Use shall exclude or limit our liability for personal injury or death caused by our negligence, or fraud. In addition, these General Terms and Conditions of Use shall not limit or exclude any other liability that we are not permitted to limit or exclude under applicable law.</li>
                                    </ul>

                                    <h3>7. Responsibility and Duties of the Member</h3>

                                    <ul>
                                        <li><b>7.1</b> As mentioned above, you are responsible for the accuracy of the content that you provide at registration and for all the information you provide about yourself in your profile. By providing us with this information, you agree and confirm that it is truthful and accurate. Intentional and / or fraudulent misrepresentation of your identity, including use of another or fictitious identity, can also subject you to potential legal liability.</li>
                                        <li><b>7.2</b> By using the Service, you agree that you will only use the NowPurple Service for private and personal reasons and that you do not use or intend to use the Service for commercial reasons. You also agree that you will not use any information about third parties (including other members) that you receive through the Service for commercial or advertising purposes. You are in particular not allowed to use technological methods or processes to automatically download or otherwise access information about other members in order to use this information outside of the NowPurple Service (e.g. by a computer program or by copying and pasting the content). You also agree that the use of computer programs for the purpose of automatically reading files of member data (such as crawlers), is prohibited.</li>
                                        <li><b>7.3</b> If you materially breach these Terms and Conditions of Use, or otherwise breach the law in relation to your use of our Service, NowPurple may be entitled to claim damages in accordance with the general rules of English law.</li>
                                        <li><b>7.4</b> You will treat messages received via or in relation to the Service confidentially and you will not disclose these messages to third parties without the consent of the sender or serious cause. The same rules also apply to any names, phone and fax numbers, home addresses, e-mail addresses and/or URLs, or other personal information of other NowPurple members shared to you by them.</li>
                                        <li><b>7.5</b> You agree that you will retrieve any messages you receive (as well as any information contained in your profile that you would like to keep) at regular and appropriate intervals and, if required, that you will archive this information on your own computer or other data storage system. You understand that NowPurple may delete any messages stored in your account after a period of 30 days following the date the message was received, without further notice to you. </li>
                                        <li>
                                            <b>7.6</b> By registering for a NowPurple account, you agree and represent that you will not misuse the Service and comply with our conduct requirements. In particular, you will not use the Service:
                                            <ul className={'use-list-style'}>
                                                <li>to distribute any immoral, obscene, pornographic content or photos</li>
                                                <li>to distribute any defamatory, offensive or otherwise illegal content or information</li>
                                                <li>to threaten or harass other members, or to infringe any third-party rights (including personal rights)</li>
                                                <li>to upload any data which contains a virus (such as infected software); or to upload data which contains a software or other content which is protected by copyright, unless you own the rights in that content or have otherwise obtained the necessary consents</li>
                                                <li>in such a manner that your use will adversely affect the availability of offers to other members</li>
                                                <li>to intercept any messages or to attempt to intercept them</li>
                                                <li>to send any messages to members for any purpose other than to communicate, and in particular you will not use the Service to promote or to offer goods or services to other members</li>
                                                <li>to send any chain letters</li>
                                                <li>to send any messages that serve a commercial purpose</li>
                                                <li>to provide in your personal description and contact requests any names, addresses, phone or fax numbers, e-mail addresses, etc. if this information is not explicitly requested</li>
                                            </ul>
                                        </li>
                                        <li><b>7.7</b> If you do not comply with the conduct requirements as mentioned above in Clauses 7.1-7.7, or if you fail to materially comply with any other of the Terms, we may take the following actions: we may ask you to stop your non-compliant activities (or otherwise send you a warning that your activities do not comply with our conduct requirements); we may delete any content which you have submitted via the Service in breach of any of our conduct requirements; we may suspend the provision of the Service to you (in whole or in part) until the issue is resolved (for example, while we investigate your activities); or (if it is clear to us that you are seriously misusing the Service) we may terminate the provision of the Service to you. </li>
                                    </ul>

                                    <h3>8. Communication</h3>

                                    <p>NowPurple is an online service. As such, NowPurple will generally communicate with its members using online methods, such as messages and push notifications inside member profile. You can contact us viaour Contact Page, but only if you have any questions relating to your Registrationor in relation to your security concerns. As a digital service, our Service is only provided online. Therefore, you can only sign up to, use and register to our Service online.</p>
                                    <p>To make sure that we do not receive unauthorised requests (and to protect your account from abuse), please make sure that you provide the following information in any communications you send to us, so we can authenticate that the message has been sent from you:</p>
                                    <ul className={'use-list-style'}>
                                        <li>your profile name</li>
                                        <li>your unique profile ID</li>
                                    </ul>
                                    <p>As mentioned above, your unique profile ID is assigned to you when you register to our Service. You can access it at any time in your online profile.</p>

                                    <h3>9. Rights of Use and Copyright</h3>

                                    <ul>
                                        <li><b>9.1</b> By using our Service, you understand and agree that NowPurple is the sole owner of all the rights in and related to the Service, including any rights of reproduction, distribution and processing, all copyrights, as well as the right of the intangible transmission and reproduction of the NowPurple website and of the content included within it. The use of any program, content, materials, trademarks as well as commercial names contained within the Service is only permitted for the purposes set out in these General Terms and Conditions of Use.</li>
                                        <li><b>9.2</b> By creating an account, you grant NowPurple the right to use (including to host, store, use, copy, display, reproduce, adapt, edit, publish, modify or distribute) any information you make available on the Service (including any information you include in your profile) for the purposes of providing the Service to you and other members.</li>
                                        <li><b>9.3</b> You understand that we have the right (but not the obligation) at our sole discretion to refuse to post, or to remove, any information that you make available on the Service; and that we have the right to change, condense or delete such content. For example, we may remove any content that violates these General Terms and Conditions of Use or is otherwise objectionable.</li>
                                        <li><b>9.4</b> We respect the intellectual property of others and we expect our members to do the same. If you believe that any content that you see on our Service has been (or is being) used in a way which is an infringement of a third party's rights, please contact us using the contact details below (or via any other reasonable means of communication).</li>
                                    </ul>

                                    <h3>10. Governing Law</h3>

                                    <p>These General Terms and Conditions of Use shall be governed by, and construed in accordance with, English Law.</p>

                                    <h3>11. Cancellation Policy, Exclusion of the Right to Cancel</h3>

                                    <h4>11.1 Right to Cancel</h4>
                                    <p>To exercise your right to cancel, you must notify us using thecontact form on our website.</p>
                                    <p>You must also provide us with a clear written statement of your decision to cancel your Registration. Please make sure that you provide us with the information listed below under Clause 11.2.</p>

                                    <h4>11.2 Please make sure that you provide us for a clear identification with the following two indications:</h4>
                                    <ul className={'use-list-style'}>
                                        <li>your profile name</li>
                                        <li>your unique profile ID</li>
                                    </ul>

                                    <h3>12. Your legal rights</h3>
                                    <p>You are entitled for the Service to be as described, fit for purpose and of satisfactory quality. </p>

                                    <h3>13. Miscellaneous</h3>

                                    <ul>
                                        <li><b>13.1</b> NowPurple may use third-party service providers and agents, including entities belonging to our group, to help us provide the Service to you.</li>
                                        <li><b>13.2</b> Should any provision of the Terms be or become invalid, or should they be incomplete, it shall not affect the validity of the remaining content of the Terms.</li>
                                        <li><b>13.3</b> The language of these Terms is English.</li>
                                        <li><b>13.4</b> We may assign, transfer or sub-contract any of our rights or obligations under these Terms to any third party at our discretion. However, where we have engaged a third party to perform part of the contract with you, we remain liable towards you for the full performance of our agreement and also for the performance by the third party.
                                            You and NowPurple and any third parties we assign, transfer or sub-contract any of our rights to, may enforce these Terms in accordance with the Contracts (Rights of Third Parties) Act 1999. Apart from this no other person has rights under the Contract (Rights of Third Parties) Act 1999 to enforce these Terms.
                                        </li>
                                        <li><b>13.5</b> Please note that we reserve the right to change and update these General Terms and Conditions of Use from time to time. </li>
                                    </ul>

                                    <h3>14. Contact Us</h3>

                                    <p>NowPurple Ltd, registered at Unit GF 20, 58 Nelson street, London, E1 2DE, United Kingdomis responsible for the content of the NowPurple Service. You can contact us using the following contact details:</p>
                                    <p><b>by post:</b></p>
                                    <p><b>NowPurple Ltd,</b></p>
                                    <p><b>Unit GF 20,</b></p>
                                    <p><b>58 Nelson street,</b></p>
                                    <p><b>London, E1 2DE</b></p>
                                    <p><b>United Kingdom</b></p>

                                    <p>or by e-mail: <b>support@NowPurple.co.uk</b></p>


                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </>
        );
    }
}

export default Terms;
