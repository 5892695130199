import React from "react";

class CookiePolicy extends React.Component {

    componentDidMount() {
        document.title = 'Cookie Policy | NowPurple Free Dating Website UK';
        document.getElementsByTagName('meta')["description"].content = 'Cookie Policy page of NowPurple. Read our cookie policy here. When our users register (free) on NowPurple, they agree to be bound by this Cookie Policy.';
        document.getElementsByTagName('meta')["keywords"].content = 'free dating site uk';

        document.body.classList.add('static');
        document.documentElement.scrollTop = 0;
    }
    componentWillUnmount() {
        document.body.classList.add('static');
    }

    render() {
        return (
            <>

                <div className="page_hero"></div>


                <section id="static">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="page_title text-center">
                                    <h3 className="purple_color">Cookie Policy</h3>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <div className="">
                                    <h3>Managing Your Cookie Preference</h3>

                                    <p>Some features on this website require cookies, which are only stored on your device and used if you give your consent for us to do so. We have provided a simple tool below to accept or refuse them as you wish. Any time you wish to modify your choice, all you have to do is come back to this page.</p>

                                    <h3>Cookie Settings</h3>

                                    <h4>Introduction</h4>

                                    <p>NowPurple is committed to protecting your privacy. We aim to provide trustworthy, industry-leading products and services so that you can focus on building meaningful connections and relationships. Our approach to privacy is to provide you with clear information about our data practices. That's why we've tried to keep legal and technical jargon to a minimum.</p>
                                    <p>This Cookie Policy explains what cookies are, what types of cookies are placed on your device when you visit our website and how we use them.</p>
                                    <p>This Cookie Policy does not address how we deal with your personal information generally. To learn more about how we process your personal information, please see our Privacy Policy here.</p>

                                    <h4>What are cookies?</h4>

                                    <p>Cookies are small text files that are sent to or accessed from your web browser or your device's memory. A cookie typically contains the name of the domain (internet location) from which the cookie originated, the "lifetime" of the cookie (i.e., when it expires) and a randomly generated unique number or similar identifier. A cookie also may contain information about your device, such as user settings, browsing history and activities conducted while using our services.</p>

                                    <h4>Are there different types of cookies?</h4>

                                    <p><b>First-party and third-party cookies</b></p>
                                    <p>There are first-party cookies and third-party cookies. First-party cookies are placed on your device directly by us. For example, we use first-party cookies to adapt our website to your browser's language preferences and to better understand your use of our website. Third-party cookies are placed on your device by our partners and service providers. For example, we use third-party cookies to measure user numbers on our website.</p>
                                    <p><b>Session and persistent cookies</b></p>
                                    <p>There are session cookies and persistent cookies. Session cookies only last until you close your browser. We use session cookies for a variety of reasons, including to learn more about your use of our website during one single browser session and to help you to use our website more efficiently. Persistent cookies have a longer lifespan and aren't automatically deleted when you close your browser. These types of cookies are primarily used to help you quickly sign-in to our website again and for analytical purposes.</p>

                                    <h4>What about other tracking technologies, like web beacons?</h4>

                                    <p>Other technologies such as web beacons (also calls pixel tags or clear gifs), tracking URLs or software development kits (SDKs) are used for similar purposes. Web beacons are tiny graphics files that contain a unique identifier that enable us to recognise when someone has visited our service. Tracking URLs are custom generated links that help us understand where the traffic to our webpages comes from. SDKs are small pieces of code included in apps, which function like cookies and web beacons.</p>
                                    <p>For simplicity, we also refer to these technologies as "cookies" in this Cookie Policy.</p>

                                    <h4>What do we use cookies for?</h4>

                                    <p>Like most providers of online services, we use cookies to provide, secure and improve our services, including by remembering your preferences, recognizing you when you visit our website and personalizing and tailoring ads to your interests. To accomplish these purposes, we also may link information from cookies with other personal information we hold about you. When you visit our website, some or all of the following types of cookies may be set on your device.</p>

                                    <h3>Cookie type description</h3>

                                    <h4>Essential website cookies</h4>
                                    <p>These cookies are strictly necessary to provide you with services available through our website and to use some of its features, such as access to secure areas.</p>

                                    <h4>Analytics cookies</h4>
                                    <p>These cookies help us understand how our website is being used, how effective marketing campaigns are, and help us customize and improve our websites for you.</p>

                                    <h4>Advertising cookies</h4>
                                    <p>These cookies are used to make advertising messages more relevant to you. They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed for advertisers, selecting advertisements that are based on your interests and measuring the number of ads displayed and their performance, such as how many people clicked on a given ad.</p>

                                    <h4>How can you control cookies?</h4>
                                    <p>There are several cookie management options available to you. Please note that changes you make to your cookie preferences may make browsing our website a less satisfying experience. In some cases, you may even find yourself unable to use all or part of our site.</p>

                                    <h4>Browser and devices controls</h4>
                                    <p>Some web browsers provide settings that allow you to control or reject cookies or to alert you when a cookie is placed on your computer. The procedure for managing cookies is slightly different for each internet browser. You can check the specific steps in your particular browser help menu.</p>

                                    <h3>Adobe Flash Player™ Flash cookies</h3>
                                    <p>Adobe Flash Player™ is an application for viewing and interacting with dynamic content using the Flash platform. Flash (and similar applications) use a technology akin to cookies to memorize parameters, preferences and uses of this content. However, Adobe Flash Player manages this information and your choices via an interface separate from that supplied by your browser.</p>
                                    <p>If your terminal is likely to display content developed using the Flash platform, we suggest you access your Flash cookie management tools directly via https://www.adobe.com.</p>

                                    <h3>Google™ Cookies</h3>
                                    <p>Here follows what  Googlewants you to know about Google's data collection technology.</p>

                                    <h4>Google Analytics</h4>
                                    <p>We use Google Analytics, which is a Google service that uses cookies and other data collection technologies to collect information about your use of the website and services in order to report website trends.</p>
                                    <p>You can opt out of Google Analytics by visiting www.google.com/settings/ads or by downloading the Google Analytics opt-out browser add-on at https://tools.google.com/dlpage/gaoptout.</p>

                                    <h3>How to contact us?</h3>
                                    <p>If you have questions about this Cookie Policy, here's how you can reach us:</p>
                                    <p><b>Online: via our contact form.</b></p>
                                    <p><b>By post:</b></p>
                                    <p><b>Privacy Officer</b></p>
                                    <p><b>NowPurple Ltd</b></p>
                                    <p><b>Unit GF 10</b></p>
                                    <p><b>58 Nelson Street</b></p>
                                    <p><b>London, E1 2DE</b></p>






                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </>
        );
    }
}

export default CookiePolicy;
