import React from "react";
import {
    adsGetRandProducts,
    baseUrl,
    blogSingle,
    getBlogComments,
    postBlogAction,
    postBlogComment, postBlogDeleteComment, postBlogView
} from "../services/ApiService";
import "../pageCss/blog.css";
import moment from "moment";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {LoginContext} from "../contexts/LoginContext";
import {userInfo} from "../services/CommonService";

class BlogSingle extends React.Component {
    static contextType = LoginContext;

    state = {
        loading: true,
        slug: '',
        blog: {},
        user: userInfo
    }

    componentDidMount() {

        if (this.props.match.params.slug !== '') {
            const  bk = this.state;
            bk.user = JSON.parse(localStorage.getItem('user'));
            bk.slug = this.props.match.params.slug;
            this.setState(bk);
            this.getPost();
            this.restCall();
        }

    }

    restCall = async () => {
        await this.adsGetProducts();
    }

    adsGetProducts = async () => {
        const res = await adsGetRandProducts(10);
        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.adProducts = res.products;
                this.setState(bk);
            }
        }
    }

    getPost = async () => {
        const res = await blogSingle(this.state.slug);
        if (res) {
            const  bk = this.state;
            if (res.status) {
                document.title = 'Blogs | NowPurple | ' + res.blog.title;
                document.getElementsByTagName('meta')["keywords"].content = res.blog.keywords;
                document.getElementsByTagName('meta')["description"].content = res.blog.summary;
                bk.blog = res.blog;
                bk.comments = res.comments;
                await this.postView({blogId: res.blog.id, viewed: 1});
            }
            bk.loading = false;
            this.setState(bk);
        }
    }

    submitComment = async () => {
        const comment = document.getElementById('post-comment');
        if (comment.value === '') {
            toast.info('Comment can not be empty');
            return false;
        }

        const res = await postBlogComment({blogId: this.state.blog.id, comment: comment.value});
        if (res) {
            if (res.status) {
                toast.success(res.message);
                comment.value = '';
                this.commentPage = 1;
                await this.blogComments();
            } else {
                toast.error(res.error);
            }
        }
    }

    commentPage = 1;
    blogComments = async () => {
        const res = await getBlogComments(this.state.blog.id, this.commentPage);
        if (res) {
            if (res.status) {
                const bk = this.state;
                if (this.commentPage > 1) {
                    const comments = res.comments.data.concat(bk.comments.data);
                    bk.comments = res.comments;
                    bk.comments.data = comments;
                } else {
                    bk.comments = res.comments;
                }
                this.setState(bk);
            }
        }
    }

    likeOrLove = async (data) => {
        if (!this.context.isLoggedIn) {
            toast.warning('Login to interact');
            return false;
        }

        const res = await postBlogAction(data);
        if (res) {
            if (res.status) {

                const bk = this.state;
                bk.blog.likes = res.blog.likes;
                this.setState(bk);
            }
        }
    }

    postView = async (data) => {
        const res = await postBlogView(data);
        if (res) {
            if (res.status) {

                const bk = this.state;
                bk.blog.views = res.blog.views;
                this.setState(bk);
            }
        }
    }

    removeCmt = null;
    removeComment = async () => {
        const res = await postBlogDeleteComment(this.removeCmt);
        if(res) {
            if (res.status) {
                const bk = this.state;
                bk.comments.data = bk.comments.data.filter((comment) => {
                    return comment.id !== this.removeCmt.commentId;
                });
                this.setState(bk);
            }
        }
    }

    render() {
        const {isLoggedIn} = this.context;

        return (
            <>

                <div className="page_hero"></div>

                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-9 col-xl-9">
                            <div className={'single-post'}>

                                {
                                    this.state.loading &&

                                    <div className="spinner-border spinner-color" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }

                                {
                                    (!this.state.loading && (this.state.slug === '' || Object.keys(this.state.blog).length === 0)) &&
                                        <p className="text-center">Post not found</p>

                                }

                                {
                                    Object.keys(this.state.blog).length > 0 &&

                                    <>
                                        <div className={'post'}>

                                            <div className="post-title">
                                                <h1>{this.state.blog.title}</h1>
                                                <p className="small_font text-uppercase">{moment(this.state.blog.created_at).local(true).format('lll')}</p>
                                            </div>

                                            <div className="post-body" dangerouslySetInnerHTML={{__html: this.state.blog.post}}>

                                            </div>

                                            <div className="post-footer">
                                                <div>
                                                    <span onClick={ async () => {
                                                        await this.likeOrLove({blogId: this.state.blog.id, liked: 1});
                                                    }} >({this.state.blog.likes})<i className="fa fa-thumbs-up purple_color" aria-hidden="true" ></i></span>
                                                    <span>({this.state.blog.views})<i className="fa fa-eye purple_color" aria-hidden="true" ></i></span>
                                                </div>

                                                <div>
                                                    <p className="cur-pointer" >
                                                        comments <span>({this.state.blog.comments})</span></p>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="post-add-comment">

                                            {
                                                !isLoggedIn ?
                                                    <p className="text-center text-muted">Login to share your comment</p>
                                                    :
                                                    <>
                                                        <textarea id="post-comment" rows="3"></textarea>

                                                        <button className="btn btn_purple_mid_light" onClick={this.submitComment}>Post Comment</button>
                                                    </>
                                            }

                                        </div>

                                        <div className="post-comments">
                                            <h4>Comments:</h4>
                                            {
                                                !this.state.comments ?
                                                    <p className="text-center">Currently have zero comments</p>
                                                    :
                                                    <>
                                                        {
                                                            this.state.comments.next_page_url &&
                                                            <p className='load-comments' onClick={ async () => {
                                                                this.commentPage = ++this.state.comments.current_page;
                                                                await this.blogComments();
                                                            }}>Previous Comments</p>
                                                        }
                                                        {
                                                            this.state.comments.data.map((comment, i) => {
                                                                return (
                                                                    <div className="post-comment" key={i}>

                                                                        <div className="profile_img">

                                                                            {
                                                                                comment.user.img &&

                                                                                <img src={`${baseUrl}/avatar/${comment.user.img}`} alt=""/>

                                                                            }

                                                                            {
                                                                                !comment.user.img && comment.user.gender.toLowerCase() === 'male' &&
                                                                                <img src={`${baseUrl}/avatar/avatar - 09-01.png`} alt=""/>
                                                                            }

                                                                            {
                                                                                !comment.user.img && comment.user.gender.toLowerCase() === 'female' &&
                                                                                <img src={`${baseUrl}/avatar/avatar - 14-01.png`} alt=""/>
                                                                            }

                                                                            {
                                                                                !comment.user.img && comment.user.gender.toLowerCase() !== 'male' && comment.user.gender.toLowerCase() !== 'female' &&
                                                                                <img src={`${baseUrl}/avatar/avatar-2877.png`} alt=""/>
                                                                            }

                                                                        </div>

                                                                        <div className={'comment-body'}>
                                                                            {
                                                                                isLoggedIn && this.state.user &&
                                                                                <>
                                                                                    {
                                                                                        parseInt(comment.user_id) === this.state.user.id &&
                                                                                        <i className="fa fa-trash-o remove-comment"
                                                                                           title={'remove comment'}
                                                                                           aria-hidden="true"
                                                                                           data-toggle={'modal'}
                                                                                           data-target={'#remove_comment'}
                                                                                           onClick={() => {
                                                                                               this.removeCmt = {
                                                                                                   blogId: this.state.blog.id,
                                                                                                   commentId: comment.id
                                                                                               };
                                                                                           }}></i>
                                                                                    }
                                                                                </>
                                                                            }

                                                                            <p className='comment-author'>{comment.user.name}</p>
                                                                            <p className='comment-date'>{moment(comment.created_at).local(true).format('lll')}</p>
                                                                            <p>{comment.comment}</p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </>
                                            }
                                        </div>

                                    </>
                                }


                            </div>
                        </div>


                        <div className="col-12 col-md-12 col-lg-3 col-xl-3">
                            <div className="profile_listing_right blog-post">

                                {
                                    <>
                                        {
                                            this.state.adProducts &&

                                            this.state.adProducts.map((product, index) => {
                                                return (
                                                    <div className="single_product sidebar_product" key={index}>
                                                        <a target="_balnk" href={product.outsite_url}>
                                                            <img src={`${baseUrl}/assets/uploads/adsproduct/${product.img}`} alt="Product image" />
                                                        </a>
                                                    </div>
                                                );
                                            })

                                        }
                                    </>
                                }

                            </div>
                        </div>

                    </div>


                    <div className="modal fade remove_comment_modal" id="remove_comment" tabIndex="-1" aria-labelledby="exampleModalLabel"
                         aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </div>
                                <div className="modal-body">
                                    <div className="modal_waper_main_profile bg-white">

                                        <h4>Are you sure want to remove the comment?</h4>

                                        <div>
                                            <button className="btn btn-danger btn-sm" data-dismiss="modal" aria-label="Close" onClick={this.removeComment}>Remove</button>
                                            <button className="btn btn-info btn-sm" data-dismiss="modal" aria-label="Close">Cancel</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </>
        );
    }

}

export default BlogSingle;
