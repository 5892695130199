import React from "react";

class PageNotFound extends React.Component {

    state = {
        isPrivatePage: false,
    };

    componentDidMount() {
        const bk = this.state;
        const loc = this.props.location.pathname;

        if (loc === '/find-friends') {
            bk.isPrivatePage = true;
        }

        this.setState(bk);
    }

    render() {
        return (

            <>

                <div className="page_hero"></div>

                <section className={'page-not-found'}>
                    <div className='container'>

                        <div className="row">
                            {
                                this.state.isPrivatePage ?
                                    <h3>Please login to view this page</h3>
                                    :
                                    <h3>Page not found</h3>
                            }
                        </div>
                    </div>
                </section>

            </>

        );
    }

}

export default PageNotFound;
