import React from "react";
import "../pageCss/story.css";
import {
    adsGetRandProducts,
    baseUrl,
    createStory, deleteComment, deleteStory, editStory,
    getComments, getFriends,
    getStories, getStoriesUnReg,
    postComment, shareStory,
    storyAction, storyNotifications
} from "../services/ApiService";
import {LoginContext} from "../contexts/LoginContext";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";
import moment from "moment";
import {userInfo} from "../services/CommonService";

class Story extends React.Component {
    static contextType = LoginContext;

    state = {
        _isMounted: false,
        user: userInfo,
        friendList: [],
        stories: {data: [], next: null, prev: null},
        topStories: {data: [], next: null, prev: null},
        timeline: {data: [], next: null, prev: null},
        notifications: [],
        fullStory: {},
        loading: true,
    }

    socket = null;
    componentDidMount() {
        document.title = 'Stories | NowPurple | Dating Blogs UK';
        document.getElementsByTagName('meta')["description"].content = 'Stories page of NowPurple. It\'s a UK based 100% free dating website where the blog function allows users to share their experiences with others.';
        document.getElementsByTagName('meta')["keywords"].content = 'dating blog uk, totally free dating sites uk';

        const bk = this.state;
        bk.user = JSON.parse(localStorage.getItem('user'));
        bk._isMounted = true;
        this.setState(bk);
        this.restCall();
        this.setScrollEvent();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        }
    }

    restCall = async () => {
        if (this.state._isMounted) {
            setTimeout(async () => {
                if (this.context.isLoggedIn) {
                    this.socket = this.context.socket;
                    await this.stories({timeline: true});
                    await this.getNotifications();
                }
            }, 300);
            await this.stories({topStories: true});
            await this.stories();
            await this.adsGetProducts();
            await this.getFriends();
        }
    }

    getFriends = async () => {
        const res = await getFriends();
        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.friendList = res.list;
                this.setState(bk);
            }
        }
    }

    scrollTopStory = 0;
    scrollTopTimeline = 0;
    storyPage = 1;
    timelinePage = 1;
    selectedPage = 1;
    setScrollEvent = async () => {
        window.addEventListener('scroll', async (e) => {
            if (this.selectedPage === 1) {
                if (document.documentElement.scrollTop > this.scrollTopStory && !this.state.loading) {
                    if (document.documentElement.scrollTop - this.scrollTopStory >= 400) {
                        this.scrollTopStory = document.documentElement.scrollTop;
                        if (this.state.stories.next) {
                            await this.stories({
                                page: ++this.storyPage
                            });
                        }
                    }
                }
            } else if (this.selectedPage === 2) {
                if (document.documentElement.scrollTop > this.scrollTopTimeline && !this.state.loading) {
                    if (document.documentElement.scrollTop - this.scrollTopTimeline >= 400) {
                        this.scrollTopTimeline = document.documentElement.scrollTop;
                        if (this.state.timeline.next) {
                            await this.stories({
                                timeline: true,
                                page: ++this.timelinePage
                            });
                        }
                    }
                }
            }
        });
    }

    adsGetProducts = async () => {
        const res = await adsGetRandProducts(10);
        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.adProducts = res.products;
                this.setState(bk);
            }
        }
    }

    recordedData = [];
    recorder = null;
    audioStream = null;

    startRecoding = async () => {
        if (navigator.mediaDevices) {
            console.log('getUserMedia supported.');
            if (this.recorder) {
                return false;
            }
            document.getElementById('rec-start').classList.add('pressed');
            document.querySelectorAll('.equalizer div').forEach(div => {
                div.classList.add('up-down');
            });
            const constraints = {audio: true};
            try {
                this.audioStream = await navigator.mediaDevices.getUserMedia(constraints);
                this.recorder = new MediaRecorder(this.audioStream);
                this.recorder.start();
                this.recorder.ondataavailable = (e) => {
                    this.recordedData.push(e.data);
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    pauseRecording = async () => {
        if (this.recorder) {
            if (this.recorder.state === "recording") {
                document.getElementById('rec-start').classList.remove('pressed');
                document.getElementById('rec-pause').classList.add('pressed');
                document.getElementById('rec-pause').title = 'Resume';
                this.recorder.pause();
                document.querySelectorAll('.equalizer div').forEach(div => {
                    div.classList.remove('up-down');
                });
            } else if (this.recorder.state === "paused") {
                document.getElementById('rec-start').classList.add('pressed');
                document.getElementById('rec-pause').classList.remove('pressed');
                document.getElementById('rec-pause').title = 'Pause';
                this.recorder.resume();
                document.querySelectorAll('.equalizer div').forEach(div => {
                    div.classList.add('up-down');
                });
            }
        }
    }

    audioBlob = null;
    stopRecording = async () => {
        try {
            if (this.recorder) {

                document.getElementById('rec-start').classList.remove('pressed');
                document.getElementById('rec-pause').classList.remove('pressed');
                document.querySelectorAll('.equalizer div').forEach(div => {
                    div.classList.remove('up-down');
                });

                if (this.audioStream) {
                    this.audioStream.getTracks().forEach(track => { track.stop(); });
                }
                this.recorder.stop();
                this.recorder.onstop = async (e) => {
                    this.audioBlob = new Blob(this.recordedData, {type: 'audio/mpeg'});
                    this.recordedData = [];
                    const audioUrl = window.URL.createObjectURL(this.audioBlob);
                    const player = document.getElementById('play-rec-audio');
                    player.src = audioUrl;
                    this.recorder = null;
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    storyTitle = '';
    selectedStoryStatus = '';
    postAudio = async () => {
        try {
            if (this.storyTitle === '') {
                toast.warn('Title needed for audio story');
                return false;
            }
            if (this.audioBlob) {
                const audioFile = new File([this.audioBlob], new Date().toISOString() +'.mp3', {type: "audio/mpeg", lastModified: Date.now()});

                const res = await createStory({audioStory: audioFile, title: this.storyTitle, status: this.selectedStoryStatus});;
                if (res) {
                    if (res.status) {
                        this.audioBlob = null;
                        this.timelinePage = 0;
                        await this.stories({
                            timeline: true,
                            page: ++this.timelinePage
                        });
                        this.storyTitle = '';
                        document.querySelector('#modal-rec-audio .modal_close').click();

                    } else {
                        toast.error(res.error);
                    }
                }
            } else {
                toast.info('Record audio first');
            }
        } catch (e) {
            console.log(e);
        }
    }

    stories = async (data = {}) => {
        let res = null;
        if (this.context.isLoggedIn) {
            res = await getStories(data);
        } else {
            res = await getStoriesUnReg(data);
        }
        if (res) {
            const bk = this.state;
            if (res.status) {
                if (data.topStories) {
                    bk.topStories.data = res.stories;
                    bk.topStories.next = res.nextPage;
                    bk.topStories.prev = res.prevPage;
                } else if (data.timeline) {
                    if (this.storyFlag) {
                        bk.timeline.data = res.stories;
                        this.storyFlag = false;
                    } else {
                        bk.timeline.data = bk.timeline.data.concat(res.stories);
                    }
                    bk.timeline.next = res.nextPage;
                    bk.timeline.prev = res.prevPage;
                } else {
                    bk.stories.data = bk.stories.data.concat(res.stories);
                    bk.stories.next = res.nextPage;
                    bk.stories.prev = res.prevPage;
                }
            }
            this.setState(bk);
            setTimeout(() => {
                bk.loading = false;
                this.setState(bk);
            }, 400);
        }
    }

    storyText = '';

    setStoryText = (e) => {
        e.persist();
        this.storyText = e.target.value;
    }

    storyFlag = false;
    storyCreate = async () => {
        if (this.storyText === '') {
            toast.warning('Please write a story first');
            return false;
        }
        const res = await createStory({story: this.storyText, status: this.selectedStoryStatus});
        if (res) {
            if (res.status) {
                toast.success(res.message);

                if (this.selectedStoryStatus !== 'private') {
                    if (this.socket) {
                        this.socket.emit('notify', {user_id: null, message: `A new story has been published`});
                    }
                }

                this.storyFlag = true;
                this.timelinePage = 0;
                await this.stories({
                    timeline: true,
                    page: ++this.timelinePage
                });
                document.getElementById('story-text').value = '';
                this.storyText = '';
            } else {
                toast.error(res.error);
            }
        }
    }


    viewStory = async (story) => {
        const bk = this.state;
        bk.fullStory = story;
        this.setState(bk);
        if (this.socket) {
            this.socket.emit('notify', {user_id: story.userId, message: `${this.state.user.name} viewed your story`});
        }
        setTimeout(() => {
            document.getElementById('comment-input').value = '';
        }, 50);
        await this.likeOrLove({storyId: story.storyId, view: true}, story);
    }

    page = 1;
    getStoryComments = async () => {
        const res = await getComments(this.state.fullStory.storyId, ++this.page);
        if (res) {
            if (res.status) {
                const bk = this.state;
                if (this.page === 1) {
                    bk.fullStory.comments = res.comments;
                } else {
                    bk.fullStory.comments.data = res.comments.data.concat(bk.fullStory.comments.data);
                    bk.fullStory.comments.next = res.comments.next;
                    bk.fullStory.comments.prev = res.comments.prev;
                    bk.fullStory.comments.total = res.comments.total;
                }
                this.setState(bk);
            }
        }
    }

    commentText = '';
    sendComment = async () => {
        if (!this.context.isLoggedIn) {
            toast.warning('Login to comment');
            return false;
        }
        if (this.commentText === '') {
            return false;
        }
        const res = await postComment({storyId: this.state.fullStory.storyId, comment: this.commentText});
        if (res) {
            if (res.status) {
                toast.success(res.message);
                this.page = 0;
                await this.getStoryComments();
                document.getElementById('comment-input').value = '';
                if (this.socket) {
                    this.socket.emit('notify', {user_id: this.state.fullStory.userId, message: `${this.state.user.name} commented on your story`});
                }
            } else {
                toast.error(res.error);
            }
        }
    }

    selectedEditComment = null;
    editComment = async () => {
        const storyComment = document.getElementById('edit-comment').value;
        if (!this.selectedEditComment || storyComment === '') {
            toast.error('Story can not be empty');
            return false;
        }

        const res = await postComment({
            storyId: this.state.fullStory.storyId,
            commentId: this.selectedEditComment.commentId,
            comment: storyComment
        });

        if (res) {
            if (res.status) {
                toast.success(res.message);
                this.state.fullStory.comments.data.forEach((c, i) => {
                    if (c.commentId === this.selectedEditComment.commentId) {
                        const bk = this.state;
                        bk.fullStory.comments.data[i].comment = storyComment;
                        this.setState(bk);
                    }
                });
            } else {
                toast.error(res.error);
            }
        }

    }

    removeCmt = {};
    removeComment = async () => {
        const res = await deleteComment(this.removeCmt);
        if (res) {
            if (res.status) {
                toast.success(res.message);
                const filteredData = this.state.fullStory.comments.data.filter((c) => {
                    return c.commentId !== this.removeCmt.commentId;
                });

                const bk = this.state;
                this.state.fullStory.comments.data = filteredData;
                this.setState(bk);
            } else {
                toast.error(res.error);
            }
        }
    }

    getNotifications = async () => {
        const res = await storyNotifications();
        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.notifications = res.result;
            }
        }
    }

    likeOrLove = async (data, story) => {
        if (!this.context.isLoggedIn && !data.view) {
            toast.warning('Login to interact');
            return false;
        }

        const res = await storyAction(data);
        if (res) {
            if (res.status) {

                if (this.socket) {
                    let msg = '';
                    if (data.like) {
                        msg = 'liked your story';
                    } else if (data.love) {
                        msg = 'loved your story';
                    }
                    if (story.likes != res.story.likes || story.loved != res.story.loved) {
                        this.socket.emit('notify', {user_id: story.userId, message: `${this.state.user.name} ${msg}`});
                    }
                }

                const bk = this.state;
                if(bk.fullStory.storyId) {
                    if (bk.fullStory.storyId === data.storyId) {
                        bk.fullStory.likes = res.story.likes;
                        bk.fullStory.loved = res.story.loved;
                    }
                }

                for(let dt in bk.topStories.data) {
                    if (bk.topStories.data[dt].storyId === data.storyId) {
                        bk.topStories.data[dt].likes = res.story.likes;
                        bk.topStories.data[dt].loved  = res.story.loved;
                    }
                }

                for(let dt in bk.stories.data) {
                    if (bk.stories.data[dt].storyId === data.storyId) {
                        bk.stories.data[dt].likes = res.story.likes;
                        bk.stories.data[dt].loved  = res.story.loved;
                    }
                }

                for(let dt in bk.timeline.data) {
                    if (bk.timeline.data[dt].storyId === data.storyId) {
                        bk.timeline.data[dt].likes = res.story.likes;
                        bk.timeline.data[dt].loved  = res.story.loved;
                    }
                }

                this.setState(bk);
            }
        }
    }

    removeStoryId = null;
    removeStory = async () => {
        if (!this.removeStoryId) {
            toast.warning('Select story first');
        }
        const res = await deleteStory({storyId: this.removeStoryId});
        if (res) {
            if (res.status) {
                toast.success(res.message);
                const bk = this.state;
                bk.timeline.data = bk.timeline.data.filter(story => {
                   return story.storyId !== this.removeStoryId;
                });
                this.setState(bk);
            } else {
                toast.error(res.error);
            }
        }
    }

    selectedEditStory = null;
    editStory = async () => {
        const storyText = document.getElementById('edit-story').value;
        if (!this.selectedEditStory || storyText === '') {
            toast.error('Story can not be empty');
            return false;
        }

        const res = await editStory({storyId: this.selectedEditStory.storyId, story: storyText});
        if (res) {
            if (res.status) {
                toast.success(res.message);
                const bk = this.state;
                for( let key in bk.timeline.data) {
                    if (bk.timeline.data[key].storyId === this.selectedEditStory.storyId) {
                        bk.timeline.data[key].textStory = storyText;
                    }
                }
                this.setState(bk);
            } else {
                toast.error(res.error);
            }
        }

    }

    selectedFiends = [];
    selectForShare = (e) => {
        e.persist();
        const val = e.target.value;

        if (val === 'all') {
            this.selectedFiends = [];
            if (e.target.checked) {
                this.selectedFiends.push(val);
                (document.querySelectorAll('.friend-lst')).forEach((list) => {
                    list.checked = false;
                });
            }
        } else {
            if (e.target.checked) {
                if (this.selectedFiends.find((val) => val === 'all')) {
                    this.selectedFiends = [];
                }
                this.selectedFiends.push(val);
            } else {

                if (this.selectedFiends.length) {
                    this.selectedFiends = this.selectedFiends.filter((f) => {
                        return f !== val;
                    });
                }
            }
        }
    }

    shareData = {}
    storyShare = async () => {
        const res = await shareStory({storyId: this.shareData.storyId, shareWith: this.selectedFiends.join(',')});
        if (res) {
            if (res.status) {
                toast.success(res.message);
            } else {
                toast.error(res.error);
            }
        }
    }

    render() {

        const {isLoggedIn} = this.context;

        return(
            <>

                <div className="page_hero"></div>

                <section id="story-container">
                    <div className="container">
                        <div className="row">

                            <div className="col-12 col-md-12 col-lg-9 col-xl-9">
                                {
                                    this.context.isLoggedIn &&
                                    <div className={'story-create'}>

                                        <div className={'story-create-header'}>
                                            <h4>Create Story</h4>
                                            <div className="d-flex flex-row">
                                                <select className={'story-status'} onChange={(e) => {
                                                    e.persist();
                                                    this.selectedStoryStatus = e.target.value;
                                                }} >
                                                    <option value="public">Public</option>
                                                    <option value="private">Private</option>
                                                </select>

                                                <button className="btn btn-sm btn_purple_mid_light"
                                                        data-toggle={'modal'} data-target={'#modal-rec-audio'}>
                                                    <i className="fa fa-microphone" aria-hidden="true"></i>
                                                    Share Audio Story</button>
                                            </div>
                                        </div>

                                        <textarea name="story_text" id="story-text" rows="4" wrap="hard"
                                                  placeholder={'Write your story....'} onChange={this.setStoryText}></textarea>

                                        <button className="btn btn_purple_mid_light" id={'post-story'} onClick={this.storyCreate}>Share Story</button>

                                    </div>

                                }

                                <div className={'story-board'}>

                                    <div className={'story-tabs'}>
                                        <ul className="nav nav-tabs" id="pills-tab"
                                            role="presentation">
                                            <li className="nav-item">
                                                <a className="nav-link active" id="pills-story-tab"
                                                   data-toggle="pill" href="#pills-story"
                                                   role="tab"
                                                   aria-controls="pills-story"
                                                   aria-selected="false" onClick={ () => {
                                                        this.selectedPage = 1;
                                                        document.documentElement.scrollTop = 150;
                                                    }}>Stories</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="pills-top-story-tab"
                                                   data-toggle="pill" href="#pills-top-story" role="tab"
                                                   aria-controls="pills-top-story"
                                                   aria-selected="true" onClick={() => {
                                                        this.selectedPage = 3;
                                                        document.documentElement.scrollTop = 150;
                                                    }}>Top Stories</a>
                                            </li>
                                            {
                                                this.context.isLoggedIn &&
                                                <>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="pills-timeline-tab"
                                                           data-toggle="pill" href="#pills-timeline" role="tab"
                                                           aria-controls="pills-timeline"
                                                           aria-selected="true" onClick={() => {
                                                            this.selectedPage = 2;
                                                            document.documentElement.scrollTop = 150;
                                                        }}>Timeline</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="pills-notification-tab"
                                                           data-toggle="pill" href="#pills-notification" role="tab"
                                                           aria-controls="pills-notification"
                                                           aria-selected="true" onClick={() => {
                                                            document.documentElement.scrollTop = 150;
                                                        }}>Notifications</a>
                                                    </li>
                                                </>
                                            }
                                        </ul>
                                    </div>

                                    <div className="tab-content" id="pills-tabContent">

                                        <div className="row tab-pane fade" role="tabpanel" aria-labelledby="pills-top-story-tab" id={'pills-top-story'}>

                                            {
                                                this.state.topStories.data.map((story, i) => {
                                                    let subStory = '';
                                                    if (story.textStory) {
                                                        subStory = story.textStory;
                                                        const storyArr = story.textStory.split(' ');
                                                        if (storyArr.length > 80) {
                                                            subStory = (storyArr.slice(0, 80)).join(' ');
                                                            subStory += `..... <strong>Read More</strong>`;
                                                        }
                                                    }
                                                    return(
                                                        <div className={'story'} key={i} >

                                                            <div className={'story-author'}>
                                                                <div className="profile_img">

                                                                    {
                                                                        story.userImg &&

                                                                        <img src={`${baseUrl}/avatar/${story.userImg}`} alt=""/>

                                                                    }

                                                                    {
                                                                        !story.userImg && story.gender.toLowerCase() === 'male' &&
                                                                        <img src={`${baseUrl}/avatar/avatar - 09-01.png`} alt=""/>
                                                                    }

                                                                    {
                                                                        !story.userImg && story.gender.toLowerCase() === 'female' &&
                                                                        <img src={`${baseUrl}/avatar/avatar - 14-01.png`} alt=""/>
                                                                    }

                                                                    {
                                                                        !story.userImg && story.gender.toLowerCase() !== 'male' && story.gender.toLowerCase() !== 'female' &&
                                                                        <img src={`${baseUrl}/avatar/avatar-2877.png`} alt=""/>
                                                                    }

                                                                </div>

                                                                <div className={'profile_info'}>
                                                                    <Link to={`/profile/${story.userName}`} className="purple_color font-weight-bold text-uppercase">{story.userName}</Link>
                                                                    <p className="small_font text-uppercase">{moment(story.createdAt).local(true).format('lll')}</p>
                                                                </div>

                                                            </div>

                                                            <div className={'shared-story'} onClick={() => {this.viewStory(story);}}
                                                                 data-toggle={'modal'} data-target={'#modal-view-story'}>

                                                                {
                                                                    story.audioStory &&
                                                                    <>
                                                                        {
                                                                            story.title &&
                                                                            <p dangerouslySetInnerHTML={{__html: story.title}}></p>
                                                                        }
                                                                        <audio src={`${baseUrl}/story/${story.audioStory}`} preload="true" controls={true}></audio>
                                                                    </>
                                                                }

                                                                {
                                                                    story.textStory &&
                                                                    <p dangerouslySetInnerHTML={{__html: subStory}}></p>
                                                                }

                                                            </div>

                                                            <div className={'story-footer'}>
                                                                <div>
                                                                    <span>({story.likes})<i className="fa fa-thumbs-up" aria-hidden="true" onClick={ async () => {
                                                                        await this.likeOrLove({storyId: story.storyId, like: true}, story);
                                                                    }}></i></span>
                                                                    <span>({story.loved})<i className="fa fa-heart" aria-hidden="true" onClick={ async () => {
                                                                        await this.likeOrLove({storyId: story.storyId, love: true}, story);
                                                                    }}></i></span>
                                                                </div>

                                                                <div>
                                                                    <p className="cur-pointer" onClick={() => {this.viewStory(story);}}
                                                                       data-toggle={'modal'} data-target={'#modal-view-story'}>
                                                                        <i className="fa fa-caret-down mr-1" aria-hidden="true"> </i>
                                                                        comments <span>({story.comments.total})</span></p>

                                                                    <a href="#" className="purple_color" data-toggle={'modal'} data-target={'#friend-list'}
                                                                       onClick={(e) => {
                                                                           e.preventDefault();
                                                                           this.shareData.storyId = story.storyId;
                                                                       }}><i className="fa fa-caret-down mr-1" aria-hidden="true"> </i> Share</a>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>

                                        <div className="row tab-pane fade show active" role="tabpanel" aria-labelledby="pills-story-tab" id={'pills-story'}>

                                            {
                                                this.state.loading &&

                                                <div className="spinner-border spinner-color" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            }

                                            {
                                                this.state.stories.data.length === 0 && !this.state.loading &&

                                                <h4 className={'empty-stories'}>No story found</h4>
                                            }

                                            {
                                                this.state.stories.data.map((story, i) => {
                                                    let subStory = '';
                                                    if (story.textStory) {
                                                        subStory = story.textStory;
                                                        const storyArr = story.textStory.split(' ');
                                                        if (storyArr.length > 80) {
                                                            subStory = (storyArr.slice(0, 80)).join(' ');
                                                            subStory += `..... <strong>Read More</strong>`;
                                                        }
                                                    }
                                                   return(
                                                       <div className={'story'} key={i} >

                                                           <div className={'story-author'}>
                                                               <div className="profile_img">

                                                                   {
                                                                       story.userImg &&

                                                                       <img src={`${baseUrl}/avatar/${story.userImg}`} alt=""/>

                                                                   }

                                                                   {
                                                                       !story.userImg && story.gender.toLowerCase() === 'male' &&
                                                                       <img src={`${baseUrl}/avatar/avatar - 09-01.png`} alt=""/>
                                                                   }

                                                                   {
                                                                       !story.userImg && story.gender.toLowerCase() === 'female' &&
                                                                       <img src={`${baseUrl}/avatar/avatar - 14-01.png`} alt=""/>
                                                                   }

                                                                   {
                                                                       !story.userImg && story.gender.toLowerCase() !== 'male' && story.gender.toLowerCase() !== 'female' &&
                                                                       <img src={`${baseUrl}/avatar/avatar-2877.png`} alt=""/>
                                                                   }

                                                               </div>

                                                               <div className={'profile_info'}>
                                                                   <Link to={`/profile/${story.userName}`} className="purple_color font-weight-bold text-uppercase">{story.userName}</Link>
                                                                   <p className="small_font text-uppercase">{moment(story.createdAt).local(true).format('lll')}</p>
                                                               </div>

                                                           </div>

                                                           <div className={'shared-story'} onClick={() => {this.viewStory(story);}}
                                                                data-toggle={'modal'} data-target={'#modal-view-story'}>

                                                               {
                                                                   story.audioStory &&
                                                                   <>
                                                                       {
                                                                           story.title &&
                                                                           <p dangerouslySetInnerHTML={{__html: story.title}}></p>
                                                                       }
                                                                       <audio src={`${baseUrl}/story/${story.audioStory}`} preload="true" controls={true}></audio>
                                                                   </>
                                                               }

                                                               {
                                                                   story.textStory &&
                                                                   <p dangerouslySetInnerHTML={{__html: subStory}}></p>
                                                               }

                                                           </div>

                                                           <div className={'story-footer'}>
                                                               <div>
                                                                   <span>({story.likes})<i className="fa fa-thumbs-up" aria-hidden="true" onClick={ async () => {
                                                                       await this.likeOrLove({storyId: story.storyId, like: true}, story);
                                                                   }}></i></span>
                                                                   <span>({story.loved})<i className="fa fa-heart" aria-hidden="true" onClick={ async () => {
                                                                       await this.likeOrLove({storyId: story.storyId, love: true}, story);
                                                                   }}></i></span>
                                                               </div>

                                                               <div>
                                                                   <p className="cur-pointer" onClick={() => {this.viewStory(story);}}
                                                                      data-toggle={'modal'} data-target={'#modal-view-story'}>
                                                                       <i className="fa fa-caret-down mr-1" aria-hidden="true"> </i>
                                                                       comments <span>({story.comments.total})</span></p>

                                                                   <a href="#" className="purple_color" data-toggle={'modal'} data-target={'#friend-list'}
                                                                      onClick={(e) => {
                                                                          e.preventDefault();
                                                                          this.shareData.storyId = story.storyId;
                                                                      }}><i className="fa fa-caret-down mr-1" aria-hidden="true"> </i> Share</a>
                                                               </div>
                                                           </div>

                                                       </div>
                                                   );
                                                })
                                            }

                                        </div>

                                        {
                                            this.context.isLoggedIn &&
                                            <>
                                                <div className="row tab-pane fade" role="tabpanel"
                                                     aria-labelledby="pills-timeline-tab" id={'pills-timeline'}>

                                                {
                                                    this.state.timeline.data.length === 0 &&

                                                    <h4 className={'empty-stories'}>Create and share your story</h4>
                                                }

                                                {
                                                    this.state.timeline.data.map((story, i) => {
                                                        let subStory = '';
                                                        if (story.textStory) {
                                                            subStory = story.textStory;
                                                            const storyArr = story.textStory.split(' ');
                                                            if (storyArr.length > 80) {
                                                                subStory = (storyArr.slice(0, 80)).join(' ');
                                                                subStory += `..... <strong>Read More</strong>`;
                                                            }
                                                        }
                                                        return (
                                                            <div className={'story'} key={i}>

                                                                <div className={'story-author'}>
                                                                    <div className="profile_img">

                                                                        {
                                                                            story.userImg &&

                                                                            <img
                                                                                src={`${baseUrl}/avatar/${story.userImg}`}
                                                                                alt=""/>

                                                                        }

                                                                        {
                                                                            !story.userImg && story.gender.toLowerCase() === 'male' &&
                                                                            <img
                                                                                src={`${baseUrl}/avatar/avatar - 09-01.png`}
                                                                                alt=""/>
                                                                        }

                                                                        {
                                                                            !story.userImg && story.gender.toLowerCase() === 'female' &&
                                                                            <img
                                                                                src={`${baseUrl}/avatar/avatar - 14-01.png`}
                                                                                alt=""/>
                                                                        }

                                                                        {
                                                                            !story.userImg && story.gender.toLowerCase() !== 'male' && story.gender.toLowerCase() !== 'female' &&
                                                                            <img
                                                                                src={`${baseUrl}/avatar/avatar-2877.png`}
                                                                                alt=""/>
                                                                        }

                                                                    </div>

                                                                    <div className={'profile_info'}>
                                                                        <Link to={`/profile/${story.userName}`}
                                                                              className="purple_color font-weight-bold text-uppercase">{story.userName}</Link>
                                                                        <p className="small_font text-uppercase">{moment(story.createdAt).local(true).format('lll')}</p>
                                                                    </div>

                                                                    {
                                                                        parseInt(story.userId) === this.state.user.id &&
                                                                        <div className="story-action">
                                                                            {
                                                                                !story.audioStory &&
                                                                                <i className="fa fa-pencil-square-o"
                                                                                   title={'edit story'} aria-hidden="true"
                                                                                   data-toggle={'modal'}
                                                                                   data-target={'#edit_story'}
                                                                                   onClick={() => {
                                                                                       document.getElementById('edit-story').value = story.textStory;
                                                                                       this.selectedEditStory = story;
                                                                                   }}></i>
                                                                            }
                                                                            <i className="fa fa-trash-o"
                                                                               title={'remove story'} aria-hidden="true"
                                                                               data-toggle={'modal'}
                                                                               data-target={'#remove_story'}
                                                                               onClick={() => {
                                                                                   this.removeStoryId = story.storyId;
                                                                               }}></i>
                                                                        </div>
                                                                    }


                                                                </div>

                                                                <div className={'shared-story'} onClick={() => {
                                                                    this.viewStory(story);
                                                                }}
                                                                     data-toggle={'modal'}
                                                                     data-target={'#modal-view-story'}>

                                                                    {
                                                                        story.audioStory &&
                                                                        <>
                                                                            {
                                                                                story.title &&
                                                                                <p dangerouslySetInnerHTML={{__html: story.title}}></p>
                                                                            }
                                                                            <audio
                                                                                src={`${baseUrl}/story/${story.audioStory}`}
                                                                                preload="true" controls={true}></audio>
                                                                        </>
                                                                    }

                                                                    {
                                                                        story.textStory &&
                                                                        <p dangerouslySetInnerHTML={{__html: subStory}}></p>
                                                                    }

                                                                </div>

                                                                <div className={'story-footer'}>
                                                                    <div>
                                                                    <span>({story.likes})<i className="fa fa-thumbs-up"
                                                                                            aria-hidden="true"
                                                                                            onClick={async () => {
                                                                                                await this.likeOrLove({
                                                                                                    storyId: story.storyId,
                                                                                                    like: true
                                                                                                }, story);
                                                                                            }}></i></span>
                                                                        <span>({story.loved})<i className="fa fa-heart"
                                                                                                aria-hidden="true"
                                                                                                onClick={async () => {
                                                                                                    await this.likeOrLove({
                                                                                                        storyId: story.storyId,
                                                                                                        love: true
                                                                                                    }, story);
                                                                                                }}></i></span>
                                                                    </div>
                                                                    <p className="cur-pointer" onClick={() => {
                                                                        this.viewStory(story);
                                                                    }}
                                                                       data-toggle={'modal'}
                                                                       data-target={'#modal-view-story'}>
                                                                        <i className="fa fa-caret-down mr-1"
                                                                           aria-hidden="true"> </i>
                                                                        comments <span>({story.comments.total})</span>
                                                                    </p>
                                                                </div>

                                                            </div>
                                                        );
                                                    })
                                                }

                                            </div>
                                                <div className="row tab-pane fade" role="tabpanel"
                                                     aria-labelledby="pills-notification-tab" id={'pills-notification'}>

                                                {
                                                    this.state.notifications.length === 0 &&

                                                    <h4 className={'empty-stories'}>No notification found</h4>
                                                }

                                                {
                                                    this.state.notifications.map((notification, i) => {

                                                        return (
                                                            <div className={'story-notification'} key={i}>

                                                                <div className="profile_img">

                                                                    {
                                                                        notification.user.userImg &&

                                                                        <img
                                                                            src={`${baseUrl}/avatar/${notification.user.img}`}
                                                                            alt=""/>

                                                                    }

                                                                    {
                                                                        !notification.user.img && notification.user.gender.toLowerCase() === 'male' &&
                                                                        <img
                                                                            src={`${baseUrl}/avatar/avatar - 09-01.png`}
                                                                            alt=""/>
                                                                    }

                                                                    {
                                                                        !notification.user.img && notification.user.gender.toLowerCase() === 'female' &&
                                                                        <img
                                                                            src={`${baseUrl}/avatar/avatar - 14-01.png`}
                                                                            alt=""/>
                                                                    }

                                                                    {
                                                                        !notification.user.img && notification.user.gender.toLowerCase() !== 'male' && notification.user.gender.toLowerCase() !== 'female' &&
                                                                        <img
                                                                            src={`${baseUrl}/avatar/avatar-2877.png`}
                                                                            alt=""/>
                                                                    }

                                                                </div>

                                                                <div className={'profile_info'}>
                                                                    <Link to={`/profile/${notification.user.name}`}
                                                                          className="purple_color font-weight-bold text-uppercase">{notification.user.name}</Link>
                                                                    <p className="small_font text-uppercase">{moment(notification.createdAt).local(true).format('lll')}</p>
                                                                    <p className="">{ notification.message }</p>
                                                                </div>

                                                            </div>
                                                        );
                                                    })
                                                }

                                                </div>
                                            </>
                                        }
                                    </div>


                                </div>

                            </div>


                            <div className="col-12 col-md-12 col-lg-3 col-xl-3">
                                <div className="profile_listing_right">

                                    {
                                        <>
                                            {
                                                this.state.adProducts &&

                                                    this.state.adProducts.map((product, index) => {
                                                        return (
                                                            <div className="single_product sidebar_product" key={index}>
                                                                <a target="_balnk" href={product.outsite_url}>
                                                                    <img src={`${baseUrl}/assets/uploads/adsproduct/${product.img}`} alt="Product image" />
                                                                </a>
                                                            </div>
                                                        );
                                                    })

                                            }
                                        </>
                                    }

                                </div>
                            </div>


                        </div>
                    </div>
                </section>


                <div className="modal fade modal_rec_audio" id="modal-rec-audio" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body p-2">

                                <h4>Record Audio</h4>

                                <div className={'rec-control'}>
                                    <i className="fa fa-microphone" id={'rec-start'} title={'Start Recording'} aria-hidden="true" onClick={this.startRecoding}></i>
                                    <i className="fa fa-pause" id={'rec-pause'} title={'Pause  Recording'} aria-hidden="true" onClick={this.pauseRecording}></i>
                                    <i className="fa fa-stop text-danger" id={'rec-stop'} title={'Stop  Recording'} aria-hidden="true" onClick={this.stopRecording}></i>
                                    <i className="fa fa-share" title={'Post Story'} aria-hidden="true" onClick={ async () => {
                                        await this.postAudio();
                                    }}></i>
                                </div>

                                {
                                    <div className="equalizer">
                                        {
                                            Array.apply(null, Array(50)).map((key, i) => {

                                                return (
                                                    <div style={{animationDelay: (Math.ceil(Math.random() * 9)  * 0.1).toFixed(1) + 's'}} key={i}></div>
                                                );
                                            })
                                        }
                                    </div>
                                }

                                <audio id={'play-rec-audio'} controls={true}></audio>
                                <input type="text" className="form-control" placeholder={'Story Title....'} onChange={(e) => {
                                    e.persist();
                                    this.storyTitle = e.target.value;
                                }} />

                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade modal_view_story" id="modal-view-story" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-xl modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body p-2">

                                {
                                    Object.keys(this.state.fullStory).length &&
                                    <div className={'story'}>

                                        <div className={'story-author'}>
                                            <div className="profile_img">

                                                {
                                                    this.state.fullStory.userImg &&

                                                    <img src={`${baseUrl}/avatar/${this.state.fullStory.userImg}`} alt=""/>

                                                }

                                                {
                                                    !this.state.fullStory.userImg && this.state.fullStory.gender.toLowerCase() === 'male' &&
                                                    <img src={`${baseUrl}/avatar/avatar - 09-01.png`} alt=""/>
                                                }

                                                {
                                                    !this.state.fullStory.userImg && this.state.fullStory.gender.toLowerCase() === 'female' &&
                                                    <img src={`${baseUrl}/avatar/avatar - 14-01.png`} alt=""/>
                                                }

                                                {
                                                    !this.state.fullStory.userImg && this.state.fullStory.gender.toLowerCase() !== 'male' && this.state.fullStory.gender.toLowerCase() !== 'female' &&
                                                    <img src={`${baseUrl}/avatar/avatar-2877.png`} alt=""/>
                                                }

                                            </div>

                                            <div className={'profile_info'}>
                                                <Link to={`/profile/${this.state.fullStory.userName}`} className="purple_color font-weight-bold text-uppercase">{this.state.fullStory.userName}</Link>
                                                <p className="small_font text-uppercase">{ moment(this.state.fullStory.createdAt).local(true).format('lll') }</p>
                                            </div>

                                        </div>

                                        <div className={'shared-story'}>

                                            {
                                                this.state.fullStory.audioStory &&
                                                <>
                                                    {
                                                        this.state.fullStory.title &&
                                                        <p dangerouslySetInnerHTML={{__html: this.state.fullStory.title}}></p>
                                                    }
                                                    <audio src={`${baseUrl}/story/${this.state.fullStory.audioStory}`} preload="true" controls={true}></audio>
                                                </>
                                            }

                                            {
                                                this.state.fullStory.textStory &&
                                                <p dangerouslySetInnerHTML={{__html: this.state.fullStory.textStory}}></p>
                                            }

                                        </div>

                                        <div className={'story-footer'}>
                                            <div>
                                                <span>({this.state.fullStory.likes})<i className="fa fa-thumbs-up" aria-hidden="true" onClick={ async () => {
                                                    await this.likeOrLove({storyId: this.state.fullStory.storyId, like: true}, this.state.fullStory);
                                                }}></i></span>
                                                <span>({this.state.fullStory.loved})<i className="fa fa-heart" aria-hidden="true" onClick={ async () => {
                                                    await this.likeOrLove({storyId: this.state.fullStory.storyId, love: true}, this.state.fullStory);
                                                }}></i></span>
                                            </div>
                                            <p>comments <span>({this.state.fullStory.comments.total})</span></p>
                                        </div>

                                        <div className={'comments'}>

                                            <div className={'comments-container'}>
                                                {
                                                    <>

                                                        {
                                                            this.state.fullStory.comments.total === 0 &&
                                                                <h4 className={'no-comments'}> No comment available</h4>
                                                        }

                                                        {
                                                            this.state.fullStory.comments.total > 0 &&
                                                            <>

                                                                <div className="comments-header">
                                                                    <p>Comments: </p>
                                                                    {
                                                                        this.state.fullStory.comments.next &&
                                                                        <span className={'view-prev-comments'} onClick={this.getStoryComments}>View Previous Comments</span>
                                                                    }
                                                                </div>

                                                                {
                                                                    this.state.fullStory.comments.data.map((comment, i) => {
                                                                       return (

                                                                           <div className={'comment'} key={i}>

                                                                               <div className={'comment-author'}>
                                                                                   <div className="profile_img" title={comment.userName}>
                                                                                       <Link to={`/profile/${comment.userName}`} className="purple_color font-weight-bold text-uppercase">

                                                                                       {
                                                                                           comment.userImg &&

                                                                                           <img src={`${baseUrl}/avatar/${comment.userImg}`} alt=""/>

                                                                                       }

                                                                                       {
                                                                                           !comment.userImg && comment.gender.toLowerCase() === 'male' &&
                                                                                           <img src={`${baseUrl}/avatar/avatar - 09-01.png`} alt=""/>
                                                                                       }

                                                                                       {
                                                                                           !comment.userImg && comment.gender.toLowerCase() === 'female' &&
                                                                                           <img src={`${baseUrl}/avatar/avatar - 14-01.png`} alt=""/>
                                                                                       }

                                                                                       {
                                                                                           !comment.userImg && comment.gender.toLowerCase() !== 'male' && comment.gender.toLowerCase() !== 'female' &&
                                                                                           <img src={`${baseUrl}/avatar/avatar-2877.png`} alt=""/>
                                                                                       }
                                                                                       </Link>
                                                                                   </div>

                                                                                   <div className={'comment-body'}>
                                                                                       <p className="small_font text-uppercase">{moment(comment.createdAt).local(true).format('lll')}</p>
                                                                                       <p dangerouslySetInnerHTML={{__html: comment.comment}}></p>
                                                                                   </div>

                                                                                   {
                                                                                       this.state.user &&

                                                                                       <div className="comment-action">
                                                                                           {
                                                                                               parseInt(comment.userId) === this.state.user.id &&
                                                                                               <i className="fa fa-pencil-square-o"
                                                                                                  title={'edit comment'}
                                                                                                  aria-hidden="true"
                                                                                                  data-toggle={'modal'}
                                                                                                  data-target={'#edit_comment'}
                                                                                                  onClick={() => {
                                                                                                      document.getElementById('edit-comment').value = comment.comment;
                                                                                                      this.selectedEditComment = comment;
                                                                                                  }}></i>
                                                                                           }

                                                                                           {
                                                                                               (parseInt(comment.userId) === this.state.user.id || parseInt(this.state.fullStory.userId) === this.state.user.id) &&
                                                                                               <i className="fa fa-trash-o"
                                                                                                  title={'remove comment'}
                                                                                                  aria-hidden="true"
                                                                                                  data-toggle={'modal'}
                                                                                                  data-target={'#remove_comment'}
                                                                                                  onClick={() => {
                                                                                                      this.removeCmt = {
                                                                                                          storyId: this.state.fullStory.storyId,
                                                                                                          commentId: comment.commentId
                                                                                                      };
                                                                                                  }}></i>
                                                                                           }
                                                                                       </div>

                                                                                   }

                                                                               </div>

                                                                           </div>

                                                                       );
                                                                    })
                                                                }

                                                            </>
                                                        }

                                                    </>
                                                }
                                            </div>

                                            <div className={'post-comment'}>
                                                <input type="text" className='form-control' id={'comment-input'} onChange={async (e) => {
                                                    e.persist();
                                                    this.commentText = e.target.value;
                                                }} />
                                                <button className='btn btn_purple_mid_light' onClick={this.sendComment}>Post</button>
                                            </div>

                                        </div>

                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade remove_story_modal" id="remove_story" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body">
                                <div className="modal_waper_main_profile bg-white">

                                    <h4>Are you sure want to remove the story?</h4>

                                    <div>
                                        <button className="btn btn-danger btn-sm" data-dismiss="modal" aria-label="Close" onClick={this.removeStory}>Remove</button>
                                        <button className="btn btn-info btn-sm" data-dismiss="modal" aria-label="Close">Cancel</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade remove_comment_modal" id="remove_comment" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body">
                                <div className="modal_waper_main_profile bg-white">

                                    <h4>Are you sure want to remove the comment?</h4>

                                    <div>
                                        <button className="btn btn-danger btn-sm" data-dismiss="modal" aria-label="Close" onClick={this.removeComment}>Remove</button>
                                        <button className="btn btn-info btn-sm" data-dismiss="modal" aria-label="Close">Cancel</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade edit_story_modal" id="edit_story" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="modal_waper_main_profile bg-white">

                                    <h4>Edit your story</h4>

                                    <textarea id="edit-story" rows="4"></textarea>

                                    <div>
                                        <button className="btn btn_purple_mid_light btn-sm" data-dismiss="modal" aria-label="Close" onClick={this.editStory}>Update</button>
                                        <button className="btn btn-info btn-sm" data-dismiss="modal" aria-label="Close">Cancel</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade edit_comment_modal" id="edit_comment" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="modal_waper_main_profile bg-white">

                                    <h4>Edit your comment</h4>

                                    <textarea id="edit-comment" rows="4"></textarea>

                                    <div>
                                        <button className="btn btn_purple_mid_light btn-sm" data-dismiss="modal" aria-label="Close" onClick={this.editComment}>Update</button>
                                        <button className="btn btn-info btn-sm" data-dismiss="modal" aria-label="Close">Cancel</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade friend_list_modal" id="friend-list" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="modal_close" data-dismiss="modal" aria-label="Close" style={{display: "none"}}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </div>

                                <h4>Select Friends</h4>
                                <div>

                                    {
                                        this.state.friendList.length === 0 &&
                                        <div className={'empty-list share-list'}>
                                            <h3>You do not have any connections yet</h3>
                                            <Link to={"/find-friends"} className="btn_outline"
                                                  onClick={(e) => {
                                                      document.querySelector('#friend-list .modal_close').click()
                                                  }}>LET'S FIND ONE</Link>
                                        </div>
                                    }

                                    {
                                        this.state.friendList.length >= 1 &&
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value='all' id="friend-all" onChange={this.selectForShare} />
                                            <label className="form-check-label" htmlFor="friend-all">
                                                All
                                            </label>
                                        </div>
                                    }

                                    {
                                        this.state.friendList.map((friend, i) => {
                                            return (
                                                <div className="form-check" key={i}>
                                                    <input className="form-check-input friend-lst" type="checkbox" value={friend.id} id={`friend${i}`}
                                                           onChange={(e) => {
                                                               document.getElementById('friend-all').checked = false;
                                                               this.selectForShare(e);
                                                           }} />
                                                        <label className="form-check-label" htmlFor={`friend${i}`}>
                                                            {friend.name}
                                                        </label>
                                                </div>
                                            );
                                        })
                                    }
                                </div>

                                {
                                    this.state.friendList.length >= 1 &&
                                    <button className="btn btn_purple_mid_light" data-dismiss="modal" aria-label="Close" onClick={this.storyShare}>Share</button>
                                }

                            </div>
                        </div>
                    </div>
                </div>


            </>
        );
    }

}

export default Story;
