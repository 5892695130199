import React from "react";

class Faq extends React.Component {

    componentDidMount() {
        document.title = 'FAQ | NowPurple Anonymous Dating Website';
        document.getElementsByTagName('meta')["description"].content = 'FAQ page of NowPurple. Find answers to all your questions about UK based dating website NowPurple.';
        document.getElementsByTagName('meta')["keywords"].content = 'anonymous dating app, anonymous dating website';

        document.body.classList.add('static');
        document.documentElement.scrollTop = 0;
    }
    componentWillUnmount() {
        document.body.classList.add('static');
    }

    render() {
        return (
            <>

                <div className="page_hero"></div>


                <section id="static">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="page_title text-center">
                                    <h3 className="purple_color">FAQ Section</h3>
                                </div>
                            </div>
                        </div>

                        <div className="row" id={'mainfaq'}>
                            <div className="col-md-10 offset-md-1">
                                <div className="accordion" id="faq">


                                    <div className="card">
                                        <div className="card-header" id="faq-head1">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq1" aria-expanded="true" aria-controls="faq1" >
                                                1. Why is our platform called NowPurple?
                                            </h3>
                                        </div>
                                        <div id="faq1" className="collapse show" aria-labelledby="faq-head1" data-parent="#faq">
                                            <div className="card-body">
                                                <p>As you know the blue colour symbolically in most cultures is associated with masculinity and pink colour – with femininity. When these two colours are mixed together, we get the colour purple – a unity of the two. NowPurple is a dating site and our aim is to help bringing two people together to form a union (a union of people of any gender or sexual orientation).</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head2">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq2" aria-expanded="true" aria-controls="faq2" >
                                                2. How to register on NowPurple?
                                            </h3>
                                        </div>
                                        <div id="faq2" className="collapse" aria-labelledby="faq-head2" data-parent="#faq">
                                            <div className="card-body">
                                                <p>First go to NowPurple website and click on Register Now button.</p>
                                                <p>In this pop-up window you can see a captcha image. Click and select the correct answer according to the image shown. After we answer correctly, a Continue button appears. Click on it and in the next window choose your username, password, age, gender, country, city and postcode. Next click on Let's Get Started. After reading our House Rules in the next popup, please click on Continue. </p>
                                                <p>Now you are in your account area and there is just one very important thing to do. Underneath your profile avatar and username, you will see the PIN number. It is very important to write it down as this PIN number will help you to reset the password. As we do not capture your email at this stage (you can later add it to your account voluntarily), there will be no other means to reset your password as only by using this PIN number. </p>
                                                <p>In our next video you will learn how to update your profile. Updating your profile is very important if you want to be successful at finding meaningful connections or relationships on NowPurple. </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head3">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq3" aria-expanded="true" aria-controls="faq3" >
                                                3. How to update my NowPurple profile?
                                            </h3>
                                        </div>
                                        <div id="faq3" className="collapse" aria-labelledby="faq-head3" data-parent="#faq">
                                            <div className="card-body">
                                                <p>After logging in, we are here in your My Account area where you can update your profile. </p>
                                                <p>First, you can update your avatar by clicking on the pencil icon near the top of the avatar. In the pop-up select from the list of the avatars the one that best suits your personality.</p>
                                                <p>Next, you can update your Bio and Why I’m Here by clicking on the pencil icon near the Bio heading. In the pop-up first fill in the Bio field. You can use up to 200 words here to describe yourself and your biography. Next fill in the Why I’m here field. You can use up to 50 words to describe why you registered on NowPurple and what you are looking for. I am using here just some dummy text, but for you it is important to write both of these paragraphs thoughtfully as this text will in many ways represent who you are to your profile visitors. Now click on Update Details. </p>
                                                <p>Further on, you can update your Contact Information and your Basic Information by clicking on the pencil icon near the Contact Information heading.</p>
                                                <p>In this pop-up first you can fill in the Phone number and the Email fields. These fields will not be visible to your profile visitors. The main reason to fill those in are to add additional options to reset your password in case you forget it and cannot find your PIN number. Therefore, these are optional fields and you can choose not to fill those in. </p>
                                                <p>Next, you can see that the Country, City, Age, Postal Code and Gender fields are already pre-populated by information you gave upon registration. You can change those here at any time. </p>
                                                <p>In addition to that, here you can fill in the Profession field. And finally, you can select from a variety of other options describing your looks.  All of these fields are optional, but the more information you provide the better as this will enable other users to get to know you better from the information you provide in your profile. </p>
                                                <p>And here you can update your Interests by clicking on the pencil icon near the Interests heading. In the pop-up simply select your interests. You can tick-mark up to 20 interests here. </p>
                                                <p>Lastly, scroll down and click on Update Details. </p>
                                                <p>Now your profile information is updated. To see how your profile looks like when viewed by other users on the platform, click on the profile view icon on the top of the My Account area near the Bio heading. </p>
                                                <p>In our next video you will learn about Find Friends, My Connections and Friend Requests options and how those work.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head4">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq4" aria-expanded="true" aria-controls="faq4" >
                                                4. How to update my NowPurple profile?
                                            </h3>
                                        </div>
                                        <div id="faq4" className="collapse" aria-labelledby="faq-head4" data-parent="#faq">
                                            <div className="card-body">
                                                <p>Find Friends option is quite self-explanatory. By clicking on this button, you will be navigated to the Find Friends page where all our users are listed. </p>
                                                <p>On the top of this page you can see a Filter which you can use to filter users by age, gender, country, city and post code. </p>
                                                <p>Regarding each user in this list you can see their avatar and username here. Next here is the flag and rating which indicates the user’s current status. To know more about NowPurple’s flagging system and rating system, please watch the relevant videos or read the relevant entries in the FAQs.</p>
                                                <p>Also, here you can see the user’s age, country, city and post code. And in this area here, you can see the Bio of the user as well as Why I’m Here tab. To view the full profile of any user, click on this View Full Profile link. </p>
                                                <p>Most importantly, here under the basic info you can see a button called Add to list. When you click it, a pop-up will appear. To confirm, click on the Continue button. That will notify the user about your friend request. Once the user accepts your friend request, you will be able to chat or communicate with the user. </p>
                                                <p>Next, let’s go back to My Account page. Here you can see the My Connections button. Here you will be able to see all the connections you already have. For example, I already have 1 user listed here. Each user here has 3 buttons – Chat Now, View and Block. These three buttons are quite self-explanatory. </p>
                                                <p>On the top right you can see the Blocked List. If you blocked any users those users will be listed here in this area. </p>
                                                <p>Further on, let’s look at the Friends Requests page. When you click on Friends Requests button, in the pop-up you can see how many users requested to become friends with you. And you can accept or cancel the requests here. </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head5">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq5" aria-expanded="true" aria-controls="faq5" >
                                                5. How to use My Gallery?
                                            </h3>
                                        </div>
                                        <div id="faq5" className="collapse" aria-labelledby="faq-head5" data-parent="#faq">
                                            <div className="card-body">
                                                <p>To use My Gallery, click on My Gallery button. Here in this gallery you can add up to 12 photos. To add photos, if you are in desktop, click on the plus sign, then click on Browse button and choose the photo from your computer. Then click on Open and next on Upload Now. The photo will be uploaded into your gallery. </p>
                                                <p>If you are using a mobile device, click on the plus sign and then on the Browse button. You will be prompted to allow the browser to access your camera. Allow the browser to do this. Then you can either take a photo with your camera or choose a photo from your storage. When you have done one of these, click on Upload and the photo will be uploaded into your gallery. Each mobile device has a slightly different interface, but the basic procedure is very similar. </p>
                                                <p>If you want to delete any of the images, you can do so by clicking on the cross icon visible here by each image. To delete an image simply click on it. </p>
                                                <p>And this is how you can manage My Gallery. </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head6">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq6" aria-expanded="true" aria-controls="faq6" >
                                                6. How to reset my password?
                                            </h3>
                                        </div>
                                        <div id="faq6" className="collapse" aria-labelledby="faq-head6" data-parent="#faq">
                                            <div className="card-body">
                                                <p>To reset your password, click on the Account Security button. Next click on Update Password here. In the pop-up first enter your old password and then enter your new password and confirm it. Finally click on Update. Now your password has been updated. </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head7">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq7" aria-expanded="true" aria-controls="faq7" >
                                                7. How to delete my account?
                                            </h3>
                                        </div>
                                        <div id="faq7" className="collapse" aria-labelledby="faq-head7" data-parent="#faq">
                                            <div className="card-body">
                                                <p>To delete your account, click on the Account Security button. Next click on Delete Account here. In the pop-up enter your password to confirm your identity. And finally click on the Delete Account button. Please be aware that your account will be permanently deleted and that neither you nor us will be able to restore your account. </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head8">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq8" aria-expanded="true" aria-controls="faq8" >
                                                8. How to use Password Recovery/Forgot Password?
                                            </h3>
                                        </div>
                                        <div id="faq8" className="collapse" aria-labelledby="faq-head8" data-parent="#faq">
                                            <div className="card-body">
                                                <p>If you have forgotten your password, click on Login and then click on Forgot Password. In the next window here we have two options. You can reset password using your PIN number. Or if you provided your email address in your My Account area, you can reset your password using your email. In this example I am showing you how to reset your password using PIN number. </p>
                                                <p>To do that click on the Use Secret PIN. In the pop-up fill in the Secret PIN field with your PIN and in the next field write a new password. Finally click on the Reset button. Then your password will be reset. </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head9">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq9" aria-expanded="true" aria-controls="faq9" >
                                                9. What are the House Rules of NowPurple?
                                            </h3>
                                        </div>
                                        <div id="faq9" className="collapse" aria-labelledby="faq-head9" data-parent="#faq">
                                            <div className="card-body">
                                                <p>As a member, you will be able to contact and exchange communications with other members (provided that they agree to communicate with you), without limitation. Please note that we may limit the number of communications that you can send if required in order to protect our members and to secure our Service.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head10">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq10" aria-expanded="true" aria-controls="faq10" >
                                                10. User blocking rules
                                            </h3>
                                        </div>
                                        <div id="faq10" className="collapse" aria-labelledby="faq-head10" data-parent="#faq">
                                            <div className="card-body">
                                                <p>As a member, you will be able to block other members if they are behaving inappropriately. We offer an option to block another member and to select a reason why you decided to block them. </p>
                                                <p>Please note that if you block 10 members in a day, your profile will be blocked until you contact NowPurple admin via Contact Page.</p>
                                                <p>You will be able to see blocked member list in your profile, and if you want, you can unblock any of those members. NowPurple reserves the right to unblock any of the blocked members if needed.</p>
                                                <p>You can learn how to block or unblock users by watching the video called “How to block and unblock users”. </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head11">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq11" aria-expanded="true" aria-controls="faq11" >
                                                11. Flag rules
                                            </h3>
                                        </div>
                                        <div id="faq11" className="collapse" aria-labelledby="faq-head11" data-parent="#faq">
                                            <div className="card-body">
                                                <p>NowPurple uses flags in order to indicate suspicious behaviour to other members.</p>
                                                <p>Light yellow flag – after a member gets blocked by other members 3 times within 1 day of setting up the profile.</p>
                                                <p>Deep yellow flag – after a member gets blocked by other members 10 times within 3 days of setting up the profile.</p>
                                                <p>Red flag – after the user gets blocked by other members 20 times within 3 days of setting up the profile.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head12">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq12" aria-expanded="true" aria-controls="faq12" >
                                                12. Other blocking rules
                                            </h3>
                                        </div>
                                        <div id="faq12" className="collapse" aria-labelledby="faq-head12" data-parent="#faq">
                                            <div className="card-body">
                                                <p>If a member finishes conversation in less than 5 min with 7 users (within 3 hours), then he/she gets blocked for 15 min.</p>
                                                <p>If a user gets blocked 20 times in 1 day, NowPurple may block the user permanently.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head13">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq13" aria-expanded="true" aria-controls="faq13" >
                                                13. Review system
                                            </h3>
                                        </div>
                                        <div id="faq13" className="collapse" aria-labelledby="faq-head13" data-parent="#faq">
                                            <div className="card-body">
                                                <p>If members talk with each other minimum 10 min they will get a reviews popup allowing the users to review each other with a star rating (1-5 stars). </p>
                                                <p>Reviews will appear on member profile display page and on the full profile page.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head14">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq14" aria-expanded="true" aria-controls="faq14" >
                                                14. How to block and unblock users?
                                            </h3>
                                        </div>
                                        <div id="faq14" className="collapse" aria-labelledby="faq-head14" data-parent="#faq">
                                            <div className="card-body">
                                                <p>To block or unblock a user you can take this action in 2 different places. Option one. Go to My Account area and click on My Connections. If there is any user you want to block, you can click on the Block button. In the pop-up please choose the reason why you decided to block this user and then click on the Block User button. </p>
                                                <p>Option two. Click on Chat Now. On the left hand side you can see the list of your connections. Click the Chat Now button near the user whom you want to block. Next in the top right corner we can see a lock icon. Click on it. By clicking on the lock icon a block button appears here. Click on this button. In the pop-up please choose the reason why you decided to block this user and then click on the Block User button. Now this user is blocked and cannot communicate with you. </p>
                                                <p>If you want to unblock a user, you can find all users blocked by you in the My Connections area in the tab called Blocked List. Here you can find the user you want to unblock and click on the Unblock button. </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head15">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq15" aria-expanded="true" aria-controls="faq15" >
                                                15. What is NowPurple PIN number and why it is important?
                                            </h3>
                                        </div>
                                        <div id="faq15" className="collapse" aria-labelledby="faq-head15" data-parent="#faq">
                                            <div className="card-body">
                                                <p>NowPurple takes your privacy very seriously, therefore we offer users to create profiles without collecting email address, phone number or other sensitive private information.  For this reason, password reset function cannot be implemented using traditional methods. </p>
                                                <p>If you choose not to provide us an email address, you can still reset your password using the secret PIN number listed in your My Account page under your avatar. </p>
                                                <p>This PIN number is visible only to you. It is extremely important to write down this PIN number in a safe place which you will remember in case you are logged out of your account, forgot your password and need to use the PIN number to reset your password. </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head16">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq16" aria-expanded="true" aria-controls="faq16" >
                                                16. What is NowPurple PIN number and why it is important?
                                            </h3>
                                        </div>
                                        <div id="faq16" className="collapse" aria-labelledby="faq-head16" data-parent="#faq">
                                            <div className="card-body">
                                                <p>NowPurple takes your privacy very seriously, therefore we offer users to create profiles without collecting email address, phone number or other sensitive private information.  For this reason, password reset function cannot be implemented using traditional methods. </p>
                                                <p>If you choose not to provide us an email address, you can still reset your password using the secret PIN number listed in your My Account page under your avatar. </p>
                                                <p>This PIN number is visible only to you. It is extremely important to write down this PIN number in a safe place which you will remember in case you are logged out of your account, forgot your password and need to use the PIN number to reset your password. </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head17">
                                            <h3 data-toggle="collapse" data-target="#faq17" aria-expanded="true" aria-controls="faq17" >
                                                17. Is it safe to use “Shop to help” page? I am concerned about my privacy.
                                            </h3>
                                        </div>
                                        <div id="faq17" className="collapse" aria-labelledby="faq-head17" data-parent="#faq">
                                            <div className="card-body">
                                                <p>Yes, it is 100% safe to use. If you click on any product in this marketplace you will be redirected to a third-party website to complete the product purchase. As you will be buying from a third party, NowPurple will never show up in your bank statements. This also means that you will never provide us your debit or credit card number or other financial information.</p>
                                                <p>You will however provide such information to that particular third-party website. We take great care to select business partners for our marketplace who have high standards of eCommerce and all of them use highly secure online payment processing platforms where all of your financial information is encrypted and secure.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head18">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq18" aria-expanded="true" aria-controls="faq18" >
                                                18. What is the delivery confirmation procedure and product quality control in “Shop to help”?
                                            </h3>
                                        </div>
                                        <div id="faq18" className="collapse" aria-labelledby="faq-head18" data-parent="#faq">
                                            <div className="card-body">
                                                <p>We take great care to select business partners for our marketplace who have high standards of eCommerce, high-quality products and a great track record of on-time delivery. However, we strongly advise to pay via PayPal as this will ensure you get a 100% money back guarantee.</p>
                                                <p>If any advertiser lets you down regarding product quality or shipping, you can also always write a review about that seller on our platform. Our advertisers are displayed according to ratings by users, which will help us to identify poor performing ones and prevent negative experience for other users. </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head19">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq19" aria-expanded="true" aria-controls="faq19" >
                                                19. Can I block a user who is annoying me?
                                            </h3>
                                        </div>
                                        <div id="faq19" className="collapse" aria-labelledby="faq-head19" data-parent="#faq">
                                            <div className="card-body">
                                                <p>As a member, you will be able to block other members if they are behaving inappropriately. We offer you an option to block another member and to select a reason why you decided to block them.</p>
                                                <p>Please note that if you block 10 members in a day, your profile will be blocked until you contact NowPurple admin via Contact Page. During this time NowPurple will investigate your case and if you blocked someone for an invalid reason, your profile will be suspended.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head20">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq20" aria-expanded="true" aria-controls="faq20" >
                                                20. Can I make a voice call through NowPurple system?
                                            </h3>
                                        </div>
                                        <div id="faq20" className="collapse" aria-labelledby="faq-head20" data-parent="#faq">
                                            <div className="card-body">
                                                <p>At this moment you cannot make a voice call, but you can send a recorded voice message. We are working on implementing voice calling system in the near future.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head21">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq21" aria-expanded="true" aria-controls="faq21" >
                                                21. Why NowPurple doesn’t show any photos of users when you first use our platform?
                                            </h3>
                                        </div>
                                        <div id="faq21" className="collapse" aria-labelledby="faq-head21" data-parent="#faq">
                                            <div className="card-body">
                                                <p>Our highest priority is our users’ privacy. From extensive research, we at NowPurple have concluded that many users do not want to share their photos publicly, but that there is a very large number of people who are more than happy to share their images or other media with those whom they know. For this same reason, many users also use fake photos which makes their profiles non-authentic and that impacts negatively all users of the platform. </p>
                                                <p>We are strictly against fake profiles. For this reason, we made our platform very easy to use by introducing avatars. You can choose an avatar according to your personality and then you can add a user into your chat list, start a conversation, and if you both trust each other, then you can ask for the photos or galleries to be shared with you.</p>
                                                <p>Lastly, on our platform, we would like to promote the view that appearance is secondary. Beauty does not last forever, but personality remains. We are certain that this feature will help people to create much more authentic connections and relationships. If you do have any thoughts about this feature you are welcome to contact us and share those with us. </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head22">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq22" aria-expanded="true" aria-controls="faq22" >
                                                22. Can I delete my NowPurple profile?
                                            </h3>
                                        </div>
                                        <div id="faq22" className="collapse" aria-labelledby="faq-head22" data-parent="#faq">
                                            <div className="card-body">
                                                <p>Yes, you can delete your profile at any time from inside your profile page. You do not need to contact us to do that. </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head23">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq23" aria-expanded="true" aria-controls="faq23" >
                                                23. How can I re-set my password for my NowPurple profile?
                                            </h3>
                                        </div>
                                        <div id="faq23" className="collapse" aria-labelledby="faq-head23" data-parent="#faq">
                                            <div className="card-body">
                                                <p>When you created the profile, NowPurple didn’t collect any email address, phone number or other similar information from you for privacy reasons.</p>
                                                <p>It is thus very important, Therefore, by default we do not offer password reset function. But if you set the email address inside your NowPurple profile page voluntarily after you register, you will then have a password reset function.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head24">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq24" aria-expanded="true" aria-controls="faq24" >
                                                24. I have set my postcode in my profile. Are you capturing my geolocation?
                                            </h3>
                                        </div>
                                        <div id="faq24" className="collapse" aria-labelledby="faq-head24" data-parent="#faq">
                                            <div className="card-body">
                                                <p>No, we don’t capture any geolocation data. If someone uses a postcode that is nearby your one, we simply match the postcodes in our local database.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head25">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq25" aria-expanded="true" aria-controls="faq25" >
                                                25. Why my profile is marked with a yellow flag/deep yellow flag/red flag?
                                            </h3>
                                        </div>
                                        <div id="faq25" className="collapse" aria-labelledby="faq-head25" data-parent="#faq">
                                            <div className="card-body">
                                                <p>NowPurple uses flags system in order to indicate suspicious behaviour to other members.</p>
                                                <p>Light yellow flag – after a member gets blocked by other members 3 times within 1 day of setting up the profile.</p>
                                                <p>Deep yellow flag – after a member gets blocked by other members 10 times within 3 days of setting up the profile.</p>
                                                <p>Red flag – after the user gets blocked by other members 20 times within 3 days of setting up the profile.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head26">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq26" aria-expanded="true" aria-controls="faq26" >
                                                26. Why my profile is blocked by NowPurple?
                                            </h3>
                                        </div>
                                        <div id="faq26" className="collapse" aria-labelledby="faq-head26" data-parent="#faq">
                                            <div className="card-body">
                                                <ul>
                                                    <li>Your profile might have been reported as underage by another member. </li>
                                                    <li>You might have ignored 7 conversations within3 hours, which is against NowPurple house rules. In this case your block will last 15 minutes. If you think it was a wrong judgement, you can contact us via our contact form. </li>
                                                    <li>If a member gets blocked 20 times in 1 day by other members, NowPurple will block that member permanently.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head27">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq27" aria-expanded="true" aria-controls="faq27" >
                                                27. How can I unlock my profile after being blocked?
                                            </h3>
                                        </div>
                                        <div id="faq27" className="collapse" aria-labelledby="faq-head27" data-parent="#faq">
                                            <div className="card-body">
                                                <p>If you think blocking your profile was a wrong judgement, you can contact us via our contact form.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head28">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq28" aria-expanded="true" aria-controls="faq28" >
                                                28. Can I create multiple profiles with NowPurple?
                                            </h3>
                                        </div>
                                        <div id="faq28" className="collapse" aria-labelledby="faq-head28" data-parent="#faq">
                                            <div className="card-body">
                                                <p>No, only one profile per person is allowed. If we find out you have multiple profiles, all your profiles will be blocked.  </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head29">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq29" aria-expanded="true" aria-controls="faq29" >
                                                29. Is NowPurple truly free?
                                            </h3>
                                        </div>
                                        <div id="faq29" className="collapse" aria-labelledby="faq-head29" data-parent="#faq">
                                            <div className="card-body">
                                                <p>Yes, NowPurple is 100% free and you get access to all functions and features completely free. </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head30">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq30" aria-expanded="true" aria-controls="faq30" >
                                                30. Will NowPurple have an app in the future?
                                            </h3>
                                        </div>
                                        <div id="faq30" className="collapse" aria-labelledby="faq-head30" data-parent="#faq">
                                            <div className="card-body">
                                                <p>We care about your privacy and installing a mobile app can sometimes cause a privacy issue. For example, you may not want anyone to see that you are using a dating app on your mobile device. For this reason, we used the latest technology to build a website (not app) which is user-friendly on all kinds of devices and which will give you a hassle-free user experience. This does not mean that we are against apps per se. Quite the opposite. We are currently in the process of developing an app for those users who would prefer to use an app rather than a website.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card" id={'guard-faq'}>
                                        <div className="card-header" id="faq-head31">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq31" aria-expanded="true" aria-controls="faq31" >
                                                31. Why do I need to activate Safeguard function?
                                            </h3>
                                        </div>
                                        <div id="faq31" className="collapse" aria-labelledby="faq-head31" data-parent="#faq">
                                            <div className="card-body">
                                                <p>When you have activated Safeguard function, other NowPurple users will feel more secure interacting with you. It shows that you care about your security and that of the other users.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head32">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq32" aria-expanded="true" aria-controls="faq32" >
                                                32. If I activate Safeguard can anyone see my personal information?
                                            </h3>
                                        </div>
                                        <div id="faq32" className="collapse" aria-labelledby="faq-head32" data-parent="#faq">
                                            <div className="card-body">
                                                <p>No-one will have access to your documents including any government agency. Only if any adverse events arise relating to questions of human life, safety or other criminal activity and provided we receive a court order, information of a particular user may be disclosed to government agencies.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="faq-head33">
                                            <h3 className="collapsed" data-toggle="collapse" data-target="#faq33" aria-expanded="true" aria-controls="faq33" >
                                                33. Can I still use the NowPurple platform without Safeguard?
                                            </h3>
                                        </div>
                                        <div id="faq33" className="collapse" aria-labelledby="faq-head33" data-parent="#faq">
                                            <div className="card-body">
                                                <p>Yes, you can use almost 100% functions without activating Safeguard. There is just one thing that we don’t recommend and that is to meet any user physically as it will be dangerous for you as well as for the other user.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </>
        );
    }
}

export default Faq;
