import React from "react";
import {baseUrl, emailContact, getCaptcha} from "../services/ApiService";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

class Contact extends React.Component {

    state = {
        captcha: {}
    }

    componentDidMount() {
        document.title = 'Contact | NowPurple Dating Website UK';
        document.getElementsByTagName('meta')["description"].content = 'Contact page of NowPurple. Contact us here if you have any queries about UK based dating site NowPurple and our services.';
        document.getElementsByTagName('meta')["keywords"].content = 'free uk dating site';

        this.captcha();
    }

    captcha = async () => {
        const captcha = await getCaptcha();
        if (captcha) {
            console.log(captcha);
            const bk = this.state;
            bk.captcha = captcha;
            this.setState(bk);
            this.setState(bk);
        }


    }

    _check = false;
    checkCaptcha = (e) => {
        e.persist();
        if (e.target.value === this.state.captcha.answer) {
            this._check = true;
            document.getElementById('send-email').classList.remove('disabled');
        } else {
            this._check = false;
            document.getElementById('send-email').classList.add('disabled');
        }
    }

    _mailMessage = {
        name: '',
        email: '',
        phone: '',
        message: ''
    }
    sendEmail = async (e) => {
        e.preventDefault();
        if (!this._check) {
            toast.error('please select the correct captcha');
            return false
        }
        if (this._mailMessage.name === '' || this._mailMessage.email === '' || this._mailMessage.phone === '' || this._mailMessage.message === '') {
            toast.error('Fill all the fields');
            return false;
        }
        const res = await emailContact(this._mailMessage);
        if (res) {
            if (res.status) {
                toast.success(res.message);
                setTimeout(() => {
                    window.location.reload();
                }, 100);
            } else {
                toast.error(res.error);
            }
        }
    }

    render() {
        return (
            <>

                <div className="page_hero"></div>


                <section id="contact_us">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="page_title text-center">
                                    <h3 className="purple_color">Contact Us</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="contect_left">
                                    <form onSubmit={this.sendEmail}>
                                        <div className="form-group">
                                            <label htmlFor="name">Full Name</label>
                                            <input type="text" className="form-control" id="name"
                                                   onChange={(e) => {
                                                       e.persist();
                                                       this._mailMessage.name = e.target.value;
                                                   }}
                                                   aria-describedby="emailHelp" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email">Email address</label>
                                            <input type="email" className="form-control" id="email"
                                                   onChange={(e) => {
                                                       e.persist();
                                                       this._mailMessage.email = e.target.value;
                                                   }}
                                                   aria-describedby="emailHelp" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="phone">Phone</label>
                                            <input type="tel" className="form-control" id="phone"
                                                   onChange={(e) => {
                                                       e.persist();
                                                       this._mailMessage.phone = e.target.value;
                                                   }}
                                                   aria-describedby="phone" pattern="[0-9]{10, 12}" />
                                        </div>
                                        {/*<div className="form-group">*/}
                                        {/*    <label htmlFor="reason">Reason to contact</label>*/}
                                        {/*    <select className="form-control" id="reason">*/}
                                        {/*        <option>General inquiry</option>*/}
                                        {/*        <option>Found a bug</option>*/}
                                        {/*        <option>Want to know how Shop system works</option>*/}
                                        {/*    </select>*/}
                                        {/*</div>*/}
                                        <div className="form-group">
                                            <label htmlFor="message">Message</label>
                                            <textarea className="form-control" id="message" rows="3"
                                                      onChange={(e) => {
                                                          e.persist();
                                                          this._mailMessage.message = e.target.value;
                                                      }}></textarea>
                                        </div>

                                        {/*capcture*/}
                                        <div className="contact_cap">
                                            <div className="cap_inputs">
                                                <h6>What can be seen in this picture?</h6>
                                                <div
                                                    className="d-block d-md-flex d-lg-flex d-xl-flex justify-content-between">
                                                    <div
                                                        className="cap_img mt-4 mt-md-0 mt-lg-0 order-0 order-md-1 order-lg-1">
                                                        {
                                                            this.state.captcha.img &&
                                                            <img src={`${baseUrl}/assets/uploads/captcha/${this.state.captcha.img}`} alt="cap images" />
                                                        }
                                                    </div>
                                                    <div className="options order-1 order-md-0 order-lg-0">

                                                        {
                                                            this.state.captcha.description &&
                                                            <>
                                                                {
                                                                    this.state.captcha.description.split(',').map((item, i) => {
                                                                        return (
                                                                            <div className="form-check" key={i}>
                                                                                <input className="form-check-input" name={'captcha'}
                                                                                       type="radio" id={i} value={item} onChange={this.checkCaptcha} />
                                                                                <label className="form-check-label" htmlFor={i}>{item}</label>
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <button type="submit" id={'send-email'} className="btn now_submit btn-light disabled" >Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/*End Row*/}
                    </div>
                </section>
            </>
        );
    }
}

export default Contact;
