import React, {useEffect} from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Home from "./pages/Home";
import Routes from "./Routes";
import LoginContextProvider from "./contexts/LoginContext";
import ReactGa from 'react-ga';

function App() {
    useEffect(() => {
        ReactGa.initialize('UA-180189754-1');
        ReactGa.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <Router forceRefresh={false}>
            <>
                <LoginContextProvider>
                    <Header/>

                    <Routes />

                    <Footer/>
                </LoginContextProvider>
            </>
        </Router>
    );
}

export default App;
