import React from "react";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {unsubscribe} from "../services/ApiService";

class Unsubscribe extends React.Component {

    userName = '';
    password = '';

    submitUnsubscribe = async () => {
        if (this.userName === '' || this.password === '') {
            toast.error('Please fill user name and password');
            return false;
        }

        const res = await unsubscribe({name: this.userName, password: this.password});

        if (res) {
            if (res.status) {
                toast.success(res.message);
            } else {
                toast.error(res.error);
            }
        }

    }

    render() {
        return (
            <>

                <div className="page_hero"></div>

                <div className={'unsubscribe'}>
                    <div>
                        <div className="form-group">
                            <label htmlFor="name">User Name</label>
                            <input type="text" className="form-control" id="name"
                                   onChange={(e) => {
                                       e.persist();
                                       this.userName = e.target.value;
                                   }}
                                   aria-describedby="emailHelp" placeholder="User Name" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input type="password" className="form-control" id="password"
                                   onChange={(e) => {
                                       e.persist();
                                       this.password = e.target.value;
                                   }}
                                   placeholder="Password" />
                        </div>
                        <button type="button" onClick={this.submitUnsubscribe} className="btn btn-secondary btn-lg btn-block">Unsubscribe</button>
                    </div>
                </div>

            </>
        );
    }

}

export default Unsubscribe;
