import React from "react";
import {getHowToVideos} from "../services/ApiService";

class HowTo extends React.Component {

    state = {
        videos: {data: []},
    };

    componentDidMount() {
        this.restCalls();
    }

    restCalls = async () => {
        await this.getHowToVideo();
    }

    _page = 1;
    getHowToVideo = async () => {
        const res = await getHowToVideos(this._page);
        if (res) {
            const bk = this.state;
            bk.videos = res;
            this.setState(bk);
        }
    }

    render() {
        return (

            <>

                <div className="page_hero"></div>

                <div className='container'>

                        <div className="row">
                            <section className="how-to-content">

                            {
                                this.state.videos.data.length === 0 &&
                                    <h3>Coming Soon</h3>
                            }

                            {
                                this.state.videos.data &&

                                this.state.videos.data.map((item, i) => {
                                    return (
                                        <div key={i}>
                                            <h4>{item.title}</h4>
                                            <div dangerouslySetInnerHTML={{__html: item.link}} />
                                        </div>
                                    );
                                })
                            }

                            <div className="page">
                                {
                                    this.state.videos.prev_page_url &&
                                    <a href="#" className="btn btn-secondary" onClick={async (e) => {
                                        e.preventDefault();
                                        this._page--;
                                        await this.getHowToVideo();
                                    }}>Previous</a>
                                }
                                {
                                    this.state.videos.next_page_url &&
                                    <a href="#" className="btn btn-secondary" onClick={async (e) => {
                                        e.preventDefault();
                                        this._page++;
                                        await this.getHowToVideo();
                                    }}>Next</a>
                                }
                            </div>

                            </section>

                        </div>
                    </div>


            </>

        );
    }

}

export default HowTo;
