import React from "react";
import AddScripts from "../AddScript";
import {baseUrl, getCategory, getProducts} from "../services/ApiService";

class Shop extends React.Component {

    state = {
        _isMounted: false,
        productList: {data: []},
        categoryList: [],
    }

    addScript = null;
    componentDidMount() {
        document.title = 'Shop To Help | NowPurple Gift Shop';
        document.getElementsByTagName('meta')["description"].content = 'Shop to Help page of NowPurple. Buy products for your daily needs, as well as for your boyfriend, girlfriend or lover and help NowPurple to keep running this platform.';
        document.getElementsByTagName('meta')["keywords"].content = 'best online dating site uk, gifts for boyfriends, gifts for girlfriends, gifts for lovers';

        this.addScript = new AddScripts();

        this.getProducts();
        this.getCategory();
    }

    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };
    }

    getCategory = async () => {
        const category = await getCategory();
        if (category) {
            const bk = this.state;
            bk._isMounted = true;
            bk.categoryList = category;
            this.setState(bk);

            setTimeout(() => {
                this.addScript.createElement('customScript', '/extraJs/ExtraShop.js');
            }, 50);
        }
    }


    selectedCat = null;
    getProducts = async (cat = null, page = 1) => {
        if (!cat) {
            cat = this.selectedCat;
        }
        const product = await getProducts(cat, page);
        if (product) {
            const bk = this.state;
            bk._isMounted = true;
            if (this.selectedCat === cat && page != 1) {
                const data = bk.productList.data.concat(product.data);
                bk.productList = product;
                bk.productList.data = data;
            } else {
                bk.productList = product;

                setTimeout(() => {
                    document.documentElement.scrollTop = 200;
                }, 50);
            }

            this.setState(bk);
        }
        if (cat) {
            this.selectedCat = cat;
        }
    }


    render() {
        return (
            <>

                <div className="page_hero"></div>

                <div className="top_colseable_area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div id="id_show_notice">
                                    <i className="fa fa-question-circle-o"></i>
                                </div>
                                <div className="alert alert-secondary now-alert" role="alert" id="closeable_notice">
                                    Your shopping keeps us going. It is completely safe to purchase from any of our advertisers in our "Shop to help" page. Once you click on a product, you will be redirected to the verified advertiser's website where you can purchase the product. As this is a third party, your bank statement will not contain any references to NowPurple.
                                    <button type="button" className="close" id="remvoe_notice">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section id="shop_help">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="sd_filter_cos">
                                    <div className="filter_title">
                                        <h4 className="text-center">Choose By Categories</h4>
                                    </div>
                                    <div className="category_listing">
                                        <div className="filter_items owl-carousel product_filter" id="product_filter">

                                            {
                                                this.state.categoryList.map((cat, index) => {
                                                    return (
                                                        <button className="category_btn active" title={cat.title} key={index}
                                                        onClick={async (e) => {
                                                            // e.preventDefault();
                                                            await this.getProducts(cat.id, 1);
                                                        }}>{cat.title}</button>
                                                    );
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="product_listing">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    {
                                        this.state.productList.data.map((product, index) => {
                                            return (
                                                <div className="single_product_wrapper col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" key={index}>
                                                    <div className="single_product">
                                                        <a  target="_balnk" href={product.outsite_url}><img src={`${baseUrl}/assets/uploads/product/${product.img}`} alt="Product image" /></a>
                                                        <h6><a target="_balnk" href={product.outsite_url}>{product.title.substring(0, 70)}</a></h6>
                                                        <p>£ {product.price}</p>
                                                        <div className="text-center">
                                                            <a target="_balnk" href={product.outsite_url} className="btn_outline">Details</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>

                                {/*Load more*/}

                                <div className="load_more_pagination text-center">

                                    {
                                        this.state.productList.current_page < this.state.productList.last_page &&
                                        <a href="#" onClick={ async (e) => {
                                            e.preventDefault();
                                            await this.getProducts(null, this.state.productList.current_page + 1);
                                        }} className="load_more">Load More</a>
                                    }

                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <section id={'customScript'}></section>

            </>
        );
    }


}

export default Shop;
