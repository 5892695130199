import React from "react";
import {Link} from "react-router-dom";
import {LoginContext} from "../contexts/LoginContext";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    acceptRejectContact,
    baseUrl,
    cancelRequest, generalSettings, getAppointments,
    getFriendRequests, getNotifications, getPageView, getUserProfile,
    login, notifySettings, setPageView,
    unreadChatMessage, updateNotifySettings, updateUserProfile
} from "../services/ApiService";
import moment from "moment";
import {userInfo} from "../services/CommonService";

class Header extends React.Component {

    static contextType = LoginContext;

    state = {
        _isMounted: false,
        name: '',
        userPassword: '',
        friendRequests: [],
        unreadChat: 0,
        gotRequests: 0,
        generalNotifications: [],
        appointments: [],
        profile: userInfo,
        notifySettings: {}
    }

    delay = 30000;
    socket = null;
    user = null;
    constructor() {
        super();
        toast.configure();
    }

    componentDidMount() {
        const link = document.createElement('link');
        link.href = "/css/font-awesome.min.css";
        link.rel = "stylesheet";
        document.head.appendChild(link);
        const bk = this.state;
        bk._isMounted = true;
        this.setState(bk);
        this.updateNotifications();
        this.notifications();
    }



    unreadMessageCount = async (self = false) => {
        const res = await unreadChatMessage();
        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.unreadChat = res.count;
                this.setState(bk);

                if (!self) {
                    const ucAudio = document.createElement('audio');
                    ucAudio.src = 'audio/message-received.mp3';
                    await ucAudio.play();
                }

            }
        }
    }

    getFriendRequests = async (self = false) => {
        const res = await getFriendRequests();
        if (res) {
            if (res.status) {

                let rfCount = 0;
                res.list.forEach((l) => {
                    if (l.fromUserId !== this.user.id) {
                        rfCount++;
                    }
                });

                const bk = this.state;
                bk.friendRequests = res.list;
                bk.gotRequests = rfCount;
                this.setState(bk);

                this.context.updateFriendRequests(res.list.length);

                if (!self) {
                    const ucAudio = document.createElement('audio');
                    ucAudio.src = 'audio/friend-request.mp3';
                    await ucAudio.play();
                }

            }
        }
    }

    acceptRejectContact = async (contact, status) => {
        const res = await acceptRejectContact({userOne: contact.fromUserId, userTwo: contact.toUserId, status: status});
        if (res) {
            if (res.status) {
                toast.success(res.message, {autoClose: 3000});
                this.socket.emit('update-notification', {from_id: contact.fromUserId, to_id: contact.toUserId});
            } else {
                toast.error(res.error, {autoClose: 3000});
            }
        }
        await this.getFriendRequests(true);
    }

    cancelRequest = async (contact) => {
        const res = await cancelRequest(contact);
        if (res) {
            if (res.status) {
                this.socket.emit('update-notification', {from_id: contact.fromUserId, to_id: contact.toUserId});
                await this.getFriendRequests(true);
            }
        }
    }

    updateNotifications = () => {
        setTimeout(async () => {
            if (this.context.isLoggedIn) {
                this.socket = this.context.socket;
                this.user = JSON.parse(localStorage.getItem('user'));
                this.socket.emit('join', this.user);
                setTimeout(() => {
                    this.socket.on('friend-request', async (data, self) => {
                        await this.getFriendRequests(self);
                    });
                    this.socket.on('chat-notify', async (data, self) => {
                        await this.unreadMessageCount(self)
                    });
                    this.socket.on('update-notification', async (data) => {
                        await this.getFriendRequests(true);
                        await this.unreadMessageCount(true);
                    });
                    this.socket.on('notify', async (data) => {
                        await this.showRealTimeNotifications(data);
                    });
                }, 150);

                await this.getFriendRequests(true);
                await this.unreadMessageCount(true);
                await this.userProfile();
            }
        }, 200);
    }

    userProfile = async () => {
        const res = await getUserProfile();
        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.profile = res.info;
                this.setState(bk);

                const ntySettings = await notifySettings();

                let flag = false;
                if (this.state.profile.availability === '') {
                    flag = true;
                    document.querySelector('.time-select').style.display = 'flex';
                    document.body.style.overflowY = 'hidden';
                }

                if (ntySettings) {
                    if (ntySettings.status) {
                        const bk = this.state;
                        bk.notifySettings = ntySettings.notifySettings
                        this.setState(bk);

                        if (!flag && this.state.notifySettings.collect_info && (this.state.profile.why_here === '' || this.state.profile.bio === '')) {
                            document.body.style.overflowY = 'hidden';
                            document.querySelector('.info-add').style.display = 'flex';
                        }
                    }
                }

            }
        }
    }

    showRealTimeNotifications = async (data) => {
        toast.info(data.message, {autoClose: this.delay});
        const ucAudio = document.createElement('audio');
        ucAudio.src = 'audio/general-notification.mp3';
        await ucAudio.play();
    }

    notifications = () => {

        setTimeout(async () => {
            if (this.context.isLoggedIn) {
                const res = await getNotifications();
                if (res) {
                    if (res.status) {
                        if (res.result.views) {
                            toast.info('You have new profile views', {autoClose: this.delay})
                        }

                        if (res.result.likes) {
                            setTimeout(() => {
                                toast.info('You have new profile likes', {autoClose: this.delay})
                            }, 200);
                        }

                        if (res.result.storyShare) {
                            setTimeout(() => {
                                toast.info('A story is been shared with you', {autoClose: this.delay})
                            }, 250);
                        }

                        if (res.result.schedule) {
                            setTimeout(() => {
                                toast.info('You have an appointment request', {autoClose: this.delay})
                            }, 260);
                        }
                    }
                }
            }
        }, 200);
    }

    updateName = (e) => {
        e.persist()
        this.setState({
            name: e.target.value,
            userPassword: this.state.userPassword
        });
    }

    updatePassword = (e) => {
        e.persist()
        this.setState({
            name: this.state.name,
            userPassword: e.target.value
        });
    }


    submitLogin = async (e) => {
        e.preventDefault();

        const result = await login({name: this.state.name, password: this.state.userPassword});
        const {setLoggedInState} = this.context;

        if (result) {
            setLoggedInState(true);
            window.location.href = '/profile';

        } else {
            toast.error('Wrong credentials', {autoClose: 3000});
        }
    }

    logOut = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('access_token');

        window.location.href = '/';
    }

    hideNotification = () => {
        const bk = this.state;
        delete bk['views'];
        this.setState(bk);
    }

    appointments = async () => {
        const res = await getAppointments();
        if (res) {
            if (res.status && res.appointments.length > 0) {
                const bk = this.state;
                bk.appointments = res.appointments;
                this.setState(bk);
            }
        }
    }

    selectedTime = [];
    selectTimes = (e) => {
        e.persist();
        if (e.target.checked) {
            this.selectedTime.push(e.target.value);
        } else {
            this.selectedTime = this.selectedTime.filter(value => {
               return value !== e.target.value;
            });
        }
    }

    setAvailability = async () => {
        if (this.selectedTime.length === 0) {
            toast.info('Select your most availability time');
            return false;
        }

        const bk = this.state;
        bk.profile.availability = this.selectedTime.join(',');
        this.setState(bk);

        document.querySelector('.cst-modal').style.display = 'none';
        await this.updateProfile();

        if (this.state.notifySettings.collect_info && (this.state.profile.why_here === '' || this.state.profile.bio === '')) {
            document.body.style.overflowY = 'hidden';
            document.querySelector('.info-add').style.display = 'flex';
        }
    }

    whyHere = '';
    selectWhyHere = (e) => {
        e.persist();
        if (e.target.id === 'option5') {
            document.querySelector('.why-here').removeAttribute('disabled');
        } else {
            document.querySelector('.why-here').setAttribute('disabled', 'disabled');
            e.persist();
            this.whyHere = e.target.value;
        }
    }

    bio = '';
    getTextAreaData = (e) => {
        e.persist();

        if (e.target.name === 'why_here') {
            this.whyHere = e.target.value;
        } else {
            this.bio = e.target.value;
        }

    }

    updateProfile = async () => {
        document.querySelector('.info-add').style.display = 'none';

        const bk = this.state;
        if (bk.profile.why_here === '') {
            bk.profile.why_here = this.whyHere;
        }
        if (bk.profile.bio === '') {
            bk.profile.bio = this.bio;
        }
        this.setState(bk);

        const data = this.state.profile;

        const result = await updateUserProfile(data);

        document.body.style.overflowY = 'auto';
        if (result) {
            if (result.status) {
                toast.success(result.message);
            } else {
                toast.error(result.error);
            }
        }
    }

    showOrNot = async (e) => {
        e.persist();
        let setting = {};
        if (e.target.checked) {
            setting['collect_info'] = 'unchecked';
        } else {
            setting['collect_info'] = 'checked';
        }
        const res = await updateNotifySettings(setting);
        if (res) {
            if (res.status) {
                toast.success(res.message);
            } else {
                toast.error(res.error);
            }
        }
    }

    commonSettings = async () => {
        const res = await generalSettings();
        if (res) {

        }
    }

    render() {
        const {isLoggedIn} = this.context;

        return (
            <>

                <header id="main_header">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <nav className="navbar navbar-expand-lg justify-content-between d-none d-lg-flex d-xl-flex">
                                    <Link className="navbar-brand" to={"/"}>
                                        <img src="/images/NowPurple.png" alt="Now Purple Logo" />
                                    </Link>
                                    <div className="mainMenu">
                                        <ul className="navbar-nav">
                                            <li className="nav-item active">
                                                <Link className="nav-link" to={"/"}>Home</Link>
                                            </li>
                                            <li className="nav-item active">
                                                <Link className="nav-link" to={"/find-friends"}>Find Friends</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to={"/story"}>Stories</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to={'/faq'} className="nav-link" href="#">Faq</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to={'/shop'} className="nav-link" href="#">Shop to help</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="right_menu position-relative">
                                        <ul className="navbar-nav">
                                            {   !isLoggedIn ?
                                                <li className="nav-item active mr-3">
                                                    <a className="nav-link btn_purple btn nav-btn" href="#" id="tigger_login">
                                                        <i className="fa fa-sign-in" aria-hidden="true"></i> Login</a>
                                                </li>
                                                :
                                                <>

                                                    <li className="nav-item mr-3">
                                                        <Link className="nav-link btn_pink btn nav-btn" to={"/profile"}>
                                                        <i className="fa fa-user"></i> Account</Link>
                                                    </li>
                                                    <li className="nav-item mr-3">
                                                        <Link className="nav-link btn_purple btn nav-btn" to={"#"} onClick={this.logOut}>
                                                        <i className="fa fa-sign-out" aria-hidden="true"></i> Logout</Link>
                                                    </li>
                                                </>
                                            }
                                            <div className="login_area">
                                                <div className="icon">
                                                    <i className="fa fa-caret-up"></i>
                                                </div>
                                                <form onSubmit={this.submitLogin}>
                                                    <div className="form-group mt-2">
                                                        <input type="text" className="form-control"
                                                               placeholder="User Name" value={this.state.name} onChange={this.updateName} />
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="password" className="form-control"
                                                               placeholder="password" value={this.state.userPassword} onChange={this.updatePassword} />
                                                    </div>
                                                    <button type="submit" className="now_search">Login</button>
                                                    <div className={'forget-pass'} >
                                                        <a href={'/forget-password'} >Forget Password</a>
                                                    </div>
                                                </form>
                                            </div>

                                        </ul>
                                    </div>
                                </nav>

                                {
                                    isLoggedIn &&
                                    <>
                                        {
                                            this.state.appointments.length > 0 &&
                                            <div className={'info-fixed'}>
                                                <div>
                                                    {
                                                        this.state.appointments.map((app, i) => {
                                                            return (
                                                                <p key={i}>{moment(app.date).format("lll")}</p>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </>
                                }

                                 {/*Mobile Menu */}

                                <div className="mobile_menu_waper d-flex d-lg-none d-xl-none justify-content-between">
                                    <div className="logo_waper">
                                        <Link to="/">
                                            <img src="/images/NowPurple.png" alt="Site logo" />
                                        </Link>
                                    </div>

                                    {
                                        isLoggedIn &&
                                        <div className={'mobile-notification-icons'}>
                                            <Link to={'/chat'}>
                                                <div className={'notification-uc'}>
                                                    <i className="fa fa-commenting-o" aria-hidden="true"></i>
                                                    {
                                                        this.state.unreadChat > 0 &&
                                                        <div className="notify">
                                                            <span className="heartbit"></span>
                                                            <span className="point"></span>
                                                        </div>
                                                    }
                                                </div>
                                            </Link>

                                            <div className={'notification-fr'} data-toggle="modal" data-target="#fr">
                                                {/*<i className="fa fa-bell-o" aria-hidden="true"></i>*/}
                                                <i className="fa fa-user-o" aria-hidden="true"></i>
                                                {
                                                    this.state.gotRequests > 0 &&

                                                    <div className="notify">
                                                        <span className="heartbit"></span>
                                                        <span className="point"></span>
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    }

                                    {
                                        !isLoggedIn &&
                                            <div className='mobile-login'>
                                                <a href="#" id="tigger_login_mobile" className="btn nav-btn">
                                                    <i className="fa fa-sign-in" aria-hidden="true"></i> Login</a>

                                                <div className="login_area_mobile">
                                                    <form onSubmit={this.submitLogin}>
                                                        <div className="form-group">
                                                            <input type="text" className="form-control"
                                                                   placeholder="User Name" value={this.state.name} onChange={this.updateName} />
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="password" className="form-control"
                                                                   placeholder="password" value={this.state.userPassword} onChange={this.updatePassword} />
                                                        </div>
                                                        <button type="submit" className="now_search">Login</button>
                                                        <button type="button" className="now_search" onClick={() => {
                                                            document.querySelector('.login_area_mobile').style.display = 'none';
                                                        }}>Cancel</button>
                                                        <div className={'forget-pass'} >
                                                            <a href={'/forget-password'} >Forget Password</a>
                                                        </div>
                                                    </form>
                                                </div>

                                            </div>

                                    }

                                    <div className="button_container" id="toggle">
                                        <span className="top"></span>
                                        <span className="middle"></span>
                                        <span className="bottom"></span>
                                    </div>
                                </div>

                                <div className="overlay" id="overlay">
                                    <nav className="overlay-menu">
                                        <ul>
                                            <li><Link to={"/"}>Home</Link></li>
                                            <li><Link className="nav-link" to={"/find-friends"}>Find Friends</Link></li>
                                            <li><Link to={"/blog"}>Blogs</Link></li>
                                            <li><a href="/#why-us">Why us</a></li>
                                            <li><Link to={'/shop'} >Shop to help</Link></li>
                                            <li><Link to={'/faq'} >Faq</Link></li>
                                                {
                                                    isLoggedIn &&

                                                        <>
                                                            <li>
                                                                <Link className="nav-link btn_pink btn nav-btn" to={"/profile"}>
                                                                <i className="fa fa-user"></i> Account</Link>
                                                            </li>
                                                            <li>
                                                                <Link className="nav-link btn_purple btn nav-btn" to={"#"} onClick={this.logOut}>
                                                                <i className="fa fa-sign-out" aria-hidden="true"></i> Logout</Link>
                                                            </li>
                                                        </>

                                                }

                                            {/*<li id="show_mobile_drop"><a href="#"><i*/}
                                            {/*    className="fa fa-globe"></i> English </a>*/}
                                            {/*    <ul className="drop_mobile">*/}
                                            {/*        <li><a href="#">Germany</a></li>*/}
                                            {/*        <li><a href="#">Bengali</a></li>*/}
                                            {/*    </ul>*/}
                                            {/*</li>*/}
                                        </ul>
                                    </nav>
                                </div>

                            </div>
                        </div>
                    </div>

                    {
                        isLoggedIn &&
                        <div className="nav-item mr-3 notification-icons">
                            <Link to={'/chat'}>
                                <div className={'notification-uc'}>
                                    <i className="fa fa-commenting-o" aria-hidden="true"></i>
                                    {
                                        this.state.unreadChat > 0 &&
                                        <div className="notify">
                                            <span className="heartbit"></span>
                                            <span className="point"></span>
                                        </div>
                                    }
                                </div>
                            </Link>
                            <div className={'notification-fr'} data-toggle="modal" data-target="#fr">
                                {/*<i className="fa fa-bell-o" aria-hidden="true"></i>*/}
                                <i className="fa fa-user-o" aria-hidden="true"></i>
                                {
                                    this.state.gotRequests > 0 &&
                                    <span className="fr-num">{this.state.gotRequests}</span>
                                }
                            </div>

                        </div>
                    }

                    <div className={'mobile-fixed'}>
                        <Link className="nav-link story-menu" to={"/story"}>
                            <span>Stories</span>
                            <i className="fa fa-newspaper-o" aria-hidden="true"></i>
                        </Link>
                    </div>


                    {

                        isLoggedIn &&
                        <div className={'cst-modal time-select'}>
                            <div className="card">
                                <div className="card-header">
                                    <p>What time of day you are usually online?</p>
                                    <p className='small_font'>Please let us know, as this information will increase your profile visibility.</p>
                                </div>
                                <div className="card-body">

                                    <div className={'times'}>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="time1" value="07 am - 08 am"
                                                   onChange={this.selectTimes} />
                                                <label className="form-check-label" htmlFor="time1">07 am - 08 am</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="time2" value="08 am - 09 am"
                                                   onChange={this.selectTimes} />
                                                <label className="form-check-label" htmlFor="time2">08 am - 09 am</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="time3" value="09 am - 10 am"
                                                   onChange={this.selectTimes} />
                                                <label className="form-check-label" htmlFor="time3">09 am - 10 am</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="time4" value="10 am - 11 am"
                                                   onChange={this.selectTimes} />
                                                <label className="form-check-label" htmlFor="time4">10 am - 11 am</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="time5" value="11 am - 12 pm"
                                                   onChange={this.selectTimes} />
                                                <label className="form-check-label" htmlFor="time5">11 am - 12 pm</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="time6" value="12 pm - 01 pm"
                                                   onChange={this.selectTimes} />
                                                <label className="form-check-label" htmlFor="time6">12 pm - 01 pm</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="time7" value="01 pm - 02 pm"
                                                   onChange={this.selectTimes} />
                                                <label className="form-check-label" htmlFor="time7">01 pm - 02 pm</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="time8" value="02 pm - 03 pm"
                                                   onChange={this.selectTimes} />
                                                <label className="form-check-label" htmlFor="time8">02 pm - 03 pm</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="time9" value="03 pm - 04 pm"
                                                   onChange={this.selectTimes} />
                                                <label className="form-check-label" htmlFor="time9">03 pm - 04 pm</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="time10" value="04 pm - 05 pm"
                                                   onChange={this.selectTimes} />
                                                <label className="form-check-label" htmlFor="time10">04 pm - 05 pm</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="time11" value="05 pm - 06 pm"
                                                   onChange={this.selectTimes} />
                                                <label className="form-check-label" htmlFor="time11">05 pm - 06 pm</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="time12" value="06 pm - 07 pm"
                                                   onChange={this.selectTimes} />
                                                <label className="form-check-label" htmlFor="time12">06 pm - 07 pm</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="time13" value="07 pm - 08 pm"
                                                   onChange={this.selectTimes} />
                                                <label className="form-check-label" htmlFor="time13">07 pm - 08 pm</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="time14" value="08 pm - 09 pm"
                                                   onChange={this.selectTimes} />
                                                <label className="form-check-label" htmlFor="time14">08 pm - 09 pm</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="time15" value="09 pm - 10 pm"
                                                   onChange={this.selectTimes} />
                                                <label className="form-check-label" htmlFor="time15">09 pm - 10 pm</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="time16" value="10 pm - 11 pm"
                                                   onChange={this.selectTimes} />
                                                <label className="form-check-label" htmlFor="time16">10 pm - 11 pm</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="time17" value="11 pm - 12 am"
                                                   onChange={this.selectTimes} />
                                                <label className="form-check-label" htmlFor="time17">11 pm - 12 am</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="time18" value="12 am - 01 am"
                                                   onChange={this.selectTimes} />
                                                <label className="form-check-label" htmlFor="time18">12 am - 01 am</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="time19" value="01 am - 03 am"
                                                   onChange={this.selectTimes} />
                                                <label className="form-check-label" htmlFor="time19">01 am - 03 am</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="time20" value="03 am - 05 am"
                                                   onChange={this.selectTimes} />
                                                <label className="form-check-label" htmlFor="time20">03 am - 05 am</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="time21" value="05 am - 07 am"
                                                   onChange={this.selectTimes} />
                                                <label className="form-check-label" htmlFor="time21">05 am - 07 am</label>
                                        </div>
                                    </div>

                                    <button className="btn btn-sm btn_purple_mid_light" onClick={ this.setAvailability }>Set Availability</button>
                                </div>
                            </div>
                        </div>

                    }


                    {

                        isLoggedIn &&
                        <div className={'cst-modal info-add'}>

                            <div className="card">
                                <div className="card-header">
                                    <p>Tell us a bit about yourself!</p>
                                    <p className='small_font'>Please let us know, as this information will increase your profile credibility</p>
                                </div>
                                <div className="card-body">
                                    {
                                        this.state.profile.why_here === '' &&
                                        <>
                                            <p className='card-text'>Why are you here?</p>

                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="exampleRadios"
                                                       id="option1" value="I am looking for a relationship"
                                                       onChange={this.selectWhyHere}/>
                                                <label className="form-check-label" htmlFor="option1">
                                                    I am looking for a relationship
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="exampleRadios"
                                                       id="option2" value="I am looking for someone to talk to"
                                                       onChange={this.selectWhyHere}/>
                                                <label className="form-check-label" htmlFor="option2">
                                                    I am looking for someone to talk to
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="exampleRadios"
                                                       id="option3" value="I am looking for a good friend"
                                                       onChange={this.selectWhyHere}/>
                                                <label className="form-check-label" htmlFor="option3">
                                                    I am looking for a good friend
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="exampleRadios"
                                                       id="option4" value="I am looking for more than a friendship"
                                                       onChange={this.selectWhyHere}/>
                                                <label className="form-check-label" htmlFor="option4">
                                                    I am looking for more than a friendship
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="exampleRadios"
                                                       id="option5" value="custom" onChange={this.selectWhyHere}/>
                                                <label className="form-check-label" htmlFor="option5">
                                                    I have another reason:
                                                </label>
                                            </div>

                                            <textarea className={'why-here'} rows={3} disabled name={'why_here'}
                                                      onChange={this.getTextAreaData} ></textarea>
                                        </>
                                    }

                                    {
                                        this.state.profile.bio === '' &&
                                        <>
                                            <p className='card-text'>Bio</p>

                                            <textarea rows={3} name={'bio'}
                                                      onChange={this.getTextAreaData} ></textarea>
                                            <p className='small_font'>words limit 160</p>
                                        </>
                                    }

                                    <div className={'info-action'}>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="dont-show"
                                                    onChange={this.showOrNot} />
                                            <label className="form-check-label" htmlFor="dont-show">Do not show again</label>
                                        </div>
                                        <div>
                                            <button className="btn btn-sm btn_pink" onClick={() => {
                                                document.body.style.overflowY = 'auto';
                                                document.querySelector('.info-add').style.display = 'none';
                                            }}>Skip</button>
                                            <button className="btn btn-sm btn_purple_mid_light" onClick={ this.updateProfile }>Update Info</button>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    }


                </header>

                <div className="modal fade modal_fr" id="fr" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body p-4">
                                <div className="modal_waper_main bg-white">
                                    <div className="modal_header">
                                        <h4 className="purple_color">Request List</h4>
                                    </div>
                                </div>
                                <div className="modal_body">
                                    {
                                        this.state.friendRequests.length == 0
                                            ?

                                            <h3 className={'empty-fr'}>No Friend Request Available</h3>

                                            :

                                            this.state.friendRequests.map((fr, index) => {

                                                const self = fr.fromUserId === this.user.id;
                                                const name = self ? fr.toUserName : fr.fromUserName;
                                                const gender = self ? fr.toUserGender : fr.fromUserGender;

                                                return (

                                                    <div className='fr' key={index}>
                                                        <div className="user_info_sum d-flex align-items-center">
                                                            <div className="profile_img">

                                                                {
                                                                    fr.img &&

                                                                    <img src={`${baseUrl}/avatar/${fr.img}`} alt=""/>

                                                                }

                                                                {
                                                                    !fr.img && gender.toLowerCase() === 'male' &&
                                                                    <img src={`${baseUrl}/avatar/avatar - 09-01.png`} alt=""/>
                                                                }

                                                                {
                                                                    !fr.img && gender.toLowerCase() === 'female' &&
                                                                    <img src={`${baseUrl}/avatar/avatar - 14-01.png`} alt=""/>
                                                                }

                                                                {
                                                                    !fr.img && gender.toLowerCase() !== 'male' && gender.toLowerCase() !== 'female' &&
                                                                    <img src={`${baseUrl}/avatar/avatar-2877.png`} alt=""/>
                                                                }

                                                            </div>
                                                            <div className="details">
                                                                <p className="purple_color font-weight-bold text-uppercase">{name}</p>
                                                                <p className="small_font">{fr.city}, {fr.country}</p>
                                                                <p className="small_font"><span
                                                                    className="purple_color">Age:</span> {fr.age} | <span
                                                                    className="pink_color">Post Code:</span> {fr.postCode}</p>

                                                                <div className={'action-btn'}>
                                                                    {
                                                                        self ?

                                                                            <a href="#" className="btn btn-sm btn-danger"
                                                                               onClick={(e) => {
                                                                                   e.preventDefault();
                                                                                   this.cancelRequest(fr);
                                                                               }}
                                                                            >Cancel Request</a>

                                                                            :

                                                                            <>

                                                                                <a href="#" className="btn btn-sm btn-primary"
                                                                                   onClick={async (e) => {
                                                                                       e.preventDefault();
                                                                                       await this.acceptRejectContact(fr, 1);
                                                                                   }}
                                                                                >Accept Request</a>
                                                                                <a href="#" className="btn btn-sm btn-danger"
                                                                                   onClick={async (e) => {
                                                                                       e.preventDefault();
                                                                                       await this.acceptRejectContact(fr, 0);
                                                                                   }}
                                                                                >Reject Request</a>

                                                                            </>
                                                                    }

                                                                    <Link to={`/profile/${name}`} className="btn btn-sm btn-info" target={'_blank'}>View Profile</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                );


                                            })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </>
        );
    }
}

export default Header;
