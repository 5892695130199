import React from "react";
import "../pageCss/story.css";
import {
    adsGetRandProducts,
    baseUrl, blogs,
    createStory, deleteComment, deleteStory, editStory,
    getComments, getFriends,
    getStories, getStoriesUnReg, postBlogAction,
    postComment, shareStory,
    storyAction
} from "../services/ApiService";
import {LoginContext} from "../contexts/LoginContext";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";
import moment from "moment";
import {userInfo} from "../services/CommonService";

class Blog extends React.Component {
    static contextType = LoginContext;

    state = {
        _isMounted: false,
        user: userInfo,
        friendList: [],
        stories: {data: [], next: null, prev: null},
        blogs: {data: [], next_page_url: null, prev_page_url: null},
        topStories: {data: [], next: null, prev: null},
        timeline: {data: [], next: null, prev: null},
        fullStory: {},
        loading: true,
    }

    socket = null;
    componentDidMount() {
        document.title = 'Blogs | NowPurple | Dating Blogs UK';
        document.getElementsByTagName('meta')["description"].content = 'Blogs page of NowPurple. It\'s a UK based 100% free dating website where the blog function allows users to share their experiences with others.';
        document.getElementsByTagName('meta')["keywords"].content = 'dating blogs uk, totally free dating sites uk';

        const bk = this.state;
        bk.user = JSON.parse(localStorage.getItem('user'));
        bk._isMounted = true;
        this.setState(bk);
        this.restCall();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        }
    }

    restCall = async () => {
        if (this.state._isMounted) {
            await this.blogs();
            await this.adsGetProducts();
            await this.getFriends();
            await this.setScrollEvent();
        }
    }

    getFriends = async () => {
        const res = await getFriends();
        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.friendList = res.list;
                this.setState(bk);
            }
        }
    }

    scrollTopStory = 0;
    setScrollEvent = async () => {
        window.addEventListener('scroll', async (e) => {
            if (this.state.blogs.next_page_url) {
                console.log('in');
                if (document.documentElement.scrollTop > this.scrollTopStory && !this.state.loading) {
                    if (document.documentElement.scrollTop - this.scrollTopStory >= 400) {
                        this.scrollTopStory = document.documentElement.scrollTop;
                        console.log(this.state.blogs.current_page);
                        await this.blogs(++this.state.blogs.current_page);

                    }
                }
            }
        });
    }

    adsGetProducts = async () => {
        const res = await adsGetRandProducts(10);
        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.adProducts = res.products;
                this.setState(bk);
            }
        }
    }

    blogs = async (page = 1) => {

        const res = await blogs(page);

        if (res) {
            const bk = this.state;
            if (res.status) {
                bk.blogs.data = bk.blogs.data.concat(res.blogs.data);
                bk.blogs.next_page_url = res.blogs.next_page_url;
                bk.blogs.prev_page_url = res.blogs.prev_page_url;
                bk.blogs.current_page = res.blogs.current_page;
            }
            this.setState(bk);
            setTimeout(() => {
                bk.loading = false;
                this.setState(bk);
            }, 400);
        }
    }


    likeOrLove = async (data) => {
        if (!this.context.isLoggedIn) {
            toast.warning('Login to interact');
            return false;
        }

        const res = await postBlogAction(data);
        if (res) {
            if (res.status) {

                const bk = this.state;

                for(let dt in bk.blogs.data) {
                    if (bk.blogs.data[dt].id === data.blogId) {
                        bk.blogs.data[dt].likes = res.blog.likes;
                    }
                }

                this.setState(bk);
            }
        }
    }

    render() {

        const {isLoggedIn} = this.context;

        return(
            <>

                <div className="page_hero"></div>

                <section id="story-container">
                    <div className="container">
                        <div className="row">

                            <div className="col-12 col-md-12 col-lg-9 col-xl-9">

                                <div className="" >

                                    {
                                        this.state.loading &&

                                        <div className="spinner-border spinner-color" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    }

                                    {
                                        this.state.blogs.data.length === 0 && !this.state.loading &&

                                        <h4 className={'empty-stories'}>Coming Soon......</h4>
                                    }

                                    {
                                        this.state.blogs.data.map((post, i) => {
                                            const postUrl = 'blog/' + post.slug.replaceAll(' ', '-');
                                            const summaryText = post.summary + "<a href='" + postUrl + "'>... Read More</Link>";

                                           return(
                                               <div className={'story'} key={i} >

                                                   <div className={'story-author'}>

                                                       <div className={'profile_info'}>
                                                           <h2 className="text-uppercase">
                                                               <Link to={postUrl}>{post.title}</Link>
                                                           </h2>
                                                           <p className="small_font text-uppercase">{moment(post.created_at).local(true).format('lll')}</p>
                                                       </div>

                                                   </div>

                                                   <div className={'shared-story'} >

                                                       {
                                                           post.summary &&
                                                           <p dangerouslySetInnerHTML={{__html: summaryText}}></p>
                                                       }

                                                   </div>

                                                   <div className={'story-footer blog'}>
                                                       <div>
                                                           <span onClick={ async () => {
                                                               await this.likeOrLove({blogId: post.id, liked: 1});
                                                           }} >({post.likes})<i className="fa fa-thumbs-up purple_color" aria-hidden="true" ></i></span>
                                                           <span>({post.views})<i className="fa fa-eye purple_color" aria-hidden="true" ></i></span>
                                                       </div>

                                                       <div>
                                                           <p className="cur-pointer" >
                                                               <i className="fa fa-caret-down mr-1" aria-hidden="true"> </i>
                                                               comments <span>({post.comments})</span></p>
                                                       </div>
                                                   </div>

                                               </div>
                                           );
                                        })
                                    }

                                </div>

                            </div>


                            <div className="col-12 col-md-12 col-lg-3 col-xl-3">
                                <div className="profile_listing_right">

                                    {
                                        <>
                                            {
                                                this.state.adProducts &&

                                                    this.state.adProducts.map((product, index) => {
                                                        return (
                                                            <div className="single_product sidebar_product" key={index}>
                                                                <a target="_balnk" href={product.outsite_url}>
                                                                    <img src={`${baseUrl}/assets/uploads/adsproduct/${product.img}`} alt="Product image" />
                                                                </a>
                                                            </div>
                                                        );
                                                    })

                                            }
                                        </>
                                    }

                                </div>
                            </div>


                        </div>
                    </div>
                </section>


            </>
        );
    }

}

export default Blog;
