import React from "react";
import {
    acceptRejectContact,
    adsGetCategory,
    adsGetProducts, adsGetRandProducts,
    baseUrl, cancelRequest,
    getChatMessages, getCities,
    getContacts, getCountries, getOnlineUsers,
    sendFriendRequest
} from "../services/ApiService";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddScripts from "../AddScript";
import "../pageCss/find-contact.css";
import {Link} from "react-router-dom";
import {userInfo} from "../services/CommonService";
import {LoginContext} from "../contexts/LoginContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

class FindContacts extends React.Component {

    static contextType = LoginContext;

    socket = null;
    state = {
        _isMounted: false,
        user: userInfo,
        contacts: [],
        selectedContact: {},
        adsCategoryList: [],
        countryList: [],
        cityList: [],
        loading: true,
        filter: {age: '', gender: '', country: '', city: '', postCode: '', notShowFriends: 0, page: 1},
        selectDate: new Date(),
        selectedDates: [],
    }

    componentDidMount() {
        document.title = 'Find Friends | NowPurple | Completely Free Dating Site UK';
        document.getElementsByTagName('meta')["description"].content = 'Find Friends page of NowPurple (100% free UK dating site) where all NowPurple users are listed. Find your loved one or get discovered.';
        document.getElementsByTagName('meta')["keywords"].content = 'completely free dating sites uk';

        const addScripts = new AddScripts();
        addScripts.createElement('customScript', '/extraJs/ExtraFindContact.js');
        const user = localStorage.getItem('user');
        const bk = this.state;
        bk._isMounted = true;
        bk.user = JSON.parse(user);
        this.setState(bk);

        this.socket = this.context.socket;

        this.getContacts();
        this.adsGetCategory();
        this.adsGetProducts();
        this.getCountries();
    }

    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };
    }

    getCountries = async () => {
        const res = await getCountries();
        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.countryList = res.list;
                this.setState(bk);
            }
        }
    }

    getCities = async (countryId) => {
        const res = await getCities(countryId);
        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.cityList = res.list;
                this.setState(bk);
            }
        }
    }

    limit = 10;
    getContacts = async (filter = {}) => {
        const bk = this.state;
        bk.loading = true;
        filter.limit = this.limit;

        const res = await getContacts(filter);
        if (res) {
            if (res.status) {
                bk.contacts = res.list;
                this.setState(bk);

                this.socket.emit('online-users', {userId: this.state.user.id, list: res.list});
                this.socket.on('online-users', list => {
                    setTimeout(() => {
                        const bk = this.state;
                        bk.contacts = list;
                        this.setState(bk);
                        document.documentElement.scrollTop = 100;
                    }, 500);
                });

            }
        }
        setTimeout(() => {
            bk.loading = false;
            this.setState(bk);
        }, 500);
    }

    message = '';
    showSchedule = false;
    addContact = async (contact) => {
        if (contact) {
            const bk = this.state;
            bk.selectedContact = contact;
            bk.selectedDates = [];
            this.setState(bk);
        }
    }

    sendRequest = async (status) => {
        if (status && this.state.selectedContact.name !== '') {
            const res = await sendFriendRequest({fromUserId: this.state.user.id, toUserId: this.state.selectedContact.id, message: this.message, schedule: this.state.selectedDates.join(',')});

            if (res) {
                if (res.status) {
                    toast.info(res.message, {autoClose: 3000});
                    this.socket.emit('friend-request', {from_id: this.state.user.id, to_id: this.state.selectedContact.id});
                    await this.getContacts(this.state.filter);
                }
            }
        } else {
            const bk = this.state;
            bk.selectedContact = {};
            this.setState(bk);
        }
    }

    acceptRejectContact = async (contact, status) => {
        const res = await acceptRejectContact({userOne: contact.id, userTwo: this.state.user.id, status: status});
        if (res) {
            if (res.status) {
                toast.success(res.message, {autoClose: 3000});
                this.socket.emit('update-notification', {from_id: this.state.user.id, to_id: contact.id});

            } else {
                toast.error(res.error, {autoClose: 3000});
            }
        }
        await this.getContacts(this.state.filter);
    }

    cancelRequest = async (contact) => {
        const res = await cancelRequest({toUserId: contact.id});
        if (res) {
            if (res.status) {
                toast.success('Friend request canceled', {autoClose: 3000});
                this.socket.emit('update-notification', {from_id: this.state.user.id, to_id: contact.id});
                await this.getContacts(this.state.filter);
            }
        }
    }

    adsGetCategory = async () => {
        const category = await adsGetCategory();
        if (category) {
            const bk = this.state;
            bk._isMounted = true;
            bk.adsCategoryList = category;
            this.setState(bk);
        }
    }


    adsGetProducts = async () => {
        const adsProduct = await adsGetRandProducts(15);
        if (adsProduct) {
            if (adsProduct.status) {
                const bk = this.state;
                bk._isMounted = true;
                bk.adsProductList = adsProduct.products;
                this.setState(bk);
            }
        }
    }

    updateValue = (e) => {
        e.persist();
        const bk = this.state;
        bk.filter[e.target.name] = e.target.value;
        this.setState(bk);

        if (e.target.name === 'country') {
            this.getCities(e.target.value);
        }

    }

    getFilteredList = async (e) => {
        e.preventDefault();
        const bk = this.state;
        bk.filter.page = 1;
        this.setState(bk);
        await this.getContacts(this.state.filter);

    }

    prevPage = async () => {
        const bk = this.state;
        if (bk.filter.page > 1) {
            bk.filter.page = bk.filter.page - 1;
            this.setState(bk);

            await this.getContacts(this.state.filter);
        }
    }

    nextPage = async () => {
        const bk = this.state;
        bk.filter.page = bk.filter.page + 1;
        this.setState(bk);

        await this.getContacts(this.state.filter);
    }

    showFriends = async (e) => {
        e.persist();
        const bk = this.state;
        bk.filter.page = 1;
        console.log(e.target.checked);
        if (e.target.checked) {
            bk.filter.notShowFriends = 0;
        } else {
            bk.filter.notShowFriends = 1;
        }
        this.setState(bk);
        await this.getContacts(this.state.filter);
    }

    curDate = null;
    setDate = (date) => {
        const bk = this.state;
        bk.selectDate = date;
        this.setState(bk);
        this.curDate = moment(date).format('Y-MM-DD kk:mm:ss');
    }

    addDate = () => {
        if (!this.curDate) {
            return false;
        }
        const bk = this.state;
        if (bk.selectedDates.length === 0) {
            bk.selectedDates.push(this.curDate);
        } else {
            if (bk.selectedDates.findIndex((d) => d === this.curDate) < 0) {
                bk.selectedDates.push(this.curDate);
            }
        }
        this.setState(bk);
        console.log(this.state.selectedDates);
    }

    render() {
        let infoMessage = `You may be surprised that you cannot see any photos here. This is a NowPurple policy decision. You will be able to see the photos of members only once they approve your friend request and share their gallery with you. At NowPurple we care about your privacy.`;
        if (this.state.user.block_duration) {
            infoMessage = `Hey, sorry, but we had to block your account for 15 min as you avoided 7 people. To avoid being blocked in the future, please remember our house rules. One of our rules is that if you accept someone’s friend request, then kindly talk with them for at least 5 min to know more about this person or let the person know about you. Therefore before accepting someone’s request, please see their profile.`;
        }else if (this.state.user.block_status) {
            infoMessage = `Your account has been blocked, as you got blocked by users 20 times or you blocked 10 users just in a day. If you think our decision was wrong, please contact us.`;
        }

        return (
            <>

                <div className="page_hero"></div>

                <div className="top_colseable_area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div id="id_show_notice">
                                    <i className="fa fa-question-circle-o"></i>
                                </div>
                                <div className="alert alert-secondary now-alert" role="alert" id="closeable_notice">
                                    { infoMessage }
                                    <button type="button" className="close" id="remvoe_notice">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section id="profile_listing">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-9 col-xl-9">

                                {/*Filter area*/}
                                <div className="header_filter">
                                    <form onSubmit={this.getFilteredList}>
                                        {/*Flex waper*/}
                                        <div className="d-block d-md-flex d-lg-flex d-xl-flex flex-column align-items-center">
                                            <div className={'filter-group'}>
                                                <div>
                                                    <div className="form-group mr-1 filter-input">
                                                        <label htmlFor="age">User Name</label>
                                                        <input className="form-control" id="name" name={'name'}
                                                               placeholder={'user name'} onChange={this.updateValue} />
                                                    </div>
                                                    <div className="form-group mr-1 filter-input">
                                                        <label htmlFor="age">Age</label>
                                                        <select className="form-control" id="age" name={'age'} onChange={this.updateValue}>
                                                            <option value={''}>Select Age</option>
                                                            <option value={'18-25'}>18 - 25</option>
                                                            <option value={'25-35'}>25 - 35</option>
                                                            <option value={'35-45'}>35 - 45</option>
                                                            <option value={'45-55'}>45 - 55</option>
                                                            <option value={'55-75'}>55 - 75</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group mr-1 filter-input">
                                                        <label htmlFor="gender">Gender</label>
                                                        <select className="form-control" id="gender" name={'gender'} onChange={this.updateValue}>
                                                            <option value=''>Select Gender</option>
                                                            <option value={'Male'}>Male</option>
                                                            <option value={'Female'}>Female</option>
                                                            <option value={'Agender'}>Agender</option>
                                                            <option value={'Androgynous'}>Androgynous</option>
                                                            <option value={'Bi Gender'}>Bi gender</option>
                                                            <option value={'Gender Fluid'}>Gender Fluid</option>
                                                            <option value={'Gender Monconforming'}>Gender Nonconforming</option>
                                                            <option value={'Gender Questioning'}>Gender Questioning</option>
                                                            <option value={'Gender Variant'}>Gender Variant</option>
                                                            <option value={'Gender Queer'}>Genderqueer</option>
                                                            <option value={'Intersex'}>Intersex</option>
                                                            <option value={'Neutrois'}>Neutrois</option>
                                                            <option value={'Non Binary'}>Non-binary</option>
                                                            <option value={'Pan Gender'}>Pangender</option>
                                                            <option value={'Queer'}>Queer</option>
                                                            <option value={'Trans Feminine'}>Transfeminine</option>
                                                            <option value={'Transgender'}>Transgender</option>
                                                            <option value={'Transgender Female To Male'}>Transgender Female to Male</option>
                                                            <option value={'Transgender Male To Female'}>Transgender Male to Female</option>
                                                            <option value={'Transgender Person'}>Transgender Person</option>
                                                            <option value={'Trans Masculine'}>Transmasculine</option>
                                                            <option value={'Transsexual Female'}>Transsexual Female</option>
                                                            <option value={'Transsexual Male'}>Transsexual Male</option>
                                                            <option value={'Transsexual Person'}>Transsexual Person</option>
                                                            <option value={'Two Spirit'}>Two-Spirit</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="form-group mr-1 filter-input">
                                                        <label htmlFor="country">Country</label>
                                                        <select className="form-control" id="country" name={'country'} onChange={this.updateValue}>
                                                            <option value={0} >Select Country</option>
                                                            {
                                                                this.state.countryList.map((country, index) => {
                                                                    return (

                                                                        <option value={country.id} key={index}>{country.name}</option>

                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group mr-1 filter-input">
                                                        <label htmlFor="city">City</label>
                                                        <select className="form-control" id="city" name={'city'} onChange={this.updateValue}>
                                                            <option value={0} >Select City</option>
                                                            {
                                                                this.state.cityList.map((city, index) => {
                                                                    return (

                                                                        <option value={city.id} key={index}>{city.name}</option>

                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group mr-1 filter-input">
                                                        <label htmlFor="post">Post Code</label>
                                                        <input type="text" className="form-control" id="post" name={'postCode'}
                                                               placeholder="E149fq" onChange={this.updateValue}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" className="btn now_search">
                                                <i className="fa fa-search" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>

                                <div className={'extra-filter'}>
                                    <div className={'ck-box'}>
                                        <div className="toggle-switch">
                                            <div>
                                                <input type="checkbox" className={'input-toggle'}
                                                       checked={ parseInt(this.state.filter.notShowFriends) === 0 || false}
                                                       id={'show-friends'}
                                                       onChange={this.showFriends} />
                                                <label htmlFor='show-friends'>
                                                    <div>
                                                        <p>ON</p>
                                                        <div></div>
                                                        <p>OFF</p>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <p>Show Friends</p>
                                    </div>
                                </div>

                                {
                                    this.state.loading &&

                                    <div className="spinner-border spinner-color" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }

                                {
                                    this.state.contacts.length === 0 && !this.state.loading &&

                                    <h4 className={'empty-list'}>Sorry, No user found</h4>
                                }

                                {
                                    this.state.contacts.map((contact, index) => {
                                        const cls = contact.online ? 'top_ticker online' : 'top_ticker';

                                        return (

                                            <div className="profile_listing_left" key={index}>
                                                <div className="row">
                                                    <div className="col-md-5">
                                                        <div className="user_info_sum d-flex align-items-center">
                                                            <div className="profile_img">
                                                                <Link to={`/profile/${contact.name}`}>
                                                                {
                                                                    contact.img &&

                                                                    <img src={`${baseUrl}/avatar/${contact.img}`} alt=""/>

                                                                }

                                                                {
                                                                    !contact.img && contact.gender.toLowerCase() === 'male' &&
                                                                    <img src={`${baseUrl}/avatar/avatar - 09-01.png`} alt=""/>
                                                                }

                                                                {
                                                                    !contact.img && contact.gender.toLowerCase() === 'female' &&
                                                                    <img src={`${baseUrl}/avatar/avatar - 14-01.png`} alt=""/>
                                                                }

                                                                {
                                                                    !contact.img && contact.gender.toLowerCase() !== 'male' && contact.gender.toLowerCase() !== 'female' &&
                                                                    <img src={`${baseUrl}/avatar/avatar-2877.png`} alt=""/>
                                                                }
                                                                </Link>

                                                                <span className={cls}> </span>
                                                            </div>
                                                            <div className="details">
                                                                <p className="font-weight-bold text-uppercase">
                                                                    <Link to={`/profile/${contact.name}`}>
                                                                        <span className={'purple_color'}>{contact.name}</span>
                                                                    </Link>
                                                                    {
                                                                        contact.flag_status === 'new' &&
                                                                        <span className="badge badge-success np_badge">new</span>
                                                                    }
                                                                    {
                                                                        contact.flag_status === 'green' &&
                                                                        <span className="reported_flag">
                                                                            <i className="fa fa-flag stage_0" title="Good Reputation On NowPurple"></i>
                                                                        </span>
                                                                    }
                                                                    {
                                                                        contact.flag_status === 'light-yellow' &&
                                                                        <span className="reported_flag">
                                                                            <i className="fa fa-flag stage_1" title="Good Reputation On NowPurple"></i>
                                                                        </span>
                                                                    }
                                                                    {
                                                                        contact.flag_status === 'deep-yellow' &&
                                                                        <span className="reported_flag">
                                                                            <i className="fa fa-flag stage_2" title="Good Reputation On NowPurple"></i>
                                                                        </span>
                                                                    }
                                                                    {
                                                                        contact.flag_status === 'red' &&
                                                                        <span className="reported_flag">
                                                                            <i className="fa fa-flag stage_3" title="Good Reputation On NowPurple"></i>
                                                                        </span>
                                                                    }

                                                                    {
                                                                        parseInt(contact.isGuarded) === 1
                                                                        ?
                                                                        <i id='sgs' className="fa fa-shield guarded" aria-hidden="true" title='Safe Guarded'
                                                                           data-toggle={'modal'} data-target={'#guard-info'}
                                                                           onClick={() => {
                                                                               document.querySelector('#guard-info p:last-of-type').style.display = 'none';
                                                                               document.querySelector('#guard-info p:first-of-type').style.display = 'block';
                                                                           }
                                                                           }></i>
                                                                        :
                                                                        <i id='sgs' className="fa fa-shield unguarded" aria-hidden="true" title='UnGuarded'
                                                                           data-toggle={'modal'} data-target={'#guard-info'}
                                                                           onClick={() => {
                                                                               document.querySelector('#guard-info p:first-of-type').style.display = 'none';
                                                                               document.querySelector('#guard-info p:last-of-type').style.display = 'block';
                                                                           }
                                                                           }></i>
                                                                    }

                                                                </p>

                                                                <div className="rating_profile">
                                                                    <div className="star_ico">

                                                                        {
                                                                            Array.apply(null, {length: contact.rating}).map((v, i) => {
                                                                                return (
                                                                                    <i className="fa fa-star" key={i}></i>
                                                                                );
                                                                            })
                                                                        }
                                                                        {
                                                                            5 - contact.rating !== 0 &&
                                                                                Array.apply(null, {length: (5-contact.rating)}).map((v, i) => {
                                                                                    return <i className="fa fa-star-o" key={i}></i>
                                                                                })
                                                                        }

                                                                    </div>
                                                                    <div className="rate_no">
                                                                        {parseFloat(contact.rating)}
                                                                    </div>
                                                                </div>
                                                                <p className="small_font">{contact.city}, {contact.country}</p>
                                                                <p className="small_font"><span
                                                                    className="purple_color">Age:</span> {contact.age} | <span className="purple_color">Post Code:</span> {contact.post} </p>
                                                                {/*<p className="small_font"></p>*/}
                                                                {/*<a href="#" className="chatnow">Chat now</a>*/}

                                                                {
                                                                    !contact.alreadySent &&
                                                                    <div className={'chat-action'}>
                                                                        <a href="#" className="chatnow pink_color add-to-list"
                                                                           onClick={(e) => {
                                                                               e.preventDefault();
                                                                               this.addContact(contact);
                                                                               this.showSchedule = false;
                                                                               document.getElementById('add-message').value = '';
                                                                               this.message = '';
                                                                           }}
                                                                           data-toggle="modal" data-target="#modal_confirm"
                                                                        >Chat Now</a>

                                                                        <a href="#" className="chatnow btn_purple_mid_light"
                                                                           onClick={(e) => {
                                                                               e.preventDefault();
                                                                               this.addContact(contact);
                                                                               this.showSchedule = true;
                                                                               document.getElementById('add-message').value = '';
                                                                               this.message = '';
                                                                           }}
                                                                           data-toggle="modal" data-target="#modal_confirm"
                                                                        >Appointment</a>
                                                                    </div>
                                                                }

                                                                {
                                                                    contact.alreadySent === 'to' &&
                                                                    <div className={'chat-action'}>

                                                                        <a href="#" className="chatnow btn-danger"
                                                                           onClick={(e) => {
                                                                               e.preventDefault();
                                                                               this.cancelRequest(contact);
                                                                           }}
                                                                        >Cancel</a>

                                                                        <a href="#" className="chatnow disabled"
                                                                           onClick={(e) => {
                                                                               e.preventDefault();
                                                                           }}
                                                                        >Appointment</a>

                                                                    </div>
                                                                }

                                                                {
                                                                    contact.alreadySent === 'me' &&
                                                                    <div className={'chat-action'}>

                                                                        <a href="#" className="chatnow btn_purple_mid_light"
                                                                           onClick={async (e) => {
                                                                               e.preventDefault();
                                                                               await this.acceptRejectContact(contact, 1);
                                                                           }}
                                                                        >Accept</a>
                                                                        <a href="#" className="chatnow pink_color add-to-list"
                                                                           onClick={async (e) => {
                                                                               e.preventDefault();
                                                                               await this.acceptRejectContact(contact, 0);
                                                                           }}
                                                                        >Reject</a>

                                                                    </div>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7">
                                                        <div className="info_area">
                                                            <div className="now_tabs d-flex justify-content-between">
                                                                <ul className="nav nav-tabs" id="profileTab" role="tablist">
                                                                    <li className="nav-item">
                                                                        <a className="nav-link active" id="bio-tab"
                                                                           data-toggle="tab" href={`#bio${contact.id}`} role="tab"
                                                                           aria-controls="home" aria-selected="true">Bio</a>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <a className="nav-link" id="why_here-tab" data-toggle="tab"
                                                                           href={`#why_here${contact.id}`} role="tab" aria-controls="why_here"
                                                                           aria-selected="false">Why i'm here?</a>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <a className="nav-link" id="avail-tab" data-toggle="tab"
                                                                           href={`#avail${contact.id}`} role="tab" aria-controls="avail"
                                                                           aria-selected="false">Availability</a>
                                                                    </li>
                                                                </ul>
                                                                <Link to={`/profile/${contact.name}`} className="view_profile"
                                                                   id="view_profile">View</Link>

                                                            </div>
                                                            <div className="tab-content mt-2" id="myTabContent">
                                                                <div className="tab-pane fade show active" id={`bio${contact.id}`} role="tabpanel"
                                                                     aria-labelledby="bio-tab">
                                                                    <p className="purple_color font-weight-bold">Hi I am {contact.name}.</p>
                                                                    {
                                                                        contact.bio
                                                                            ?
                                                                            <p className='details-text'>{contact.bio}</p>
                                                                            :
                                                                            <>
                                                                                <p className='details-text'>___ ___ ___ ___ ____ ___ ____ _____ ___ ____ ___ ____ ____ ____ ____ _____ ____ ___ _____ _____ _____ ____ ___ ___ ___ ___ ___ ____ ___ ____ _____ ___ ____ ___ ____ ____ ____ ____ _____ ____ ___ _____ _____ _____ ____ ___</p>
                                                                            </>
                                                                    }
                                                                </div>
                                                                <div className="tab-pane fade" id={`why_here${contact.id}`} role="tabpanel"
                                                                     aria-labelledby="why_here-tab">
                                                                    {
                                                                        contact.why_here
                                                                            ?
                                                                            <p className='details-text'>{contact.why_here}</p>
                                                                            :
                                                                            <>
                                                                                <p className='details-text'>___ ___ ___ ___ ____ ___ ____ _____ ___ ____ ___ ____ ____ ____ ____ _____ ____ ___ _____ _____ _____ ____ ___ ___ ___ ___ ___ ____ ___ ____ _____ ___ ____ ___ ____ ____ ____ ____ _____ ____ ___ _____ _____ _____ ____ ___</p>
                                                                            </>
                                                                    }
                                                                </div>
                                                                <div className="tab-pane fade avail-tab" id={`avail${contact.id}`} role="tabpanel"
                                                                     aria-labelledby="avail-tab">
                                                                    {
                                                                        contact.availability
                                                                            ?
                                                                            <ul>
                                                                                {
                                                                                    contact.availability.map((t, i) => {
                                                                                        return (
                                                                                            <li key={i}>{t}</li>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                            :
                                                                            <>
                                                                                <p className='text-muted small_font'>Please let us know, as this information will increase your profile visibility</p>
                                                                            </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        );
                                    })
                                }

                                {/*End single one profile*/}

                                {

                                    <div className={'paginate'}>
                                        {
                                            this.state.filter.page > 1 &&
                                            <button className={'btn btn-secondary'} onClick={this.prevPage}>Previous</button>
                                        }
                                        {
                                            this.state.contacts.length > 0 && this.state.contacts.length === this.limit &&
                                            <button className={'btn btn-secondary'} onClick={this.nextPage}>Next</button>
                                        }
                                    </div>
                                }

                            </div>

                            {/*End col 9*/}

                            <div className="col-12 col-md-12 col-lg-3 col-xl-3">
                                <div className="profile_listing_right">

                                    {
                                        this.state.adsProductList &&
                                            <>
                                                {
                                                    this.state.adsProductList.map((adsProduct, index) => {
                                                        return (
                                                            <div className="single_product sidebar_product" key={index}>
                                                                <a  target="_balnk" href={adsProduct.outsite_url}>
                                                                    <img src={`${baseUrl}/assets/uploads/adsproduct/${adsProduct.img}`} alt="Product image" />
                                                                </a>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </>
                                    }

                                </div>
                            </div>

                        </div>
                    </div>
                </section>


                <div className="modal fade modal_tnc" id="modal_confirm" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body p-0">

                                <div className="tnc_content">

                                    <h4 className="text-white text-left">Do you want to add {this.state.selectedContact.name} to your friends list?</h4>
                                    <div className="top_carret">
                                        <i className="fa fa-caret-down"></i>
                                    </div>
                                </div>

                                <div className="modal_waper_main bg-white">
                                    <div className="modal_header p-5 text-left">
                                        <p>Thank you for sending a friendship request to {this.state.selectedContact.name}! As soon as your request is accepted, you will be able to communicate with them. Before that, we ask a bit of your patience. Thank you!</p>
                                    </div>
                                    <div className="pl-5 pr-5 pb-5 pt-0 text-left">
                                        <textarea rows="3" id={'add-message'} placeholder={'Write you message....'} onChange={(e) => {
                                            e.persist();
                                            this.message = e.target.value;
                                        }}></textarea>

                                        {
                                            this.showSchedule &&
                                            <div className="set-schedule">
                                                <p>Set schedule:</p>
                                                <ul className={'schedule-dates'}>
                                                    {
                                                        this.state.selectedDates.map((date, i) => {
                                                            return (
                                                                <li key={i}>{date}</li>
                                                            )
                                                        })

                                                    }
                                                </ul>

                                                <DatePicker
                                                    selected={this.state.selectDate}
                                                    onChange={date => this.setDate(date)}
                                                    timeInputLabel="Time:"
                                                    dateFormat="MM/dd/yyyy h:mm aa"
                                                    showTimeInput
                                                />
                                                {
                                                    this.state.selectedDates.length < 3 &&
                                                    <i className="fa fa-plus-circle ml-2 purple_color cur-pointer" aria-hidden="true" onClick={this.addDate}></i>
                                                }
                                            </div>
                                        }

                                        <p className="mt-2 text-info"><sup>*</sup> Writing message or setting schedule are optional</p>

                                        <div className="continue  text-center mt-3">
                                            <a href="#" className="btn_now_purple btn_purple"
                                               id={'send-btn'} onClick={async () => { await this.sendRequest(true)}}>Continue</a>
                                            {/*<button className={'btn btn-sm btn-danger'} id={'cancel-btn'} onClick={async () => { await this.sendRequest(false)}}>Cancel Request</button>*/}
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>



                <div className="modal fade re_modal" id="guard-info" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body">
                                <div className="modal_waper_main_profile bg-white">

                                    <p>This user has Safeguard activated. Meeting this user physically is safe.</p>
                                    <p>This user has not activated Safeguard. Meeting this user physically is not safe.</p>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section id={'customScript'}></section>

            </>
        );
    }
}

export default FindContacts;
