import React, {Component, createContext} from 'react';
import io from "socket.io-client";
import {baseChatUrl, getFriendRequests, unreadChatMessage} from "../services/ApiService";

export const LoginContext = createContext({
    isLoggedIn: false
});

class LoginContextProvider extends Component {
    state = {
        isLoggedIn: false,
        // unreadChat: 0,
        friendRequests: 0,
        joinedSocket: false
    }

    socket = null;
    user = null;
    componentDidMount() {
        const token = localStorage.getItem('access_token');
        if (token) {
            this.setLoggedInState(true);
        }

        this.socket = io(`${baseChatUrl}`);

    }

    updateUnreadChat = (count) => {
        const bk = this.state;
        bk.unreadChat = count;
        this.setState(bk);
    }

    updateFriendRequests = (count) => {
        const bk = this.state;
        bk.friendRequests = count;
        this.setState(bk);
    }

    setLoggedInState = (data) => {
        const bk = this.state;
        bk.isLoggedIn = data;
        this.setState(bk);

    }

    setSocketJoinStatus = (data) => {
        const bk = this.state;
        bk.joinedSocket = data;
        this.setState(bk);
    }

    render() {
        return (
            <LoginContext.Provider value={{ ...this.state,
                setLoggedInState: this.setLoggedInState,
                // updateUnreadChat: this.updateUnreadChat,
                updateFriendRequests: this.updateFriendRequests,
                // updateNotifications: this.updateNotifications,
                socket: this.socket
            }}>
                { this.props.children }
            </LoginContext.Provider>
        );
    }

}

export default LoginContextProvider;
