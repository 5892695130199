import React from "react";
import {Link} from "react-router-dom";
import "../pageCss/profile.css";
import {baseUrl, getUserProfile, setPageView, setProfileLike} from "../services/ApiService";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {userInfo} from "../services/CommonService";
import AddScripts from "../AddScript";
import {LoginContext} from "../contexts/LoginContext";


class Profile extends React.Component {
    static contextType = LoginContext;

    state = {
        currentUser: {},
        profile: userInfo,
    }
    userProfileName = null;

    socket = null;
    componentDidMount() {
        const bk = this.state;
        bk.currentUser = JSON.parse(localStorage.getItem('user'));
        this.setState(bk);

        this.socket = this.context.socket;
        this.userProfileName = this.props.match.params.userName;

        this.getUserProfile(this.userProfileName);

    }

    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };
    }

    getUserProfile = async (userName) => {
        const res = await getUserProfile(userName);

        if (res) {
            if (res.status) {
                const bk = this.state;
                bk.profile = res.info;
                this.setState(bk);

                document.getElementById('customScript').innerHTML = '';
                const addScr = new AddScripts();
                addScr.createElement('customScript', '/extraJs/ExtraMyProfile.js');
                await this.pageViewSet();
            }
        }
    }

    pageViewSet = async () => {
        await setPageView({viewedUser: this.state.profile.id});
        this.socket.emit('notify', {user_id: this.state.profile.id, message: `${this.state.currentUser.name} viewed your profile`});
    }

    likeProfile = async () => {
        if (this.state.profile.liked) {
            toast.success('Already Liked this user profile');
            return false;
        }
        const res = await setProfileLike({likedUser: this.state.profile.id});
        if (res) {
            if (res.status) {
                toast.success('Liked this user profile');
                const bk = this.state;
                bk.profile.likes += 1;
                this.setState(bk);
                this.socket.emit('notify', {user_id: this.state.profile.id, message: `${this.state.currentUser.name} liked your profile`});
            }
        }
    }

    render() {
        return (
            <>

                <div className="page_hero"></div>


                <section id="profile">
                    {
                        this.state.profile.name === ''
                            ?

                            <div className="spinner-border spinner-color" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>

                            :

                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <div className="profile_left">
                                            <div className="profile_images">
                                                {
                                                    this.state.profile.img &&

                                                    <img src={`${baseUrl}/avatar/${this.state.profile.img}`} alt=""/>

                                                }

                                                {
                                                    !this.state.profile.img && this.state.profile.gender.toLowerCase() === 'male' &&
                                                    <img src={`${baseUrl}/avatar/avatar - 09-01.png`} alt=""/>
                                                }

                                                {
                                                    !this.state.profile.img && this.state.profile.gender.toLowerCase() === 'female' &&
                                                    <img src={`${baseUrl}/avatar/avatar - 14-01.png`} alt=""/>
                                                }

                                                {
                                                    !this.state.profile.img && this.state.profile.gender.toLowerCase() !== 'male' && this.state.profile.gender.toLowerCase() !== 'female' &&
                                                    <img src={`${baseUrl}/avatar/avatar-2877.png`} alt=""/>
                                                }
                                            </div>
                                            <div className="bio">
                                                <div className="profile_title">
                                                    <h6>Bio</h6>
                                                </div>
                                                {
                                                    this.state.profile.bio
                                                        ?
                                                        <p>{this.state.profile.bio}</p>
                                                        :
                                                        <>
                                                            <p>___ ___ ___ ___ ____ ___ ____ _____ ___ ____ ___ ____ ____ ____ ____ _____ ____ ___ _____ _____ _____ ____ ___ ___ ___ ___ ___ ____ ___ ____ _____ ___ ____ ___ ____ ____ ____ ____ _____ ____ ___ _____ _____ _____ ____ ___</p>
                                                        </>
                                                }
                                            </div>
                                            <div className="bio">
                                                <div className="profile_title">
                                                    <h6>Interest</h6>
                                                </div>
                                                <div className="tags_interest">
                                                    {
                                                        this.state.profile.hobby !== '' &&
                                                        (this.state.profile.hobby.split(',')).map((item, i) => {
                                                            return (
                                                                <span className="interest_btn" key={i}>{item}</span>
                                                            );
                                                        })
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-8 offset-md-1">
                                        <div className="profile_right">
                                            <div
                                                className="user_info d-block d-md-flex d-lg-flex d-xl-flex justify-content-between">
                                                <div className="name_details">
                                                    <h3 className="purple_color">{this.state.profile.name}
                                                        {
                                                            parseInt(this.state.profile.isGuarded) === 1
                                                                ?
                                                                <i id='sgs' className="fa fa-shield guarded" aria-hidden="true" title='Safe Guarded'
                                                                   data-toggle={'modal'} data-target={'#guard-info'}
                                                                onClick={() => {
                                                                    document.querySelector('#guard-info p:last-of-type').style.display = 'none';
                                                                    document.querySelector('#guard-info p:first-of-type').style.display = 'block';
                                                                }
                                                                }></i>
                                                                :
                                                                <i id='sgs' className="fa fa-shield unguarded" aria-hidden="true" title='UnGuarded'
                                                                   data-toggle={'modal'} data-target={'#guard-info'}
                                                                   onClick={() => {
                                                                       document.querySelector('#guard-info p:first-of-type').style.display = 'none';
                                                                       document.querySelector('#guard-info p:last-of-type').style.display = 'block';
                                                                   }
                                                                   }></i>
                                                        }
                                                    <span>
                                                        <i className="fa fa-map-marker"></i> {this.state.profile.cityName}, {this.state.profile.countryName}
                                                    </span>
                                                    </h3>
                                                    <h6>{this.state.profile.profession}</h6>
                                                </div>
                                                <div className="right_bookmark">
                                                    <small>{
                                                     this.state.profile.liked ? 'Liked' : 'Likes'
                                                    }({this.state.profile.likes})
                                                        <i className="fa fa-thumbs-up" aria-hidden="true" onClick={this.likeProfile}></i>
                                                    </small>
                                                    <h5><i className="fa fa-bookmark"></i> Bookmark</h5>
                                                </div>
                                            </div>
                                            <div className="profile_match">
                                                <p>{this.state.profile.profilePercentage}% Completed</p>
                                                <div className="profile_bar_pipe">
                                                    <div id={'variable'} style={{width: `${this.state.profile.profilePercentage}%`}}></div>
                                                </div>
                                            </div>

                                            <div className="profile_ajax_tabs">
                                                <ul className="nav nav-pills" id="pills-tab"
                                                    role="tablist">
                                                    <li className="nav-item">
                                                        <a className="nav-link active" id="pills-basic-tab"
                                                           data-toggle="pill" href="#pills-basic" role="tab"
                                                           aria-controls="pills-basic"
                                                           aria-selected="true">Basic Information</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="pills-why-here-tab"
                                                           data-toggle="pill" href="#pills-why-here"
                                                           role="tab"
                                                           aria-controls="pills-why-here"
                                                           aria-selected="false">Why I'm Here</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        {
                                                            this.state.profile.availability === ''
                                                            ?
                                                                <a className="nav-link disabled"
                                                                   data-toggle="pill" href="#"
                                                                   role="tab"
                                                                   aria-selected="false">Availability</a>
                                                            :
                                                                <a className="nav-link" id="pills-avail-tab"
                                                                   data-toggle="pill" href="#pills-avail"
                                                                   role="tab"
                                                                   aria-controls="pills-avail"
                                                                   aria-selected="false">Availability</a>
                                                        }

                                                    </li>
                                                    <li className="nav-item">
                                                        {
                                                            !this.state.profile.gallery && !this.state.profile.hasGalleryImage
                                                            ?
                                                                <a className="nav-link disabled"
                                                                   data-toggle="pill" href="#"
                                                                   aria-selected="false">Gallery</a>
                                                            :

                                                                <a className="nav-link" id="pills-gallery-tab"
                                                                   data-toggle="pill" href="#pills-gallery"
                                                                   role="tab"
                                                                   aria-controls="pills-gallery"
                                                                   aria-selected="false">Gallery</a>
                                                        }

                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="profile_details">
                                                <div className="tab-content" id="pills-tabContent">
                                                    <div className="row tab-pane fade show active" role="tabpanel" aria-labelledby="pills-basic-tab" id={'pills-basic'}>
                                                        <div className="info_container">
                                                            <h6 className="purple_color">Basic Information:</h6>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <p>
                                                                        <span>Gender:</span> {this.state.profile.gender}
                                                                    </p>
                                                                    <p>
                                                                        <span>Age:</span> {this.state.profile.age}
                                                                    </p>
                                                                    <p>
                                                                        <span>Profession:</span> {this.state.profile.profession}
                                                                    </p>
                                                                    <p>
                                                                        <span>Height:</span> {this.state.profile.height}
                                                                    </p>
                                                                    <p>
                                                                        <span>Ethnicity:</span> {this.state.profile.ethnicity}
                                                                    </p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p>
                                                                        <span>Body Type:</span> {this.state.profile.bodyType}
                                                                    </p>
                                                                    <p>
                                                                        <span>Skin color:</span> {this.state.profile.skin_color}
                                                                    </p>
                                                                    <p>
                                                                        <span>Eye Color:</span> {this.state.profile.eye_color}
                                                                    </p>
                                                                    <p>
                                                                        <span>Hair Length:</span> {this.state.profile.hair_length}
                                                                    </p>
                                                                    <p>
                                                                        <span>Hair Type:</span> {this.state.profile.hair_type}
                                                                    </p>
                                                                    <p>
                                                                        <span>Hair Color:</span> {this.state.profile.hair_type}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row tab-pane fade" role="tabpanel" aria-labelledby="pills-why-here-tab" id={'pills-why-here'}>

                                                    {
                                                        this.state.profile.why_here
                                                            ?
                                                            <p>{this.state.profile.why_here}</p>
                                                            :
                                                            <>
                                                                <p>___ ___ ___ ___ ____ ___ ____ _____ ___ ____ ___ ____ ____ ____ ____ _____ ____ ___ _____ _____ _____ ____ ___ ___ ___ ___ ___ ____ ___ ____ _____ ___ ____ ___ ____ ____ ____ ____ _____ ____ ___ _____ _____ _____ ____ ___</p>
                                                            </>
                                                    }

                                                    </div>


                                                    <div className="row tab-pane fade" role="tabpanel" aria-labelledby="pills-avail-tab" id={'pills-avail'}>

                                                    {
                                                        this.state.profile.availability !== '' &&
                                                        <>
                                                            <p className='text-secondary'>Usually i am online at the bellow times:</p>
                                                            <ul>
                                                                {
                                                                    this.state.profile.availability.split(',').map((t, i) => {
                                                                        return (
                                                                            <li key={i}>{t}</li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </>
                                                    }


                                                    </div>

                                                    <div className="row tab-pane fade" role="tabpanel" aria-labelledby="pills-gallery-tab" id={'pills-gallery'}>
                                                        <div className="gallery_waper">
                                                            <div className="row">

                                                                {
                                                                    !this.state.profile.gallery
                                                                    ?

                                                                        <p className={'no-shared-image'}>Hey, this is a private gallery. But you can ask this member to share this gallery with you.</p>

                                                                    :

                                                                        this.state.profile.gallery.map((item, i) => {
                                                                            return (

                                                                                <div className="col-md-3" key={i}>
                                                                                    <div className="gal_image">
                                                                                        <a href={`${baseUrl}/image_gallery/${item.name}`}
                                                                                           className="with-caption image-link">
                                                                                            <img src={`${baseUrl}/image_gallery/${item.name}`}/>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>

                                                                            );
                                                                        })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>

                    }

                </section>


                <div className="modal fade re_modal" id="guard-info" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal_close" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                            <div className="modal-body">
                                <div className="modal_waper_main_profile bg-white">

                                    <p>This user has Safeguard activated. Meeting this user physically is safe.</p>
                                    <p>This user has not activated Safeguard. Meeting this user physically is not safe.</p>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <section id={'customScript'}></section>

            </>
        );
    }
}

export default Profile;
