import React from "react";
import {Switch, Route} from 'react-router-dom';
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Shop from "./pages/Shop";
import Profile from "./pages/Profile";
import FindContacts from "./pages/FindContacts";
import Chat from "./chat/Chat";
import {LoginContext} from "./contexts/LoginContext";
import MyProfile from "./pages/MyProfile";
import Privacy from "./pages/Privacy";
import Faq from "./pages/Faq";
import Terms from "./pages/terms";
import CookiePolicy from "./pages/CookiePolicy";
import ForgetPassword from "./pages/forgetPassword";
import PageNotFound from "./pages/pageNotFound";
import HowTo from "./pages/howTo";
import Story from "./pages/Story";
import RenewPassword from "./pages/renewPassword";
import Blog from "./pages/Blog";
import BlogSingle from "./pages/BlogSingle";
import Unsubscribe from "./pages/Unsubscribe";

const Routes = () => {

    return (
        <LoginContext.Consumer>{(loginContext) => {
            const {isLoggedIn} = loginContext;
            return (
                <Switch>
                    <Route path="/" exact component={Home}/>
                    <Route path="/contact" component={Contact}/>
                    <Route path="/shop" component={Shop}/>
                    <Route path="/privacy-policy" component={Privacy}/>
                    <Route path="/faq" component={Faq}/>
                    <Route path="/terms" component={Terms}/>
                    <Route path="/cookie-policy" component={CookiePolicy} />
                    <Route path="/forget-password" component={ForgetPassword} />
                    <Route path="/how-to" component={HowTo} />
                    <Route path="/story" component={Story} />
                    <Route path="/blog" exact component={Blog} />
                    <Route path="/blog/:slug" component={BlogSingle} />
                    <Route path="/reset-password/:key" component={RenewPassword} />
                    <Route path="/unsubscribe" component={Unsubscribe} />
                    {   isLoggedIn &&
                        <>
                            <Route path="/profile" exact component={MyProfile}/>
                            <Route path="/profile/:userName" component={Profile}/>
                            <Route path="/chat" component={Chat}/>
                            <Route path="/find-friends" component={FindContacts}/>
                        </>
                    }

                    <Route component={PageNotFound} />
                </Switch>
            );

        }}</LoginContext.Consumer>

    );

}

export default Routes;
