import React from "react";

class Privacy extends React.Component {

    componentDidMount() {
        document.title = 'Privacy Policy | NowPurple Free Dating Website UK';
        document.getElementsByTagName('meta')["description"].content = 'Privacy Policy page of NowPurple. Read our privacy policies here. At NowPurple the privacy of all our users is our highest priority.';
        document.getElementsByTagName('meta')["keywords"].content = 'free dating site uk';

        document.body.classList.add('static');
        document.documentElement.scrollTop = 0;
    }
    componentWillUnmount() {
        document.body.classList.add('static');
    }

    render() {
        return (
            <>

                <div className="page_hero"></div>


                <section id="static">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="page_title text-center">
                                    <h3 className="purple_color">Privacy Policy</h3>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <div className="">
                                    <h2>Privacy Policy and your protection as a member</h2>

                                    <p>At NowPurple, your privacy is our highest priority. </p>
                                    <p>Your privacy is at the core of the way we design and build the services and products, so that you can fully trust them and focus on building connections and relationships.That is why we (among other functions) created an option to register to our services without using email, phone number or any other personal information. We also don’t collect other personal information that many other services choose to collect such as precise geolocation (we offer to search by location according to the information provided to us by members themselves such as country, city, postcode etc).</p>
                                    <p>We appreciate that you put your trust in us when you provide us with any kind of information, and we do not take this lightly.</p>
                                    <p>We do not compromise with your privacy. We design all of our products and services with your privacy as our highest priority. </p>
                                    <p>We strive to be transparent in the way we process your data. Because we use many of the same online services you do, we know that insufficient information and overly complicated language are common issues in privacy policies. We take the exact opposite approach: we have written our Privacy Policy and related documents in plain language. We actually want you to read our policies and understand our privacy practices!</p>
                                    <p>We work hard to keep your information secure. We have teams dedicated to keeping your data safe and secure. We constantly update our security practices and invest in our security efforts to enhance the safety of your information.</p>

                                    <h2>Privacy Policy</h2>

                                    <p>Welcome to NowPurple’s Privacy Policy. Thank you for taking the time to read it.</p>
                                    <p>We appreciate that you trust us with your information, and we intend to always keep that trust. This starts with making sure you understand the information we collect, why we collect it, how it is used and your choices regarding your information. This Policy describes our privacy practices in plain language, keeping legal and technical jargon to a minimum.</p>
                                    <p>This Privacy Policy applies beginning October, 2020. </p>
                                    <ul>
                                        <li>WHO WE ARE</li>
                                        <li>WHERE THIS PRIVACY POLICY APPLIES</li>
                                        <li>INFORMATION WE COLLECT</li>
                                        <li>COOKIES AND OTHER SIMILAR DATA COLLECTION TECHNOLOGIES</li>
                                        <li>HOW WE USE INFORMATION</li>
                                        <li>HOW WE SHARE INFORMATION</li>
                                        <li>CROSS-BORDER DATA TRANSFERS</li>
                                        <li>YOUR RIGHTS</li>
                                        <li>HOW WE PROTECT YOUR INFORMATION</li>
                                        <li>HOW LONG WE RETAIN YOUR INFORMATION</li>
                                        <li>CHILDREN’S PRIVACY</li>
                                        <li>PRIVACY POLICY CHANGES</li>
                                        <li>HOW TO CONTACT US</li>
                                    </ul>

                                    <h3>WHO WE ARE</h3>

                                    <p>The company that is responsible for your information under this Privacy Policy (the “data controller”) is:</p>
                                    <ul>
                                        <li>NowPurple Ltd</li>
                                        <li>Unit GF 20</li>
                                        <li>58 Nelson Street</li>
                                        <li>London, E1 2DE</li>
                                    </ul>

                                    <h3>WHERE THIS PRIVACY POLICY APPLIES</h3>

                                    <p>This Privacy Policy applies to websites and other services operated by NowPurple. For simplicity, we refer to all of these as our “services” in this Privacy Policy. To make it extra clear, we’ve added links to this Privacy Policy on all applicable services.</p>
                                    <p>Some services may require their own unique privacy policy. If a particular service has its own privacy policy, then that policy — not this Privacy Policy — applies.</p>

                                    <h3>INFORMATION WE COLLECT</h3>

                                    <p>It goes without saying, we can’t help you develop meaningful connections and relationships without some information about you, such as basic profile details anddescriptions. We also collect information generated as you use our services, for example access logs. If you want additional info, we go into more detail below.</p>

                                    <h4>Information you give us</h4>

                                    <p>You choose to give us certain information when using our services. This includes:</p>
                                    <p>When you create an account, you provide us with at least your login credentials, as well as some basic details necessary for the service to work, such as your gender and date of birth.
                                        When you complete your profile, you can voluntarily share with us additional information, such as details on your personality, lifestyle, interests and other details about you, as well as content such as photos. To add certain content, like pictures you may allow us to access your camera or photo album. Some of the information you choose to provide us may be considered “special” or “sensitive” in certain jurisdictions, for example your racial or ethnic origins, sexual orientation and religious beliefs. By choosing to provide this information, you consent to our processing of that information. We do not process photos or other content automatically and without your consent.
                                    </p>
                                    <p>We do not charge for any of our dating related services, but we do have a marketplace called “Shop to help” on our platform where you can buy certain products. If you click on any product in this marketplace you will be redirected to a third-party website to complete the product purchase. As you will be buying from a third party, NowPurple will never show up in your bank statements.This also means that you will neverprovide us your debit or credit card number or other financial information. </p>
                                    <p>You will however provide such information to that particular third-party website. We take great care to select business partners for our marketplace who have high standards of eCommerce and all of them use highly secure online payment processing platforms where all of your financial information is encrypted and secure. </p>
                                    <p>When you participate in surveys or focus groups, you give us your insights into our products and services, responses to our questions and testimonials.</p>
                                    <p>When you choose to participate in our promotions, events or contests, we collect the information that you use to register or enter.</p>
                                    <p>If you contact our customer care team, we collect the information you give us during the interaction. Sometimes, we monitor or record these interactions for training purposes and to ensure a high quality of service.</p>
                                    <p>If you ask us to communicate with or otherwise process information of other people (for example, if you ask us to send a message on your behalf to one of your friends), we collect the information about others that you give us in order to complete your request.
                                        We also process your chats with other users as well as the content you publish, as part of the operation of the services.
                                    </p>

                                    <h4>Information we receive from others</h4>

                                    <p>In addition to the information you provide us directly, we receive information about you from others, including:</p>

                                    <h4>Other Users</h4>

                                    <p>Other users may provide information about you as they use our services. For instance, we may collect information about you from other users if they contact us about you. </p>

                                    <h4>Other Partners</h4>

                                    <p>We may receive info about you from our partners, for instance when NowPurple accounts can be created through a partner’s websites (in which case they pass along registration information to us) or where NowPurple ads are published on a partner’s websites and platforms (in which case they may pass along details on a campaign’s success).</p>
                                    <p>Information collected when you use our services</p>
                                    <p>When you use our services, we collect information about which features you’ve used, how you’ve used them and the devices you use to access our services.  See below for more details:</p>

                                    <h4>Usage Information</h4>

                                    <p>We collect information about your activity on our services, for instance how you use them (e.g., date and time you logged in, features you’ve been using, searches, clicks and pages which have been shown to you, referring webpage address, advertising that you click on) and how you interact with other users (e.g., users you connect and interact with, time and date of your exchanges, number of messages you send and receive).</p>

                                    <h4>Device information</h4>

                                    <p>We collect information from and about the device(s) you use to access our services, including:</p>
                                    <p>hardware and software information such as IP address, device ID and type, device-specificsettings and characteristics, softwarecrashes, advertising IDs (such as Google’s AAID and Apple’s IDFA, both of which are randomly generated numbers that you can reset by going into your device’ settings), browser type, version and language, operating system, time zones, identifiers associated with cookies or other technologies that may uniquely identify your device or browser (e.g., IMEI/UDID and MAC address);
                                        information on your wireless connection, like your service provider and signal strength;
                                        information on device sensors such as accelerometers, gyroscopes and compasses.
                                    </p>

                                    <h3>COOKIES AND OTHER SIMILAR DATA COLLECTION TECHNOLOGIES</h3>

                                    <p>We use and may allow others to use cookies and similar technologies (e.g., web beacons, pixels) to recognize you and/or your device(s). You may read our Cookie Policy link for more information on why we use them (such as authenticating you, remembering your preferences and settings, analysing site traffic and trends, delivering and measuring the effectiveness of advertising campaigns, allowing you to use social features) and how you can better control their use, through your browser settings and other tools.</p>
                                    <p>Some web browsers (including Safari, Internet Explorer, Firefox and Chrome) have a “Do Not Track” (“DNT”) feature that tells a website that a user does not want to have his or her online activity tracked. If a website that responds to a DNT signal receives a DNT signal, the browser can block that website from collecting certain information about the browser’s user. Not all browsers offer a DNT option and DNT signals are not yet uniform. For this reason, many businesses, including NowPurple, do not currently respond to DNT signals.</p>

                                    <h3>HOW WE USE INFORMATION</h3>

                                    <p>The main reason we use your information is to deliver and improve our services. Additionally, we use your information to help keep you safe and to provide you with advertising that may be of interest to you. Read on for a more detailed explanation of the various reasons we use your information, together with practical examples.</p>

                                    <h4>To administer your account and provide our services to you</h4>

                                    <ul>
                                        <li>Create and manage your account</li>
                                        <li>Provide you with customer support and respond to your requests</li>
                                        <li>Communicate with you about our services</li>
                                        <li>To help you connect with other users</li>
                                        <li>Show users’ profiles to one another</li>
                                    </ul>

                                    <p>To ensure a consistent experience across your devices Link the various devices you use so that you can enjoy a consistent experience of our services on all of them. We do this by linking devices and browser data, such as when you log into your account on different devices or by using partial or full IP address, browser version and similar data about your devices to help identify and link them.</p>

                                    <h4>To serve you relevant offers and ads</h4>
                                    <p>Administer sweepstakes, contests, discounts or other offers</p>
                                    <p>Develop, display and track content and advertising tailored to your interests on our services and other sites</p>
                                    <p>Communicate with you by internal messages or notifications (taking place on our own platform) about products or services that we think may interest you to improve our services and develop new ones</p>

                                    <h4>Administer focus groups and surveys</h4>

                                    <p>Conduct research and analysis of users’ behaviour to improve our services and content (for instance, we may decide to change the look and feel or even substantially modify a given feature based on users’ behaviour)</p>
                                    <p>Develop new features and services (for example, we may decide to build a new interests-based feature further to requests received from users).
                                        To prevent, detect and fight fraud or other illegal or unauthorized activities
                                    </p>

                                    <h4>Address ongoing or alleged misbehaviour on and off-platform</h4>

                                    <p>Perform data analysis to better understand and design countermeasures against these activities</p>
                                    <p>Retain data related to fraudulent activities to prevent against recurrences To ensure legal compliance</p>

                                    <h4>Comply with legal requirements</h4>

                                    <p>Assist law enforcement, Enforce or exercise our rights, for example our General Terms and Conditions of Use To process your information as described above, we rely on the following legal bases: </p>
                                    <p><b>Provide our service to you:</b>  Most of the time, the reason we process your information is to perform the contract that you have with us. For instance, as you go about using our service to build meaningful connections and relationships, we use your information to maintain your account and your profile and to make it viewable to other users.</p>
                                    <p><b>Legitimate interests:</b> We may use your information where we have legitimate interests to do so. For instance, we analyse users’ behaviour on our services to continuously improve our offerings, we suggest offers we think might interest you, and we process information for administrative, fraud detection and other legal purposes.</p>
                                    <p><b>Consent:</b> From time to time, we may ask for your consent to use your information for certain specific reasons. You may withdraw your consent at any time by contacting us at the address provided at the end of this Privacy Policy.</p>

                                    <h3>HOW WE SHARE INFORMATION</h3>

                                    <p>Since our goal is to help you make meaningful connections and relationships, the main sharing of users’ information is, of course, with other users. We also share some users’ information with service providers and partners who assist us in operating the services and in some cases, legal authorities. Read on for more details about how your information is shared with others.</p>

                                    <h4>With other users</h4>

                                    <p>You share information with other users when you voluntarily disclose information on the service (including your public profile). Please be careful with your information and make sure that the content you share is stuff that you’re comfortable being publicly viewable since neither you nor we can control what others do with your information once you share it.</p>
                                    <p>If you choose to limit the audience for all or part of your profile or for certain content or information about you, then it will be visible according to your settings.</p>

                                    <h4>With our service providers and partners</h4>

                                    <p>We use third parties to help us operate and improve our services. These third parties assist us with various tasks, including data hosting and maintenance, analytics, customer care, marketing, advertising, and security operations.</p>
                                    <p>We may also share information with partners who distribute and assist us in advertising our services. For instance, we may share limited information on you in hashed, non-human readable form to advertising partners.</p>
                                    <p>We follow a strict vetting process prior to engaging any service provider or working with any partner. All of our service providers and partners must agree to strict confidentiality obligations.</p>

                                    <h4>For corporate transactions</h4>

                                    <p>We may transfer your information if we are involved, whether in whole or in part, in a merger, sale, acquisition, divestiture, restructuring, reorganization, dissolution, bankruptcy or other change of ownership or control.</p>

                                    <h4>When required by law</h4>

                                    <p>We may disclose your information if reasonably necessary: (i) to comply with a legal process, such as a court order, subpoena or search warrant, government / law enforcement investigation or other legal requirements; (ii) to assist in the prevention or detection of crime (subject in each case to applicable law); or (iii) to protect the safety of any person.</p>

                                    <h4>To enforce legal rights</h4>

                                    <p>We may also share information: (i) if disclosure would mitigate our liability in an actual or threatened lawsuit; (ii) as necessary to protect our legal rights and legal rights of our users, business partners or other interested parties; (iii) to enforce our agreements with you; and (iv) to investigate, prevent, or take other action regarding illegal activity, suspected fraud or other wrongdoing.</p>

                                    <h4>With your consent or at your request</h4>

                                    <p>We may ask for your consent to share your information with third parties. In any such case, we will make it clear why we want to share the information.</p>
                                    <p>We may use and share non-personal information (meaning information that, by itself, does not identify who you are such as device information, general demographics, general behavioural data), as well as personal information in hashed, non-human readable form, under any of the above circumstances. </p>
                                    <p>We may also share this information with third parties (notably advertisers) to develop and deliver targeted advertising on our services and on websites or applications of third parties, and to analyse and report on advertising you see. We may combine this information with additional non-personal information or personal information in hashed, non-human readable form collected from other sources. More information on our use of cookies and similar technologies can be found in our Cookie Policy link.</p>

                                    <h3>YOUR RIGHTS</h3>

                                    <p>We want you to be in control of your information, so we have provided you with the following tools:</p>
                                    <p>Access / Update tools in the service. Tools and account settings that help you to access, rectify or delete information that you provided to us and that’s associated with your account directly within the service. If you have any question on those tools and settings, please contact our customer care team for help via our Contact Page.</p>
                                    <p>Deletion. You can delete your account by using the corresponding functionality directly on the service (inside your profile).</p>
                                    <p>We want you to be aware of your privacy rights. Here are a few key points to remember:</p>

                                    <h4>Reviewing your information</h4>

                                    <p>Applicable privacy laws may give you the right to review the personal information we keep about you (depending on the jurisdiction, this may be called right of access, right of portability or variations of those terms). You can request a copy of your personal information by putting in such a request to us via our contact form.</p>

                                    <h4>Updating your information</h4>

                                    <p>If you believe that the information we hold about you is inaccurate or that we are no longer entitled to use it and want to request its rectification, deletion or object to its processing, please contact us here.</p>
                                    <p>For your protection and the protection of all of our users, we may ask you to provide proof of identity before we can answer the above requests.</p>
                                    <p>Keep in mind, we may reject requests for certain reasons, including if the request is unlawful or if it may infringe on trade secrets or intellectual property or the privacy of another user. If you wish to receive information relating to another user, such as a copy of any messages you received from him or her through our service, the other user will have to contact our Privacy Officer to provide their written consent before the information is released. </p>
                                    <p>We do not however store your or other user messages, chat history and similar in our servers longer than 90 days. After this time expires, we can no longer retrieve any of this information. </p>
                                    <p>Also, we may not be able to accommodate certain requests to object to the processing of personal information, notably where such requests would not allow us to provide our service to you anymore. For instance, we cannot provide our service if we do not have your date of birth.</p>
                                    <p>Accountability. In certain countries, including in the European Union, you have a right to lodge a complaint with the appropriate data protection authority if you have concerns about how we process your personal information. The data protection authority you can lodge a complaint with notably may be that of your habitual residence, where you work or where we are established.</p>

                                    <h3>HOW WE PROTECT YOUR INFORMATION</h3>

                                    <p>We work hard to protect you from unauthorized access to or alteration, disclosure or destruction of your personal information. As with all technology companies, although we take very serious steps to secure your information, we do not promise, and you should not expect, that your personal information will remain secure 100% due to technological failure or similar.</p>
                                    <p>We regularly monitor our systems for possible vulnerabilities and attacks and regularly review our information collection, storage and processing practices to update our physical, technical and organizational security measures.</p>
                                    <p>We may suspend your use of all or part of the services without notice if we suspect or detect any breach of security. If you believe that your account or information is no longer secure, please notify us immediately here.</p>

                                    <h3>HOW LONG WE RETAIN YOUR INFORMATION</h3>

                                    <p>We keep your personal information only as long as we need it for legitimate business purposes (as laid out in Section 5) and as permitted by applicable law. To protect the safety and security of our users on and off our services, we implement a safety retention window of 90 days following account deletion. During this period, account information will be retained although the account will of course not be visible on the services anymore.</p>
                                    <p>In practice, we delete or anonymize your information upon deletion of your account (following the safety retention window) or after two years of continuous inactivity, unless: we must keep it to comply with applicable law (for instance, some “traffic data” is kept for one year to comply with statutory data retention obligations); </p>
                                    <p>we must keep it to evidence our compliance with applicable law (for instance, records of consents to our Terms, Privacy Policy and other similar consents are kept for five years);
                                        there is an outstanding issue, claim or dispute requiring us to keep the relevant information until it is resolved; or
                                        the information must be kept for our legitimate business interests, such as fraud prevention and enhancing users’ safety and security</p>
                                    <p>For example, information may need to be kept to prevent a user who was banned for unsafe behaviour or security incidents from opening a new account.</p>
                                    <p>Keep in mind that even though our systems are designed to carry out data deletion processes according to the above guidelines, we cannot promise that all data will be deleted within a specific timeframe due to technical constraints.</p>

                                    <h3>CHILDREN’S PRIVACY</h3>

                                    <p>Our services are restricted to users who are 18 years of age or older. We do not permit users under the age of 18 on our platform and we do not knowingly collect personal information from anyone under the age of 18. If you suspect that a user is under the age of 18, please use the reporting mechanism available through the service.</p>

                                    <h3>PRIVACY POLICY CHANGES</h3>

                                    <p>Because we’re always looking for new and innovative ways to help you build meaningful connections and relationships, this policy may change over time. We will notify you before any material changes take effect so that you have time to review the changes.</p>

                                    <h3>HOW TO CONTACT US</h3>

                                    <p>If you have questions about this Privacy Policy, here’s how you can reach us:</p>
                                    <p>Online: via our contact us page</p>
                                    <p><b>By post:</b></p>

                                    <p><b>Privacy Officer</b></p>
                                    <p><b>NowPurple Ltd</b></p>
                                    <p><b>Unit GF 20</b></p>
                                    <p><b>58 Nelson Street</b></p>
                                    <p><b>London, E1 2DE</b></p>
                                    <p><b>United Kingdom</b></p>


                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </>
        );
    }
}

export default Privacy;
