
export const userInfo = {
    id: 0,
    uid: '',
    age: '',
    phone: '',
    bio: '',
    block_duration: 0,
    block_status: 0,
    chest: '',
    city: '',
    country: '',
    cityName: '',
    countryName: '',
    email: '',
    ethnicity: '',
    eye_color: '',
    flag_status: '',
    gender: '',
    bodyType: '',
    hair_color: '',
    hair_length: '',
    hair_type: '',
    height: '',
    hobby: '',
    img: '',
    mac: '',
    name: '',
    postcode: '',
    profession: '',
    skin_color: '',
    waist: '',
    why_here: '',
    profilePercentage: 0,
    likes: 0,
    views: 0,
    hasGalleryImage: false,
    allowSwearWords: 0,
    availability: ''
};

export const interests = [
    "Acting",
    "Action figure",
    "Air sports",
    "Aircraft spotting",
    "Airsoft",
    "Amateur astronomy",
    "Amateur geology",
    "Amateur radio",
    "Animal fancy",
    "Animation",
    "Antiquing",
    "Antiquities",
    "Archery",
    "Art",
    "Art collecting",
    "Association football",
    "Astrology",
    "Astronomy",
    "Audiophile",
    "Australian rules football",
    "Auto audiophilia",
    "Auto racing",
    "Axe throwing",
    "Backgammon",
    "Backpacking",
    "Badminton",
    "Baking",
    "BASE jumping",
    "Baseball",
    "Basketball",
    "Baton twirling",
    "Beach volleyball",
    "Beauty pageants",
    "Beekeeping",
    "Billiards",
    "Biology",
    "Birdwatching",
    "Blacksmithing",
    "Blogging",
    "BMX",
    "Board sports",
    "Board/tabletop games",
    "Bodybuilding",
    "Book collecting",
    "Book discussion clubs",
    "Bowling",
    "Boxing",
    "Breakdancing",
    "Bridge",
    "Building",
    "Bus spotting",
    "Button collecting",
    "Camping",
    "Canoeing",
    "Car fixing & building",
    "Car riding",
    "Card games",
    "Cartophily (card collecting)",
    "Charity",
    "Checkers (draughts)",
    "Cheerleading",
    "Chemistry",
    "Chess",
    "Cleaning",
    "Climbing",
    "Clothesmaking",
    "Coin collecting",
    "Collecting",
    "Collection hobbies",
    "Color guard",
    "Comic book collecting",
    "Competitive hobbies",
    "Computer programming",
    "cooking",
    "Cosplaying",
    "Creative writing",
    "Cribbage",
    "Cricket",
    "Crocheting",
    "Croquet",
    "Cross-stitch",
    "Crossword puzzles",
    "Cryptography",
    "Curling",
    "Cycling",
    "Dancing",
    "Darts",
    "Debate",
    "Deltiology (postcard collecting)",
    "Die-cast toy",
    "Digital arts",
    "Disc golf",
    "DJing",
    "Do it yourself",
    "Dog sport",
    "Dolls",
    "Dominoes",
    "Drama",
    "Drawing",
    "Drinking",
    "Driving",
    "Eating",
    "Educational hobbies",
    "Electrochemistry",
    "Electronic games",
    "Electronics",
    "Element collecting",
    "Embroidery",
    "Ephemera collecting",
    "Equestrianism",
    "Esports",
    "Exercise",
    "Exhibition drill",
    "Experimenting",
    "Family",
    "Farming",
    "Fashion",
    "Fashion design",
    "Fencing",
    "Field hockey",
    "Figure skating",
    "Filmmaking",
    "Finding love",
    "Fishing",
    "Fishkeeping",
    "Flower arranging",
    "Flower collecting and pressing",
    "Flower growing",
    "Flying",
    "Flying disc",
    "Footbag",
    "Foraging",
    "Foreign language learning",
    "Fossil hunting",
    "Freestyle football",
    "Frisbee",
    "Furniture building",
    "Gaming",
    "Gardening",
    "Genealogy",
    "Geocaching",
    "Geography",
    "Ghost hunting",
    "Going to the theatre",
    "Gold prospecting",
    "Golfing",
    "Gongoozling",
    "Graffiti",
    "Graphic design",
    "Gymnastics",
    "Hacking",
    "Handball",
    "Handicrafts",
    "Herbalism",
    "Herp keeping",
    "Herping",
    "Hiking",
    "Hiking/backpacking",
    "History",
    "Hobby horsing",
    "Hobby tunneling",
    "Home improvement",
    "Homebrewing",
    "Hooping",
    "Horseback riding",
    "Horseshoes",
    "Houseplant care",
    "Hula hooping",
    "Humor",
    "Hunting",
    "Ice hockey",
    "Ice skating",
    "Iceboat racing",
    "Indoors",
    "Inline skating",
    "Insect collecting",
    "Jewelry making",
    "Jigsaw puzzles",
    "Jiu Jitsu",
    "Jogging",
    "Journaling",
    "Judo",
    "Juggling",
    "Jujitsu",
    "Jukskei",
    "Jumping rope",
    "Kabaddi",
    "Karaoke",
    "Karate",
    "Kart racing",
    "Kayaking",
    "Kendama",
    "Kite flying",
    "Kitesurfing",
    "Knife collecting",
    "Knife throwing",
    "Knitting",
    "Knowledge/word games",
    "Kombucha brewing",
    "Lace making",
    "Lacrosse",
    "Lapidary",
    "LARPing",
    "Laser tag",
    "Learning",
    "Leather crafting",
    "Lego building",
    "Letterboxing",
    "Listening to music",
    "Listening to podcasts",
    "Long-distance running",
    "Longboarding",
    "Lotology (lottery ticket collecting)",
    "Machining",
    "Macrame",
    "Magic",
    "Magnet fishing",
    "Mahjong",
    "Makeup",
    "Marbles",
    "Marching band",
    "Martial arts",
    "Mathematics",
    "Mazes (indoor/outdoor)",
    "Meditation",
    "Metal detecting",
    "Metalworking",
    "Meteorology",
    "Microscopy",
    "Mineral collecting",
    "Model aircraft",
    "Model building",
    "Model racing",
    "Model United Nations",
    "Motor sports",
    "Mountain biking",
    "Mountaineering",
    "Movie and movie memorabilia collecting",
    "Museum visiting",
    "Mushroom hunting/mycology",
    "Music",
    "Nail art",
    "Needlepoint",
    "Netball",
    "Nordic skating",
    "Observation hobbies",
    "Orienteering",
    "Origami",
    "Outdoors",
    "Paintball",
    "Painting",
    "Palmistry",
    "Parkour",
    "Perfume",
    "Personal growth",
    "Pet adoption & fostering",
    "Pets",
    "Philately",
    "Photography",
    "Physics",
    "Pickleball",
    "Pilates",
    "Pin (lapel)",
    "Playing musical instruments",
    "Podcast hosting",
    "Poi",
    "Poker",
    "Pole Dancing",
    "Politics",
    "Polo",
    "Pool",
    "Pottery",
    "Powerboat racing",
    "Powerlifting",
    "Pressed flower craft",
    "Psychology",
    "Public transport riding",
    "Puzzles",
    "Quidditch",
    "Quilling",
    "Quilting",
    "Quizzes",
    "Race walking",
    "Racquetball",
    "Radio-controlled car racing",
    "Radio-controlled model",
    "Rafting",
    "Rail transport modeling",
    "Rail transport modelling",
    "Railway journeys",
    "Railway studies",
    "Rappelling",
    "Rapping",
    "Reading",
    "Record collecting",
    "Reiki",
    "Research",
    "Road biking",
    "Robot combat",
    "Rock balancing",
    "Rock climbing",
    "Rock tumbling",
    "Role-playing games",
    "Roller derby",
    "Roller skating",
    "Rubik's Cube",
    "Rugby",
    "Rugby league football",
    "Running",
    "Sailing",
    "Sand art",
    "Satellite watching",
    "Science and technology studies",
    "Scouting",
    "Scrapbooking",
    "SCUBA Diving",
    "Sculling or rowing",
    "Sculpting",
    "Scutelliphily",
    "Sea glass collecting",
    "Seashell collecting",
    "Sewing",
    "Shoemaking",
    "Shoes",
    "Shogi",
    "Shooting",
    "Shooting sport",
    "Shopping",
    "Shortwave listening",
    "Shuffleboard",
    "Singing",
    "Skateboarding",
    "Sketching",
    "Skiing",
    "Skimboarding",
    "Skipping rope",
    "Skydiving",
    "Slacklining",
    "Sled dog racing",
    "Slot car",
    "Slot car racing",
    "Snowboarding",
    "Snowmobiling",
    "Snowshoeing",
    "Soapmaking",
    "Soccer",
    "Social media",
    "Social studies",
    "Softball",
    "Speed skating",
    "Speedcubing",
    "Sport stacking",
    "Sports",
    "Sports memorabilia",
    "Sports science",
    "Spreadsheets",
    "Squash",
    "Stamp collecting",
    "Stand-up comedy",
    "Stone collecting",
    "Stone skipping",
    "Stuffed toy collecting",
    "Sun bathing",
    "Surfing",
    "Survivalism",
    "Swimming",
    "Table football",
    "Table tennis",
    "Taekwondo",
    "Tai chi",
    "Tarot",
    "Taxidermy",
    "Tea bag collecting",
    "Tennis",
    "Tennis polo",
    "Tether car",
    "Thrifting",
    "Thru-hiking",
    "Ticket collecting",
    "Topiary",
    "Tour skating",
    "Tourism",
    "Toys",
    "Trade fair visiting",
    "Trainspotting",
    'Transit map collecting',
    "Trapshooting",
    "Travel",
    "Travelling",
    "Triathlon",
    "Ultimate frisbee",
    "Urban exploration",
    "Vacation",
    "Vegetable farming",
    'Vehicle restoration',
    "Video editing",
    "Video game collecting",
    'Video game developing',
    "Video gaming",
    "Videography",
    'Vintage cars',
    'Vintage clothing',
    "Vinyl Records",
    "Virtual reality",
    'Volleyball',
    "Walking",
    'Watching movies',
    'Watching television',
    'Water polo',
    'Water sports',
    "Weaving",
    "Webtooning",
    'Weight training',
    "Weightlifting",
    "Welding",
    "Whale watching",
    "Whittling",
    "Wikipedia editing",
    "Winemaking",
    "Wood carving",
    "Woodworking",
    "Word searches",
    'Work/career',
    'Worldbuilding',
    "Wrestling",
    "Writing",
    "Yo-yoing",
    "Yoga",
    "Zumba",
];

export const emojis = [
    "&#128512;",
    "&#128513;",
    "&#128514;",
    "&#128515;",
    "&#128516;",
    "&#128517;",
    "&#128518;",
    "&#128519;",
    "&#128520;",
    "&#128521;",
    "&#128522;",
    "&#128523;",
    "&#128524;",
    "&#128525;",
    "&#128526;",
    "&#128527;",
    "&#128528;",
    "&#128529;",
    "&#128530;",
    "&#128531;",
    "&#128532;",
    "&#128533;",
    "&#128534;",
    "&#128535;",
    "&#128536;",
    "&#128537;",
    "&#128538;",
    "&#128539;",
    "&#128540;",
    "&#128541;",
    "&#128542;",
    "&#128543;",
    "&#128544;",
    "&#128545;",
    "&#128546;",
    "&#128547;",
    "&#128548;",
    "&#128549;",
    "&#128550;",
    "&#128551;",
    "&#128552;",
    "&#128553;",
    "&#128554;",
    "&#128555;",
    "&#128556;",
    "&#128557;",
    "&#128558;",
    "&#128559;",
    "&#128560;",
    "&#128561;",
    "&#128562;",
    "&#128563;",
    "&#128564;",
    "&#128565;",
    "&#128566;",
    "&#128567;",
    "&#128568;",
    "&#128569;",
    "&#128570;",
    "&#128571;",
    "&#128572;",
    "&#128573;",
    "&#128574;",
    "&#128575;",
    "&#128576;",
    "&#128577;",
    "&#128578;",
    "&#128579;",
    "&#128580;",
    "&#129296;",
    "&#129297;",
    "&#129298;",
    "&#129299;",
    "&#129300;",
    "&#129301;",
    "&#129312;",
    "&#129313;",
    "&#129314;",
    "&#129315;",
    "&#129316;",
    "&#129317;",
    "&#129318;",
    "&#129319;",
    "&#129321;",
    "&#129322;",
    "&#129323;",
    "&#129324;",
    "&#129325;",
    "&#129326;",
    "&#129327;",
    "&#129328;",
    "&#129488;",
];
