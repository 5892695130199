class AddScripts {
    createScript() {
        const urls = [
            // 'js/jquery-2.1.4.min.js',
            // 'js/bootstrap.min.js',
        ];

        urls.forEach(url => {
            this.createElement(url);
        });
    }

    createElement(elId, url) {
        const script = document.createElement("script");
        script.src = url;
        script.async = false;
        const customScriptBody = document.getElementById(elId);

        customScriptBody.appendChild(script);
        return script;
    }

    removeElement(element) {
        document.querySelector(element).innerHTML = '';
    }

}

export default AddScripts;
