

// export const baseUrl = 'http://127.0.0.1:8000';
// export const baseApiUrl = 'http://127.0.0.1:8000/api';
// export const baseChatUrl = 'http://localhost:5500';

// export const baseUrl = 'https://demo.nowpurple.com/admin';
// export const baseApiUrl = 'https://demo.nowpurple.com/admin/api';
// export const baseChatUrl = 'https://chat.nowpurple.com';

export const baseUrl = 'https://nowpurple.com/admin';
export const baseApiUrl = 'https://nowpurple.com/admin/api';
export const baseChatUrl = 'https://chat.nowpurple.com';


export const createFormData = (data = {}) => {
    let formData = new FormData();
    for (let key in data) {
        formData.append(key, data[key]);
    }

    return formData;
}

const post = async (url, data, socketId = '') => {

    let myHeaders = new Headers();

    if (localStorage.getItem('access_token')) {
        myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    }

    if (socketId !== '') {
        myHeaders.append('X-Socket-ID', socketId);
    }

    let formData = createFormData(data);
    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        redirect: 'follow'
    };

    return fetch(baseApiUrl + url, requestOptions)
        .then(response => response.json())
        .then(result => result)
        .catch(error => console.log('error', error));


}

const get = (url, socketId = '') => {

    let myHeaders = new Headers();

    if (localStorage.getItem('access_token')) {
        myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    }

    if (socketId !== '') {
        myHeaders.append('X-Socket-ID', socketId);
    }

    let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    if (url.indexOf("?") >= 0) {
        url = url + '&t=' + Date.now().toString();
    } else {
        url = url + '?t=' + Date.now().toString();
    }

    return fetch(baseApiUrl + url, requestOptions)
        .then(response => response.json())
        .then(result => result)
        .catch(error => console.log('error', error));

}

const getFromSocket = (url) => {

    let requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    return fetch(baseChatUrl + url, requestOptions)
        .then(response => response.json())
        .then(result => result)
        .catch(error => console.log('error', error));

}

export const login = async (data = {email: '', password: ''}) => {
    const res = await post('/login', data);

    if (res) {

        if (res.user) {
            localStorage.setItem('access_token', res.access_token);
            localStorage.setItem('user', JSON.stringify(res.user));
            return true;
        }

    }

    return false;
}

export const registerUser = async (data = {}) => {
    const res = await post('/register', data);

    if (!res) {
        return {status: false, error: 'Something went wrong'};
    } else if (res.user) {
        localStorage.setItem('access_token', res.access_token);
        localStorage.setItem('user', JSON.stringify(res.user));
        return {status: true};
    }
    return res;
}

export const checkUserName = async (data = {}, socketId = '') => {
    return await post('/check-user-name', data);
}

export const updatePassword = async (data = {}) => {
    return await post('/update-password', data);
}

export const resetPassword = async (data = {}) => {
    return await post('/reset-password', data);
}

export const sendResetEmail = async (data = {}) => {
    return await post('/send-reset-link', data);
}

export const removeAccount = async (data = {}) => {
    return await post('/remove-account', data);
}

export const sendChatMessage = async (data = {}, socketId = '') => {
    // const res = await post('/send-chat-message', data, socketId);
    return await post('/send-chat-message', data);
}

export const checkSwearWords = async (data = {}) => {
    return await post('/check-swear-word', data);
}

export const sendChatFile = async (data = {}) => {
    return await post('/send-chat-file', data);
}

export const getFriends = async (data = {}, socketId) => {
    return await post('/get-friends', data, socketId);
}

export const getChatMessages = async (channel) => {
    return await get('/get-chat-message?channel=' + channel);
}

export const getOnlineUsers = async () => {
    return await getFromSocket('/online-users');
}

export const getContacts = async (params = {}) => {
    let paramStr = '';
    if (Object.keys(params).length > 0) {
        let i = 0;
        for(let p in params) {
            if (i === 0) {
                paramStr += p + '=' + params[p];
            } else {
                paramStr += '&' + p + '=' + params[p];
            }

            i++;
        }
    }

    if (paramStr !== '') {
        paramStr = '?' + paramStr;
    }

    return await get('/find-contacts' + paramStr);
}

export const sendFriendRequest = async (data = {}) => {
    return await post('/send-friend-request', data);
}

export const acceptRejectContact = async (data = {}) => {
    return await post('/accept-reject-request', data);
}

export const cancelRequest = async (data = {}) => {
    return await post('/cancel-request', data);
}

export const removeChatMessage = async (data = {}) => {
    return await post('/remove-chat-message', data);
}

export const getFriendRequests = async () => {
    return await get('/get-friend-requests');
}

export const unreadChatMessage = async () => {
    return await get('/unread-message');
}

export const getCountries = async () => {
    return await get('/countries');
}

export const getCities = async (countryId) => {
    return await get('/cities?countryId=' + countryId);
}


export const getCaptcha = async () => {
    return await get('/get-captcha');
}


export const getCategory = async () => {
    return await get('/product-category');
}

export const getProducts = async (cat, page) => {
    let param = '';
    if (cat) {
        param += 'cat=' + cat;
    }
    if (param !== '') {
        param += '&page=' + page;
    } else {
        param += 'page=' + page;
    }
    return await get('/product?' + param);
}

export const adsGetCategory = async () => {
    return await get('/ads-product-category');
}

export const adsGetProducts = async () => {
    return await get('/ads-product');
}

export const adsGetRandProducts = async (limit = 3) => {
    return await get('/get-random-product?limit=' + limit);
}

export const updateUser = async (data = {}) => {
    return await post('/update-profile', data);
}

export const updateUserProfile = async (data = {}) => {
    return await post('/update-full-profile', data);
}

export const getUserProfile = async (userName = '') => {
    let param = '';
    if (userName !== '') {
        param = '?userName=' + userName;
    }
    return await post('/get-profile' + param);
}

export const addGallery = async (data = {}) => {
    return await post('/add-gallery', data);
}

export const removeGallery = async (data = {}) => {
    return await post('/remove-gallery', data);
}

export const getSharedWithMeGallery = async (data = {}) => {
    return await post('/get-shared-with-me-gallery', data);
}

export const getGallery = async () => {
    return await post('/get-gallery');
}

export const giveReview = async (data = {}) => {
    return await post('/rate-user', data);
}

export const unfriend = async (data = {}) => {
    return await post('/unfriend', data);
}

export const blockUser = async (data = {}) => {
    return await post('/block-user', data);
}

export const unblockUser = async (data = {}) => {
    return await post('/unblock-user', data);
}

export const shareGallery = async (data = {}) => {
    return await post('/share-gallery', data);
}

export const getBlockedList = async () => {
    return await get('/get-blocked-list');
}

export const getAvatarList = async (page = 1) => {
    let param = '?page=' + page;
    return await get('/avatar-list' + param);
}

export const getHowToVideos = async (page = 1) => {
    let param = `?page=${page}`;
    return await get('/get-how-to-videos' + param);
}

export const activeNewsLetter = async (data = {}) => {
    return await post('/active-news-letter',  data);
}

export const submitSafeGuardDocs = async (data = {}) => {
    return await post('/submit-sg-docs',  data);
}

export const emailContact = async (data = {}) => {
    return await post('/email-contact',  data);
}

export const createStory = async (data = {}) => {
    return await post('/create-story',  data);

}

export const getStories = async (data = {}) => {
    return await post('/get-stories',  data);
}

export const getStoriesUnReg = async (data = {}) => {
    let param = [];
    if (data.timeline) {
        param.push(`timeline=` + data.timeline);
    }
    if (data.topStories) {
        param.push(`topStories=` + data.topStories);
    }
    if (data.page) {
        param.push(`page=` + data.page);
    }

    return await get('/get-stories?' + param.join('&'));
}

export const editStory = async (data = {}) => {
    return await post('/edit-story',  data);
}

export const storyAction = async (data = {}) => {
    return await post('/story-action',  data);
}

export const shareStory = async (data = {}) => {
    return await post('/share-story',  data);
}

export const deleteStory = async (data = {}) => {
    return await post('/delete-story',  data);
}

export const postComment = async (data = {}) => {
    return await post('/post-comment',  data);
}

export const deleteComment = async (data = {}) => {
    return await post('/delete-comment',  data);

}

export const getComments = async (storyId, page = 1) => {
    let param = `?storyId=${storyId}&page=${page}`;
    return await get('/get-comments' + param);
}

export const storyNotifications = async () => {
    return await get('/story-notifications');
}

export const setPageView = async (data = {}) => {
    return await post('/set-profile-view',  data);
}

export const getProfileActions = async (data = {}) => {
    return await post('/get-profile-actions',  data);
}

export const setProfileLike = async (data = {}) => {
    return await post('/set-profile-like',  data);

}

export const getNotifications = async () => {
    return await get('/get-notifications');
}

export const getSchedules = async () => {
    return await get('/get-schedules');
}

export const getAppointments = async () => {
    return await get('/get-appointments');
}

export const notifySettings = async () => {
    return await get('/notify-settings');
}

export const generalSettings = async () => {
    return await get('/general-settings');
}

export const updateNotifySettings = async (data = {}) => {
    return await post('/update-notify-settings',  data);
}

export const acceptSchedule = async (data = {}) => {
    return await post('/accept-appointment',  data);
}

export const cancelSchedule = async (data = {}) => {
    return await post('/cancel-appointment',  data);
}

export const blogs = async (page) => {
    return await get('/blogs?page=' + page);
}

export const blogSingle = async (slug) => {
    return await get('/blog-single?slug=' + slug);
}

export const getBlogComments = async (blogId, page = 1) => {
    return await get('/blog-comments?blogId=' + blogId + '&page=' + page);
}

export const postBlogComment = async (data = {}) => {
    return await post('/post-blog-comment',  data);
}

export const postBlogAction = async (data = {}) => {
    return await post('/post-blog-action',  data);
}

export const postBlogView = async (data = {}) => {
    return await post('/post-blog-view',  data);
}

export const postBlogDeleteComment = async (data = {}) => {
    return await post('/post-blog-comment-delete',  data);
}

export const unsubscribe = async (data = {}) => {
    return await post('/marketing/unsubscribe',  data);
}
