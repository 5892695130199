import React from "react";
import {Link} from "react-router-dom";
import {activeNewsLetter} from "../services/ApiService";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

class Footer extends React.Component {

    state = {
        userEmail: '',
    }

    newsLetter = async () => {
        if (!this.state.userEmail) {
            toast.info('Provide email address');
            return false;
        }
        const res = await activeNewsLetter({email: this.state.userEmail});
        if (res) {
            if (res.status) {
                toast.success(res.message);
            } else {
                toast.error(res.error);
            }
        }
    }

    render() {
        return (
            <>
                <footer id="main_footer">
                    <div className="container pt-1">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-lg-6">

                                <div className="newsletter_area">
                                    <div className="title">Newsletter</div>
                                    <p>Subscribe To Our Newsletter And Stay Up To Date</p>

                                    <form className="form d-flex"
                                          onSubmit={ async (e) => {
                                              e.preventDefault();
                                              await this.newsLetter();
                                              const bk = this.state;
                                              bk.userEmail = '';
                                              this.setState(bk);
                                          }}>
                                        <label className="sr-only" htmlFor="inlineFormInputName2">Email Address</label>
                                        <input type="email" className="form-control mb-2 mr-sm-2" value={this.state.userEmail}
                                               id="inlineFormInputName2" placeholder="test@test.com"
                                               onChange={(e) => {
                                                   e.persist();
                                                   const bk = this.state;
                                                   bk.userEmail = e.target.value;
                                                   this.setState(bk);
                                               }} />

                                            <button type="submit" className="btn btn-primary mb-2">Subscribe Now</button>
                                    </form>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="continer">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="footer_menu text-center">
                                    <ul>
                                        <li><Link to={"/blog"}>Blogs</Link></li>
                                        <li><Link to={"/privacy-policy"}>Privacy Policy</Link></li>
                                        <li><Link to={"/terms"}>Terms and Conditions</Link></li>
                                        <li><Link to={"/cookie-policy"}>Cookie Policy</Link></li>
                                        <li><Link to={"/shop"}>Shop to help</Link></li>
                                        <li><Link to={'/faq'}>Faq</Link></li>
                                        <li><Link to={'/contact'}>Contact us</Link></li>
                                    </ul>
                                    <div className="copy">
                                        <p className="text-center">&copy; NowPurple 2020 | All rights reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}

export default Footer;
